import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import callService from 'services/call-service';
import priorityService from 'services/priority-service';
import callTypeService from "services/callType-service";
import customerTypeService from "services/customerType-service";
import designationService from "services/designation-service";
import executiveService from "services/executive-service";
import MDChip from 'components/MDChip';
import jobSheetService from "services/jobSheet-service";
 
const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };

const convertDateFormat=(inputDate)=> {
      
  const parts = inputDate.split('-');
  const date = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
  const formattedDate = date.toISOString().replace(/\.\d{3}/, '');

  return formattedDate;
}  
const convertToCustomDateTimeFormat = (inputDate) => {
    
    const date = inputDate?new Date(inputDate):new Date();
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    const hours = String(date.getHours()).padStart(2, "0"); // Ensure two-digit hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensure two-digit minutes
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

const convertDateTimeToISO8601 = (inputDateTime) => {
    const date = new Date(inputDateTime);
    if (!isNaN(date)) {
      // Check if the date is valid
      return date.toISOString();
    }
    // Handle invalid input (e.g., if the inputDateTime is not in the expected format)
    return null; // or any appropriate error handling
  };
const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
    return inputDate
  };

const CallForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const id = userDetail.decodedToken?.userId;
    const navigate = useNavigate()
    const [options,setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [startingDate, setStartingDate] = useState(formatDate(new Date()));
    const [endDate,setEndDate] = useState(getDefaultEndDate())
    const [creatDate,setCreatDate] = useState(formatDate(new Date()))
    const [callTypeList, setCallTypeList ] = useState([])
    const [priorityTypeList, setPriorityTypeList ] = useState([])
    const [customerTypeList, setCustomerTypeList ] = useState([])
    const [search,setSearch] = useState('')
    const [designationList,setDesignationList] = useState([])
    const [executiveList,setExecutiveList] = useState([])
    const [values,setValues] = useState({
          callAssignDate:new Date(),
          callDocumentNumber:'',
          RMN:'',
          callType:'service call',
          companyName:'',
          googleMapUrl:'',
          address:'',
          area:'',
          city:'',
          state:'',
          country:'',
          pincode:'',
          amcName:'',
          customerType:'',
          AMCStatus:'',
          status:'unassigned',
          remainingDays:'',
          callDetailsNotes:'',
          priority:'medium',
          executiveDesignation:'',
          executives:'',
          callAttendDate:new Date().toISOString(),
          attendTime:'',
          amcCustomerId:'',
          parentId: id
       })
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectedOptionsChange = (newSelectedOptions) => {
         setSelectedOptions(newSelectedOptions);
         setValues({...values,executives:newSelectedOptions})
       };
    const [formErrors, setFormErrors] = useState({
            companyName:'',
        });
    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

        useEffect(async() => {
            try{
                const response = id != undefined && await jobSheetService.docNolist(id)
                response.data && setValues({
                    ...values,
                 callDocumentNumber: response.data.callDocNumber
                })
            }catch(err){
                console.log(err)
            }
        },[id])
            
        useEffect(async() => {
            try{
                const executiveList = id ? await executiveService.list(id,'',''):{data:[]}
                executiveList.data && setExecutiveList(executiveList.data)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        const formatedExecutiveList = executiveList.map((item) => ({
            value: item._id,
            label: item.name,
            designation: item.designation
          }));

        useEffect(async() => {
            try{
                const callType = id ? await callTypeService.list(id):{data:{callTypeList:[]}}
                callType.data && setCallTypeList(callType.data.callTypeList)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        useEffect(async() => {
            try{
                const priorityType = id ? await priorityService.list(id):{data:{priorityList:[]}}
                priorityType.data && setPriorityTypeList(priorityType.data.priorityList)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        useEffect(async() => {
            try{
                const customerType = id ? await customerTypeService.list(id):{data:{customerTypeList:[]}}
                customerType.data && setCustomerTypeList(customerType.data.customerTypeList)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        useEffect(async() => {
            try{
                const designation = id ? await designationService.list(id):{data:{designationTypeList:[]}}
                designation.data && setDesignationList(designation.data.designationTypeList)
            }catch(err){
                console.log("err",err)
            }
         }, [id])
    
  
    useEffect(async() => {
        try{
            const list = id ? await customerService.search(id,search):{data:[]}
            setOptions(list.data)
            
        }catch(err){
            console.log(err)
        }
        
    }, [search])   

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await callService.create(values)
                navigate('/call', { state: { successMessage: "Call created successfully" } })
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
          } else {
            console.log('Form validation failed');
          }
    }
    const createCustomer=()=>{
        navigate('/create-customer')
    }
      
    const handleInputChange = (selected) => {
        setSelectedOption(selected);     
       
        selected.length > 0 && setValues((values) => ({...values,
                companyName:selected[0].companyName,
                address:selected[0].address,
                area:selected[0].area,
                country:selected[0].country,
                city:selected[0].city,
                state:selected[0].state,
                pincode:selected[0].pincode,
                googleMapUrl:selected[0].googleMapUrl,
                RMN:selected[0].contact,
                email:selected[0].email,
                alternateNumber:selected[0].alternateNumber,
                contactPerson:selected[0].contactPerson,
                customerType:selected[0].customerType,
                gstNumber:selected[0].gstNumber,
               // termsAndCondition:selected[0].termsAndCondition,
                parentId:selected[0].parentId,
            }))
    };

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
            
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="input" required>Customer Name</label>
                    <Typeahead
                        id="autocomplete-textfield"
                        labelKey="companyName"
                        options={options}
                        placeholder="Start typing..."
                        selected={selectedOption}
                        onChange={handleInputChange}
                        className = {`${formErrors.amcName?`is-invalid`:``}`}
                        onInputChange={(text, e) => {setSearch(text)}}
                    />
                     <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Customer Name.
                    </div>
                </div>
                <MDButton 
                        size="small" 
                        variant="contained" 
                        style={{"width": "1vw","height": "1vw","margin":" 5vh 0 0 0"}} 
                        color="info"
                        onClick={createCustomer}
                        > 
                        +
                </MDButton>

                <div className="form-group col-md-4">
                    <label htmlFor="inputamcName" required>AMC Name</label>
                    <input type="text" className="form-control" id="inputamcName" placeholder="AMC Name" onChange={e=>{setValues({...values,amcName:e.target.value})}} disabled/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number</label>
                    <input type="tel" className="form-control" id="validationServer05" placeholder="Contact" value={values.RMN} onChange={e=>{setValues({...values,RMN:e.target.value})}} disabled/>
                </div>
            </div>
            <hr className='line'/>
            <div className="form-row">  
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Call Document Number</label>
                    <input type="number" className="form-control" id="inputAMCDocNo" placeholder="Doc No" value={values.callDocumentNumber} onChange={e=>{setValues({...values,callDocumentNumber:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Call Type</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/call-type')
                        } else {
                          setValues({ ...values, callType: e.target.value });
                        }
                    }}
                    value={values.callType}
                    >
                                   
                                    <option value="AMC call">AMC call</option>
                                    <option value="job call">Job call</option>
                                    <option value="normal">Normal Call</option>
                                    <option value="service call">Service call</option>
                                    {callTypeList.map((item,index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                    ))}
                                    <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Customer Type</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/customer-type')
                        } else {
                          setValues({ ...values, customerType: e.target.value });
                        }
                    }} 
                    value={values.customerType}
                    disabled>
                                <option value="supplier">supplier</option>
                                <option value="customer">customer</option>
                                {customerTypeList.map((item,index) => (
                                                <option key={index} value={item}>{item}</option>
                                                ))}
                                <option value="create">Create</option>
                    </select>
                </div>
            </div>
             <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="input">AMC Status</label>
                    <select id="input" className="form-control" onChange={e=>{setValues({...values,AMCStatus:e.target.value})}} disabled>
                        <option selected>Active</option>
                        <option>In Active</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputEndDate">Remaining Days</label>
                    <input type="number" className="form-control" placeholder="Remaining Days" id="inputEndDate" onChange={e=>{setValues({...values,remainingDays:e.target.value})}} disabled/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Priority</label>
                    <select id="inputCountry" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/priority')
                        } else {
                          setValues({ ...values, priority: e.target.value });
                        }
                    }}
                    value={values.priority}
                    >
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                    {priorityTypeList.map((item,index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                    ))}
                                     <option value="create">Create</option>
                    </select>
                </div>
             </div>
             <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Executive Designation</label>
                    <select id="inputCountry" className="form-control" 
                    value={selectedOptions[0]?.designation}
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/designation')
                        } else {
                           setValues({ ...values, executiveDesignation: e.target.value });
                        }
                    }}
                    disabled
                    >
                                    <option value="executive">Executive</option>
                                    <option value="sr.executive">Sr.Executive</option>
                                    {designationList.map((item,index) => (
                                                <option key={index} value={item}>{item}</option>
                                                ))}
                                     <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Executives</label>
                    <MDChip
                        selectedOptions={selectedOptions}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                        options={formatedExecutiveList}
                    />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Attend Date</label>
                    <input type="datetime-local"  className="form-control"  value={convertToCustomDateTimeFormat(values.callAttendDate)}  id="inputAddress" placeholder="Address" 
                    // onChange={e=>{setValues({...values,callAttendDate:convertDateFormat(e.target.value)})}}
                    onChange={e=>{setValues({...values,callAttendDate:convertDateTimeToISO8601(e.target.value)})}}/>
                </div>
             </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea" placeholder="Area" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}} disabled/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control" placeholder="City" id="inputCity" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control" placeholder="Zip" id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            className="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid Url.
                        </div>
                    </div>
                </div>
             </div>
            <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Notes</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,callDetailsNotes:e.target.value})}}/>
                </div>
            </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Create
          </MDButton>
         
    </div>
  )
}

export default CallForm

