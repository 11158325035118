import React from 'react'
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const PopUp = () => {
  return (
    <>
     <Card style={{ 'position': 'absolute','top': '50vh'}}>
            <MDBox p={2} mx={3} display="grid" justifyContent="center">
                 Are You Sure You Had Completed?
                    <MDTypography variant="h6" color="white">
                     <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 2vw"}} 
                              color='info'
                            //   onClick={() =>setFilter('today')}
                              > 
                           Yes
                      </MDButton>
                      <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 2vw"}} 
                              color='info'
                            //   onClick={() =>setFilter('today')}
                              > 
                            No
                      </MDButton>
                      </MDTypography>
            </MDBox>
     </Card>
    </>
  )
}

export default PopUp