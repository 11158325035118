import React, { useState } from 'react';

const data =  [
  {
      "_id": "6540a2859150c6e1d6e1e2c4",
      "companyName": "Shehzaan Customer",
      "email": "mansurishehzaan@gmail.com",
      "contact": 9372968706,
      "alternateNumber": null,
      "contactPerson": "",
      "customerType": "",
      "gstNumber": "",
      "googleMapUrl": "",
      "address": "",
      "area": "kalamboli",
      "city": "mumbai",
      "state": "",
      "country": "",
      "pincode": "",
      "parentId": "653f34b72cff1ed1105d7e7e",
      "notes": "",
      "__v": 0
  },
  {
      "_id": "6540e0eaa3e7382ce8677fca",
      "companyName": "customer",
      "email": "customer@gmail.com",
      "contact": 7666089915,
      "alternateNumber": 7666666666,
      "contactPerson": "customer",
      "customerType": "supplier",
      "gstNumber": "sdfdg",
      "googleMapUrl": "dsfg",
      "address": "108 origin",
      "area": "panvel",
      "city": "chembur",
      "state": "Maharashtra",
      "country": "chembur",
      "pincode": "400107",
      "parentId": "6540e022a3e7382ce8677f05",
      "notes": "saddfgh",
      "__v": 0
  }
]

const SearchFilter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedField, setSelectedField] = useState('all'); // Default field

  // Filter data based on search query and selected field
  const filteredData = data.filter((item) => {
    return Object.values(item).some((fieldValue) => {
      return fieldValue && fieldValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
  };

  return (
    <div>
      <div>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div>
        <label>Search By:</label>
        <select value={selectedField} onChange={handleFieldChange}>
          <option value="all">All</option>
          <option value="name">Name</option>
          <option value="contact">Contact</option>
          <option value="email">Email</option>
          <option value="area">Area</option>
          <option value="city">City</option>
        </select>
      </div>
      <ul>
        {filteredData.map((item) => (
          <li key={item._id}>
            {item.companyName} - {item.email} - {item.contact} - {item.area} - {item.city}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchFilter;

