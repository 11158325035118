import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import MDInput from "components/MDInput";
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import Dropdown from '../Dropdown'
import MDButton from 'components/MDButton';
import executiveService from 'services/executive-service';
import { useNavigate, useParams } from "react-router-dom"
import departmentService from 'services/department-service';
import designationService from 'services/designation-service';
import { ConnectedTvOutlined } from '@mui/icons-material';
import PrevDoc from "components/PrevDoc";

function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
const ExecutiveForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const parentId = userDetail.decodedToken?.userId;
    const [values,setValues] = useState({})
    const {id} = useParams()
    const [ departmentList, setDepartmentList ] = useState([])
    const [ designationList, setDesignationList ] = useState([])
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState({
        email: '',
        password:'',
        contact: '',
        name: '',
        contactPerson:'',
        address:'',
        pincode:''
    });

    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

    useEffect(async() => {
        try{
            const department =  await departmentService.list(parentId)
            setDepartmentList(department.data.departmentTypeList)
        }catch(err){
            console.log(err)
        }           
    }, [parentId])

    useEffect(async() => {
        try{
            const designation =  await designationService.list(parentId)
                setDesignationList(designation.data.designationTypeList)
        }catch(err){
            console.log(err)
        }
    }, [parentId])
     
    useEffect(async() => {
        try{
            const executive =  await executiveService.getByID(id)
                
            setValues({
                companyName: executive.data.companyName,
                email: executive.data.email,    
                password: executive.data.password, 
                contact: executive.data.contact, 
                role: executive.data.role,
                alternateNumber: executive.data.alternateNumber, 
                contactPerson: executive.data.contactPerson, 
                name: executive.data.name, 
                gender: executive.data.gender, 
                DOB: executive.data.DOB,     
                maritialStatus: executive.data.maritialStatus, 
                DOJ: executive.data.DOJ,         
                department: executive.data.department, 
                designation: executive.data.designation, 
                bloodGroup: executive.data.bloodGroup,  
                address: executive.data.address, 
                city: executive.data.city, 
                area: executive.data.area, 
                state: executive.data.state,   
                country: executive.data.country, 
                pincode: executive.data.pincode,  
                googleMapUrl:executive.data.googleMapUrl,
                profileImage: executive.data.profileImage,
                aadharForntImage: executive.data.aadharForntImage,
                aadharBackImage: executive.data.aadharBackImage,
                panImage: executive.data.panImage,
                passportImage: executive.data.passportImage,
                drivingLicenceImage: executive.data.drivingLicenceImage,
                notes: executive.data.notes,
                role:'executive',
                parentId: executive.data.parentId
             })
            
        }catch(err){
            console.log(err)
        }
    }, [])

    const handleValidation = () => {
        let valid = true;
        const newErrors = { ...formErrors }; 

        if(values.companyName.length == 0){
            newErrors.companyName='Invalid Company Name'
            valid = false
        }else{
            newErrors.companyName = ''
        }

        if(values.email.length == 0){
            newErrors.email='Invalid Email'
            valid = false
        }else{
            newErrors.email = ''
        }

        if(values.password.length < 8){
            newErrors.password='Invalid Passwprd'
            valid = false
        }else{
            newErrors.password = ''
        }

        if(values.contact.length == 0){
            newErrors.contact='Invalid Contact'
            valid = false
        }else{
            newErrors.contact = ''
        }
        if(values.name.length == 0){
            newErrors.name='Invalid Name'
            valid = false
        }else{
            newErrors.name = ''
        }

        // if(values.contactPerson.length == 0){
        //     newErrors.contactPerson='Invalid Contact Person'
        //     valid = false
        // }else{
        //     newErrors.contactPerson = ''
        // }
        // if(values.department.length == 0){
        //     newErrors.department='Invalid Department'
        //     valid = false
        // }else{
        //     newErrors.department = ''
        // }

        // if(values.designation.length == 0){
        //     newErrors.designation='Invalid Designaton'
        //     valid = false
        // }else{
        //     newErrors.designation = ''
        // }

        // if(values.address.length == 0){
        //     newErrors.address='Invalid Address'
        //     valid = false
        // }else{
        //     newErrors.address = ''
        // }

        // if(values.pincode.length == 0){
        //     newErrors.pincode='Invalid Pincode'
        //     valid = false
        // }else{
        //     newErrors.pincode = ''
        // }


        setFormErrors(newErrors);
        return valid;
    }   
   
    const handleSubmit=async()=>{
        if(handleValidation()) {
            const formData = new FormData();
    
            formData.append('companyName', values.companyName);
            formData.append('email', values.email);
            formData.append('password', values.password);
            formData.append('contact', values.contact);
            formData.append('alternateNumber', values.alternateNumber);
            formData.append('contactPerson', values.contactPerson);
            formData.append('name', values.name);
            formData.append('gender', values.gender);
        values.DOB && formData.append('DOB', values.DOB);
            formData.append('maritialStatus', values.maritialStatus);
        values.DOJ && formData.append('DOJ', values.DOJ);
            formData.append('department', values.department);
            formData.append('designation', values.designation);
            formData.append('bloodGroup', values.bloodGroup);
            formData.append('address', values.address);
            formData.append('city', values.city);
            formData.append('area', values.area);
            formData.append('state', values.state);
            formData.append('country', values.country);
            formData.append('pincode', values.pincode);
            formData.append('googleMapUrl', values.googleMapUrl);
            formData.append('aadharForntImage', values.aadharForntImage);
            formData.append('aadharBackImage', values.aadharBackImage);
            formData.append('panImage', values.panImage);
            formData.append('passportImage', values.passportImage);
            formData.append('drivingLicenceImage', values.drivingLicenceImage);
            formData.append('notes', values.notes);
            formData.append('role', values.role);
            formData.append('parentId', values.parentId);
        
            if (values.profileImage) {
                formData.append('profileImage', values.profileImage);
            }
                
            try{
                const response = await executiveService.update(id,formData)
                navigate('/executives', { state: { successMessage: "Executive updated successfully" } })
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
            

        } else {
            console.log('Form validation failed');
        }
    }   
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        console.log("files",file)
        setValues({
            ...values,
            profileImage: file,
        });
    };

    const handleFrontAdharImageUpload = (e) => {
        const file = e.target.files[0];
        setValues({
            ...values,
            aadharForntImage: file,
        });
    };

    const handleBackAdharImageUpload = (e) => {
        const file = e.target.files[0];
        setValues({
            ...values,
            aadharBackImage: file,
        });
    };

    const handlePanImageUpload = (e) => {
        const file = e.target.files[0];
        setValues({
            ...values,
            panImage: file,
        });
    };

    const handlePassportImageUpload = (e) => {
        const file = e.target.files[0];
        setValues({
            ...values,
            passportImage: file,
        });
    };

    const handleDrivingLicenceImageUpload = (e) => {
        const file = e.target.files[0];
        setValues({
            ...values,
            drivingLicenceImage: file,
        });
    };
    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
  return (
    <>
       <div className='form-container'>
        <form className='form-box'>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Name<span className="text-danger">*</span></label>
                    <input type="text" className={`form-control ${formErrors.name?`is-invalid`:``}`} id="inputAMCName" value={values.name}  placeholder="Name" onChange={e=>{setValues({...values,name:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number<span className="text-danger">*</span></label>
                    <input type="tel"className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05"  placeholder="Contact" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Email<span className="text-danger">*</span></label>
                    <input type="text" className={`form-control ${formErrors.email?`is-invalid`:``}`} id="inputAMCName" placeholder="Email"  value={values.email}  onChange={e=>{setValues({...values,email:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Email.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Password<span className="text-danger">*</span></label>
                    <input type="password" className={`form-control ${formErrors.password?`is-invalid`:``}`} id="inputAMCName" placeholder="Password"  value={values.password}  onChange={e=>{setValues({...values,password:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Password atleast of 8 character.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Contact Person</label>
                    <input type="text"className={`form-control ${formErrors.contactPerson?`is-invalid`:``}`} id="inputAMCDocNo" placeholder="Contact Person" value={values.contactPerson} onChange={e=>{setValues({...values,contactPerson:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact Person.
                    </div>
                </div>
                
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Alternate Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="Alternate Number" value={values.alternateNumber} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                </div>
                <hr className="border border-primary border-3 opacity-75" style={{width:"100%"}}/>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Gender</label>
                    <select className="form-control" aria-label="Default select example">
                        <option selected value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                <label htmlFor="inputState">Marritial Status</label>
                    <select className="form-control" aria-label="Default select example">
                        <option selected value="single">Single</option>
                        <option value="married">Married</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">DOB</label>
                    <input type="date" className="form-control" id="validationServer05"  value={formatDate(values.DOB)} onChange={e=>{setValues({...values,DOB:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Blood Group</label>
                    <input type="text" className="form-control" id="validationServer05"  value={values.bloodGroup} onChange={e=>{setValues({...values,bloodGroup:e.target.value})}}/>
                </div>
                <hr className="border border-primary border-3 opacity-75" style={{width:"100%"}}/>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">DOJ</label>
                    <input type="date" className="form-control" id="validationServer05"  value={formatDate(values.DOJ)} onChange={e=>{setValues({...values,DOJ:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Designation</label>
                    <select  className={`form-control ${formErrors.designation?`is-invalid`:``}`} aria-label="Default select example"  
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/designation')
                        } else {
                          setValues({ ...values, designation: e.target.value });
                        }
                      }}
                      value={values.designation}
                      >
                            <option value="executive">Executive</option>
                            <option value="sr.executive">Sr.Executive</option>
                                {designationList.map((item,index) => (
                                    <option key={index} value={item}>{item}</option>
                                    ))}
                            <option value="create">Create</option>
                    </select>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Designation.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Department</label>
                    <select  className={`form-control ${formErrors.department?`is-invalid`:``}`} aria-label="Default select example"  
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/department')
                        } else {
                          setValues({ ...values, department: e.target.value });
                        }
                      }}
                      value={values.department}
                      >
                            <option value="management">Management</option>
                            <option value="employee">Employee</option>
                                {departmentList.map((item,index) => (
                                    <option key={index} value={item}>{item}</option>
                                    ))}
                            <option value="create">Create</option>
                    </select>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Department.
                    </div>
                </div>
                <hr className="border border-primary border-3 opacity-75" style={{width:"100%"}}/>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className={`form-control ${formErrors.address?`is-invalid`:``}`}  id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea"  placeholder="Area" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text"  className={`form-control ${formErrors.city?`is-invalid`:``}`}   id="inputCity" placeholder="City" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text"  className={`form-control ${formErrors.state?`is-invalid`:``}`}   id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className={`form-control ${formErrors.pincode?`is-invalid`:``}`} placeholder='Zip Code' id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text"  className={`form-control ${formErrors.country?`is-invalid`:``}`}   id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid City.
                        </div>
                    </div>
                </div>
                <hr className="border border-primary border-3 opacity-75" style={{width:"100%"}}/>
                <div className="form-group col-md-3">
                    <label htmlFor="inputCity">Profile Image</label>
                    <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile01"
                        onChange={handleImageUpload}
                    />
                     {/* {values.profileImage && <p style={{"fontSize":"1.5vh"}}>{values.profileImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.profileImage}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inputCity">Front Adhar Card</label>
                        <input
                            type="file"
                            className="form-control"
                            id="inputGroupFile01"
                            onChange={handleFrontAdharImageUpload}
                        />
                    {/* {values.aadharForntImage && <p style={{"fontSize":"1.5vh"}}>{values.aadharForntImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.aadharForntImage}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inputCity">Back Adhar Card</label>
                        <input
                                type="file"
                                className="form-control"
                                id="inputGroupFile01"
                                onChange={handleBackAdharImageUpload}
                            />
                    {/* {values.aadharBackImage && <p style={{"fontSize":"1.5vh"}}>{values.aadharBackImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.aadharBackImage}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inputCity">PAN Card</label>
                            <input
                                type="file"
                                className="form-control"
                                id="inputGroupFile01"
                                onChange={handlePanImageUpload}
                            />
                     {/* {values.panImage && <p style={{"fontSize":"1.5vh"}}>{values.panImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.panImage}/>
                </div>
                 <div className="form-group col-md-3">
                    <label htmlFor="inputCity">Passport</label>
                            <input
                                type="file"
                                className="form-control"
                                id="inputGroupFile01"
                                onChange={handlePassportImageUpload}
                            />
                     {/* {values.passportImage && <p style={{"fontSize":"1.5vh"}}>{values.passportImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.passportImage}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inputCity">Driving Licence</label>
                            <input
                                type="file"
                                className="form-control"
                                id="inputGroupFile01"
                                onChange={handleDrivingLicenceImageUpload}
                            />
                    {/* {values.drivingLicenceImage && <p style={{"fontSize":"1.5vh"}}>{values.drivingLicenceImage}</p>} */}
                </div>
                <div className="form-group col-md-3">    
                    <PrevDoc url={values.drivingLicenceImage}/>
                </div>
                <hr className="border border-primary border-3 opacity-75" style={{width:"100%"}}/>
                <div className="form-group col-md-6">
                    <label htmlFor="inputZip">Notes</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,callDetailsNotes:e.target.value})}}/>
                </div>
            </div>
        </form>   
        <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
        <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={handleSubmit}
                > 
                Update
        </MDButton>
    </div>
    </>
  )
  
}

export default ExecutiveForm

