import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


import Sidenav from "examples/Sidenav";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Configurator from "examples/Configurator";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";

import Login from "auth/login"
import Executive from "layouts/executive"
import CreateExecutive from "layouts/createExecutive"
import Customer from "layouts/customer"
import TodoList from "layouts/todoList"

import { setupAxiosInterceptors } from "./services/interceptor";

import MDButton from "components/MDButton";
import routes from "routes";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import './App.css'
import CreateCustomer from "layouts/createCustomer";
import Department from "layouts/department";
import Designation from "layouts/designation";
import CustomerType from "layouts/customerType";
import CallType from "layouts/callType";
import AMCType from "layouts/amcType";
import Priority from "layouts/priority";
import TermsAndCondition from "layouts/termsAndCondition";
import EditExecutive from "layouts/editExecutive";
import EditCustomer from "layouts/editCustomer";
import AMC from "layouts/amc";
import CreateAMC from "layouts/createAmc";
import EditAMC from "layouts/editAmc";
import JobSheet from "layouts/jobSheet";
import CreateJobSheet from "layouts/createJobSheet";
import EditJobSheet from "layouts/editJobSheet";
import Call from "layouts/call";
import CreateCall from "layouts/createCall";
import ExecutiveCallList from "layouts/executiveCallList";
import ExecutiveJobSheet from "layouts/executiveJobSheet";
import ExecutiveDashboard from "layouts/executiveDashboard";
import CreateTermsAndCondition from "layouts/createTermsAndConditions";
import EditTermsAndCondition from "layouts/editTermsAndConditions";
import Overview from "layouts/profile";
import EditProfile from "layouts/editProfile";
import ProtectedRoute from "examples/ProtectedRoute";
import ExecutiveLogin from "auth/executiveLogin";
import EditUser from "layouts/editUser";
import CreateUser from "layouts/createUser";
import EditCall from "layouts/editCall";
import MultipleCustomer from "layouts/multipleCustomer";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import PasswordReset from "auth/reset-password";
import DocNo from "layouts/docNo";
import PrevDoc from "components/PrevDoc";
import SearchFilter from "components/SearchFilter";
import AMCPrint from "components/Print/AMCPrint";
// import TermsAndConditionsCard from "examples/TermsAndConditionsCard";


const App = () => {
  // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTExMTY0NDJhOWFhNWNhOTgyNzk0NTkiLCJlbWFpbCI6InN1cGVyYWRtaW5AZ21haWwuY29tIiwicm9sZSI6InN1cGVyIGFkbWluIiwiaWF0IjoxNjk3NTQ1NDI1LCJleHAiOjE2OTc2MzE4MjV9.E2TJ048CIDe2kJasWEb4zlF6-BKX1KdHBaBV9R8lMhM`
  // localStorage.setItem("token",token)
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const companyName = userDetail.decodedToken?.companyName
    const email = userDetail.decodedToken?.email
    const profileImage = userDetail.decodedToken?.profileImage
    const [controller, dispatch] = useMaterialUIController();
    const route = useLocation().pathname;
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const role = userDetail.decodedToken?.role;
    const isExpired = userDetail?.isExpired
    const navigate = useNavigate()
 
    //const isExpired = false
    const allowedRoutes = routes.filter((route) =>
      route.allowedTo.includes(role)
    );
  
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

      // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
      if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
      }
    };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/login");
  });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          role && <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute
               isAuthenticated={authContext.isAuthenticated}
               isExpired={isExpired}
               role={role}
               allowedRoles={route.allowedTo}
               >
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
    return null;
  });
    const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );  
  if (route === "/") {
    return <Navigate to="/login" />;
  }
  
  return (
    <ThemeProvider theme={theme} >
    <CssBaseline />
    {role?layout === "dashboard" && (
    <Sidenav
        color='primary'
        brand={brandWhite}
        brandName={companyName}
        email={email}
        profileImage={profileImage}
        routes={allowedRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />):(<></>)
    }
      {/* <Configurator />
      {configsButton} */}
          <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/executive-login" element={<ExecutiveLogin/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/reset/" element={<PasswordReset/>} />
            {getRoutes(routes)}
          
            <Route path="/create-executive" element={<CreateExecutive/>} />

            <Route path="/customers" element={<Customer/>} />
            <Route path="/create-customer" element={<CreateCustomer/>} />
            <Route path="/todo-list" element={<TodoList/>} />

            <Route path="/department" element={<Department/>} />
            <Route path="/designation" element={<Designation/>} />
            <Route path="/customer-type" element={<CustomerType/>} />
            <Route path="/call-type" element={<CallType/>} />
            <Route path="/amc-type" element={<AMCType/>} />
            <Route path="/priority" element={<Priority/>} />
            <Route path="/terms-and-condition" element={<TermsAndCondition/>} />
            <Route path="/edit-executive/:id" element={<EditExecutive/>} />
            <Route path="/edit-customer/:id" element={<EditCustomer/>} />
            <Route path="/amc" element={<AMC/>} />
            <Route path="/create-amc" element={<CreateAMC/>} />
            <Route path="/edit-amc/:id" element={<EditAMC/>} />
            <Route path="/job-sheet" element={<JobSheet/>} />
            <Route path="/create-job-sheet" element={<CreateJobSheet/>} />
            <Route path="/edit-jobsheet/:id" element={<EditJobSheet/>} />
            <Route path="/call" element={<Call/>} />
            <Route path="/create-call" element={<CreateCall/>} />
            <Route path="/edit-call/:callId" element={<EditCall/>} />
            <Route path="/executive-call-list" element={<ExecutiveCallList/>} />
            <Route path="/executive-jobsheet" element={<ExecutiveJobSheet/>} />
            <Route path="/executive-dashboard" element={<ExecutiveDashboard/>} />
            <Route path="/create-terms-and-condition" element={<CreateTermsAndCondition/>} />
            <Route path="/edit-terms-and-condition/:index" element={<EditTermsAndCondition/>} />
            <Route path="/profile" element={<Overview/>} />
            <Route path="/edit-profile/:id" element={<EditProfile/>} />
            <Route path="/edit-user/:id" element={<EditUser/>} />
            <Route path="/create-user" element={<CreateUser/>} />
            <Route path="/multiple-customer" element={<MultipleCustomer/>} />
            <Route path="/doc-no" element={<DocNo/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/executive-dashboard" element={<ExecutiveDashboard/>} />
            <Route path="/prev-doc" element={<PrevDoc/>} />
            <Route path="/search-filter" element={<SearchFilter/>} />
            {/* <Route path="/terms-and-conditions" element={<TermsAndConditionsCard/>} /> */}
            <Route path="*" element={<CatchAllRedirect />} />

            {/* <Route path="/print" element={<AMCPrint/>} /> */}
          </Routes>

  </ThemeProvider>
  )
  function CatchAllRedirect() {
   
    // const navigate = useNavigate();
  
    // // Check the current location and redirect accordingly
    // if (window.location.pathname === '/login') {
    //   navigate('/login');
    // } else if (window.location.pathname === '/dashboard') {
    //   navigate('/dashboard');
    // } else {
    //   navigate('/login'); // Default to /login for unknown paths
    // }
  
    // return null; 
    navigate('/login');
    return null;
  }
}

export default App