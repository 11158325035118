import React,{useState,useEffect} from 'react'
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate, useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import callService from 'services/call-service';
import authService from 'services/auth-service';

function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
}  
function formatDateToISOString(dateString) {
    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
      return null; // Invalid date format
    }
  
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based (0-11)
    const day = parseInt(dateParts[2], 10);
  
    const date = new Date(year, month, day);
    if (isNaN(date)) {
      return null; // Invalid date
    }
  
    return date.toISOString();
  }
const ProfileForm = () => {
    const navigate = useNavigate()

    const [values,setValues] = useState({
        companyName: '',
        email: '',
        password:'',
        contact: '',
        alternateNumber: '',
        contactPerson: '',
        profileImage: null,
        address: '',
        area: '',
        state: '',
        country: '',
        pincode: '',
        googleMapUrl:'',
        role: 'admin',
        gstNumber:'',
        subscription: {
            expiresAt: new Date()
        }
       })
    
    const [formErrors, setFormErrors] = useState({
            companyName:'',
            email: '',
            password:'',
            contact: '',
            contactPerson: '',
        });

    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            if(values.email.length == 0){
                newErrors.email='Invalid Email'
                valid = false
            }else{
                newErrors.email = ''
            }

            if(values.password.length < 8){
                newErrors.password='Invalid Passwprd'
                valid = false
            }else{
                newErrors.password = ''
            }

            if(values.contact.length == 0){
                newErrors.contact='Invalid Contact'
                valid = false
            }else{
                newErrors.contact = ''
            }

            if(values.contactPerson.length == 0){
                newErrors.contactPerson='Invalid Contact Person'
                valid = false
            }else{
                newErrors.contactPerson = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   


    const handleImageUpload = (e) => {
        const file = e.target.files[0];

        setValues({
            ...values,
            profileImage: file,
        });
        };

    const handleSubmit=async()=>{
          if(handleValidation()) {
            const formData = new FormData();
    
            formData.append('companyName', values.companyName);
            formData.append('email', values.email);
            formData.append('password', values.password);
            formData.append('contact', values.contact);
            formData.append('alternateNumber', values.alternateNumber);
            formData.append('contactPerson', values.contactPerson);
            formData.append('address', values.address);
            formData.append('area', values.area);
            formData.append('state', values.state);
            formData.append('country', values.country);
            formData.append('pincode', values.pincode);
            formData.append('googleMapUrl', values.googleMapUrl);
            formData.append('gstNumber', values.gstNumber);
            formData.append('role', values.role);
            
            if (values.profileImage) {
                formData.append('profileImage', values.profileImage);
            }
            
              // Append the subscription field
            formData.append('subscription.expiresAt', values.subscription.expiresAt);
            try{
                const response = await authService.register(formData);
                console.log(response)
                // const response = await authService.register(values)
                navigate('/user-list', { state: { successMessage: "Profile created successfully" } })
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
            

          } else {
            console.log('Form validation failed');
          }
    }

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }

  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Company Name</label>
                    <input type="text" className={`form-control ${formErrors.companyName?`is-invalid`:``}`} id="inputAMCName" value={values.companyName}  placeholder="Company Name" onChange={e=>{setValues({...values,companyName:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Company Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Email</label>
                    <input type="text" className={`form-control ${formErrors.email?`is-invalid`:``}`} id="inputAMCName" placeholder="Email"  value={values.email}  onChange={e=>{setValues({...values,email:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Email.
                     </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Password</label>
                    <input type="password" className={`form-control ${formErrors.password?`is-invalid`:``}`} id="inputAMCName" placeholder="Password"  value={values.password}  onChange={e=>{setValues({...values,password:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Password atleast of 8 character.
                     </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Contact Person</label>
                    <input type="text"className={`form-control ${formErrors.contactPerson?`is-invalid`:``}`} id="inputAMCDocNo" placeholder="Contact Person" value={values.contactPerson} onChange={e=>{setValues({...values,contactPerson:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact Person.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact</label>
                    <input type="tel"className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05"  placeholder="Contact" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Alternate Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="Alternate Number" value={values.alternateNumber} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">GST Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="GST Number" value={values.gstNumber} onChange={e=>{setValues({...values,gstNumber:e.target.value})}}/>
                </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea"  placeholder="Area" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className={`form-control ${formErrors.country?`is-invalid`:``}`}  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="City" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className={`form-control ${formErrors.state?`is-invalid`:``}`}  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control" placeholder='Zip Code' id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid City.
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Subscription End Date</label>
                    <input type="date" className="form-control"  id="inputCity" value={formatDateToYYYYMMDD(values.subscription.expiresAt)} onChange={e=>{setValues({...values,subscription:{expiresAt:formatDateToISOString(e.target.value)}})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Profile Image</label>
                    <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile01"
                        onChange={handleImageUpload}
                    />
                    </div>
                {/* <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Profile Image</label>
                        <input type="file" className="form-control" id="inputGroupFile01" value={values.profileImage} onChange={e=>{setValues({...values,profileImage:e.target.value})}}/>
                </div> */}
            </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
             <MDButton
                size="small"
                variant="contained"
                style={{ margin: '0 0 2vh 2vw', width: '8vw', height: '5.5vh' }}
                color="info"
                onClick={handleSubmit}>
                Create
            </MDButton>
          {/* <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Create
          </MDButton> */}
         
    </div>
  )
}

export default ProfileForm

