import HttpService from "./htttp.service";

class AuthService {
   authEndpoint = process.env.API_URL;

  login = async (payload) => {
    const loginEndpoint = '/api/auth/login';
    return await HttpService.post(loginEndpoint, payload);
  };

  executivelogin = async (payload) => {
    const loginEndpoint = '/api/executive/login-executive';
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (payload) => {
    const registerEndpoint = '/api/auth/register';
    const data =JSON.stringify(payload)
    return await HttpService.post(registerEndpoint, payload);
  };

  delete = async (id) => {
    const loginEndpoint = `api/auth/delete-user?id=${id}`;
    return await HttpService.delete(loginEndpoint);
  };
  logout = async () => {
    const logoutEndpoint = 'api/auth/logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'api/auth/forget-password';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'api/auth/reset-password';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async(id) => {
    const getProfile = `/api/auth/get-user?id=${id}`;
    return await HttpService.get(getProfile);
  }

  updateProfile = async (id,payload) => {
    const updateProfile = `/api/auth/update-user?id=${id}`;
    const data =JSON.stringify(payload)
    return await HttpService.put(updateProfile, payload);
  }

  adminDashboard = async (id,payload) => {
    const updateProfile = `/api/auth/admin-dashboard?id=${id}`;
    return await HttpService.get(updateProfile);
  }
  superAdminDashboard = async (id,payload) => {
    const updateProfile = `/api/auth/super-admin-dashboard`;
    return await HttpService.get(updateProfile);
  }

  allAdmin = async (search) => {
    const listOfAdmin = `/api/dashboard/all-admins?companyName=${search}`;
    return await HttpService.get(listOfAdmin);
  }
}

export default new AuthService();
