import HttpService from "./htttp.service";

class TodoService {
   authEndpoint = process.env.API_URL;

  list = async (id,searchQuery) => {
    const listEndpoint = `/api/task/list?userId=${id}&searchQuery=${searchQuery}`;
    return await HttpService.get(listEndpoint, searchQuery);
  };

  create = async (id,payload) => {
    const createTask = '/api/task/create';
    
    const data =JSON.stringify({
      "userId":`${id}`,
      "title": payload
     })
   
    return await HttpService.post(createTask, data);
  };

  delete = async (payload) => {
    const loginEndpoint = `/api/task/delete?id=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };
  update = async (payload) => {
    const loginEndpoint = `/api/task/update?id=${payload._id}`;

    var data = JSON.stringify({
      "title": payload.title,
      "isDone": !payload.isDone
    });

    return await HttpService.put(loginEndpoint,data);
  };
}

export default new TodoService();
