import React,{useState,useEffect} from 'react'
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate, useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import callService from 'services/call-service';
import authService from 'services/auth-service';

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

const ProfileForm = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [values,setValues] = useState({
        companyName: '',
        email: '',
        contact: '',
        alternateNumber: '',
        contactPerson: '',
       })
    
    const [formErrors, setFormErrors] = useState({
            companyName:'',
            email: '',
            contact: '',
            contactPerson: '',
        });

  
    useEffect(async() => {
        try{
            const list = await authService.getProfile(id)
            setValues({
                companyName: list.data.companyName,
                email: list.data.email,
                contact: list.data.contact,
                alternateNumber: list.data.alternateNumber,
                contactPerson: list.data.contactPerson,
            })
            
        }catch(err){
            console.log(err)
        }
        
    }, [])   

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            if(values.email.length == 0){
                newErrors.email='Invalid Email'
                valid = false
            }else{
                newErrors.email = ''
            }

            if(values.contact.length == 0){
                newErrors.contact='Invalid Contact'
                valid = false
            }else{
                newErrors.contact = ''
            }

            if(values.contactPerson.length == 0){
                newErrors.contactPerson='Invalid Contact Person'
                valid = false
            }else{
                newErrors.contactPerson = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await authService.updateProfile(id,values)
                console.log(response)
                navigate('/profile')
            }catch(err){
                console.log(err)
            }
           

          } else {
            console.log('Form validation failed');
          }
    }
 
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Company Name</label>
                    <input type="text" className={`form-control ${formErrors.companyName?`is-invalid`:``}`} id="inputAMCName" value={values.companyName}  placeholder="Company Name" onChange={e=>{setValues({...values,companyName:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Company Name.
                    </div>
                </div>
               
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Email</label>
                    <input type="text" className={`form-control ${formErrors.email?`is-invalid`:``}`} id="inputAMCName" placeholder="Email"  value={values.email}  onChange={e=>{setValues({...values,email:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Email.
                     </div>
                </div>
               
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Contact Person</label>
                    <input type="text"className={`form-control ${formErrors.contactPerson?`is-invalid`:``}`} id="inputAMCDocNo" placeholder="Contact Person" value={values.contactPerson} onChange={e=>{setValues({...values,contactPerson:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact Person.
                    </div>
                </div>
               
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact</label>
                    <input type="tel"className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact.
                    </div>
                </div>
               
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Alternate Number</label>
                    <input type="tel" className="form-control" id="validationServer05" value={values.alternateNumber} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                </div>
            </div>
         </form>   
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Update
          </MDButton>
         
    </div>
  )
}

export default ProfileForm

