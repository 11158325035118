/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Data
import executiveCallListTableData from "layouts/executiveCallList/data/executiveCallListTableData";
import { useNavigate } from "react-router-dom"
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import callService from "services/call-service";
import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import PopUp from "components/PopUp";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";


const tabStyles = {
  pending: {
    backgroundColor: '#313136',
    color: 'white',
    border: '2px solid #313136',
    margin: '0 14.45vw 2vh 7.225vw',
   // margin: '0 7.225vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  today: {
    backgroundColor: '#ff7d00',
    color: 'white',
    border: '2px solid #ff7d00',
    margin: '0 7.225vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
};
const tabInactiveStyles = {
  pending: {
    color: '#313136',
    backgroundColor: 'white',
    border: '2px solid #313136',
    margin: '0 14.45vw 2vh 7.225vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  today: {
    backgroundColor: 'white',
    color: '#ff7d00',
    border: '2px solid #ff7d00',
    margin: '0 7.225vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
};


function ExecutiveCallList() {
  // const { columns, rows, searchPara,filterStatus } = executiveCallListTableData();
  const navigate = useNavigate()
  const [refresh,setRefresh]= useState(false)
  const [data, setData] = useState([])
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const role = userDetail.decodedToken?.role;
  const parentId = userDetail.decodedToken?.parentId;
  const [filter,setFilter] = useState('today')
  const [popUp, setPopUp] = useState(false)
  const [message, setMessage] = useState('')
  const [callId, setCallId] = useState('')
  const [reason, setReason] = useState('')
  const [reasonError, setReasonError] = useState(false)
  const [pendingPopUp, setPendingPopUp] = useState(false)
  const [activeMainTab, setActiveMainTab] = useState('today');
  const [notes,setNotes] = useState('')
  function convertUTCtoSpecificTimeZone(utcDateString) {
    const utcDate = new Date(utcDateString);
  
    // Adjust time to UTC+5:30
    utcDate.setHours(utcDate.getHours());
    utcDate.setMinutes(utcDate.getMinutes());
  
    // Formatting the date
    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, '0');
    const date = String(utcDate.getDate()).padStart(2, '0');
    const hours = String(utcDate.getHours()).padStart(2, '0');
    const minutes = String(utcDate.getMinutes()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${date}`;
    const formattedTime = `${hours}:${minutes}`;
  
    return `${formattedDate},${formattedTime}`;
  }
    
  const handleMainTabClick = (tab) => {
    setFilter(tab)
  //  filterStatus(tab)
    setActiveMainTab(tab);
  };

  const cancelPopUp =()=>{
    setPendingPopUp(false)
    setPopUp(false)
    setMessage('')
  }

  const statusPending = async(id)=>{
    setCallId(id)
    setMessage('Please provide the reason.')
    setPendingPopUp(true)
  }

  const pending = async()=>{
    try{
      if(reason.length != 0){
        const data = await callService.updateStatus(callId,'pending',reason)
        setPendingPopUp(false)
        setMessage('')
        setRefresh(!refresh)
        setReasonError(false)
        setReason('')
      }else{
        setReasonError(true)
      }
      
    }catch(err){
      console.log(err)
    }
  }
  const completed = async()=>{
    try{
        const data = await callService.updateStatus(callId,'completed',notes)
        setPopUp(false)
        setMessage('')
        setRefresh(!refresh)
    }catch(err){
       console.log(err)
    }
  }

const statusCompleted =async(id)=>{
  setCallId(id)
  setMessage('Are you sure you had completed it?')
  setPopUp(true)
}

useEffect(async() => {
    const response = await callService.calllist(parentId,id,filter);
    setData(response.data)
}, [filter,refresh])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Executive Call List
                  {/* <MDInput 
                       label={<span style={{ color: 'white' }}>Search Here</span>} 
                      size="small"  
                      style={{"margin":"0 1vw 0 44vw"}} 
                      onChange={(e)=>searchList(e.target.value)}
                    />
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        style={{color:"white"}}
                      >
                        <i className="fa fa-filter"></i>
                      </a>

                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item"  onClick={() => setFilter("all")}>All</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contact")}>Contact</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contactPerson")}>Contact Person</a>
                        <a className="dropdown-item"  onClick={() => setFilter("name")}>Name</a>
                        <a className="dropdown-item"  onClick={() => setFilter("email")}>Email</a>
                      </div> */}
              
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={createCall}> 
                        Create
                  </MDButton> */}
                  
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={() =>filterByStatus('all')}> 
                        All Jobs
                  </MDButton> */}
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={() =>filterByStatus('completed')}> 
                        Completed Jobs
                  </MDButton> */}
                   {/* <MDTypography variant="h6" color="white">
                      <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 4vw"}} 
                              onClick={() =>setFilter('pending')}> 
                            Pending Jobs
                      </MDButton>
                      <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 2vw"}} 
                              onClick={() =>setFilter('today')}> 
                            Today's Job
                      </MDButton>
                    </MDTypography>     */}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <div>
                    <ul className="nav nav-pills custom-sub-tabs">
                      <li className="nav-item">
                        <a
                          //className={`nav-link ${activeMainTab === 'reassigned' ? 'active' : ''}`}
                          className="nav-link"
                          style={activeMainTab === 'today' ? tabStyles.today : tabInactiveStyles.today}
                          onClick={() => handleMainTabClick('today')}
                        >
                          Today's 
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                         // className={`nav-link ${activeMainTab === 'pending' ? 'active' : ''}`}
                         className="nav-link"
                         style={activeMainTab === 'all' ? tabStyles.pending : tabInactiveStyles.pending} 
                         onClick={() => handleMainTabClick('all')}
                        >
                          Pending
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                    {
                  data.map((item, index) => (
                    <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                          <MDBox
                            display="grid"
                            justifyContent="center"
                            alignItems="center"
                            bgColor='info'
                            color="blue"
                            width="16rem"
                            height="16rem"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                          >
                            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} style={{textAlign:"left"}}>
                                <MDTypography variant="h3" fontWeight="regular" textTransform="uppercase" color="light" align='left' marginBottom='1vh'>
                                  {item.companyName}
                                </MDTypography>
                                {item.address.length > 0 && <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" align='left' marginBottom='1vh'>
                                  <Icon fontSize="medium">
                                    location_on
                                  </Icon> 
                                  {`${item.address},${item.area},${item.city}`}<br/>
                                  {`${item.state},${item.pincode}` }
                                </MDTypography>}
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light" align='left' marginBottom='1vh'>
                                  <a href={`tel:${item.RMN}`}> <span className="material-symbols-outlined" style={{color:"white"}}>call</span> </a>
                                  {item.RMN}
                                </MDTypography>
                                {item.callDetailsNotes.length > 0 &&
                                // <>
                                <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" align='left' marginBottom='1vh'>
                                  <Icon fontSize="medium" marginBottom='1vh'>
                                    note_alt
                                  </Icon> 
                                    {item.callDetailsNotes}
                                </MDTypography>
                                // </>
                                }
                                <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" align='left'>
                                  <Icon fontSize="medium">
                                    calendar_month
                                  </Icon>
                                  {convertUTCtoSpecificTimeZone(item.callAttendDate)}
                                </MDTypography>
                            </MDBox>
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                                    <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              style={{margin: "1rem"}}
                                              onClick={()=>{statusPending(item._id)}}
                                              > 
                                            Pending
                                        </MDButton>
                                        <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              onClick={()=>{statusCompleted(item._id)}}
                                              > 
                                            Completed
                                      </MDButton>
                                </MDTypography>
                                  
                          </MDBox>
                    </MDBox>
                </Card>
                  ))
                }
                    </div>
            </div>

                {/* {
                  data.map((item, index) => (
                    <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                          <MDBox
                            display="grid"
                            justifyContent="center"
                            alignItems="center"
                            bgColor='info'
                            color="blue"
                            width="16rem"
                            height="16rem"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                          >
                            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                                <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
                                  {item.companyName}
                                </MDTypography>
                                <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" >
                                {`${item.address}, ${item.area},${item.city}, ${item.state}, ${item.pincode}` }
                                </MDTypography>
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                                  {item.RMN}
                                  <a href={`tel:${item.RMN}`}> <span className="material-symbols-outlined" style={{color:"white"}}>call</span> </a>
                                </MDTypography>
                                <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
                                  {item.callDetailsNotes}
                                </MDTypography>
                            </MDBox>
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                                    <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              style={{margin: "1rem"}}
                                              onClick={()=>{statusPending(item._id)}}
                                              > 
                                            Pending
                                        </MDButton>
                                        <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              onClick={()=>{statusCompleted(item._id)}}
                                              > 
                                            Completed
                                      </MDButton>
                                </MDTypography>
                                  
                          </MDBox>
                    </MDBox>
                </Card>
                  ))
                }
             */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
         {popUp &&
          <div className='overlay'> <Card style={{ 
                        position: 'fixed',
                        zIndex: 10000,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        '@media (min-width: 768px)': {
                          // Tablet view and above
                          transform: 'translate(-50%, -50%)',
                        },
                        '@media (min-width: 992px)': {
                          // Desktop view
                          transform: 'translate(-10%, -50%)',
                        },
                      }}>
                  <MDBox p={2} mx={3} display="grid" justifyContent="center">
                            {/* {message} */}
                            <label htmlFor="inputReason" required>Are you sure you had completed it?</label>
                              <input type="text" 
                                  className={`form-control`}
                                  id="inputAMCName"
                                  value={notes}  
                                  placeholder="Notes" 
                                  onChange={e=>{setNotes(e.target.value)}} 
                                />
                              <div id="validationServer03Feedback" className="invalid-feedback">
                                Please provide the reason
                              </div>
                          <MDTypography variant="h6" color="white">
                          <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={completed}
                                    > 
                                Yes
                            </MDButton>
                            <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={cancelPopUp}
                                    > 
                                  No
                            </MDButton>
                            </MDTypography>
                  </MDBox>
          </Card>
          </div>}
          {pendingPopUp && <div className='overlay'> <Card style={{ 
                                  position: 'fixed',
                                  zIndex: 10000,
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  '@media (min-width: 768px)': {
                                    transform: 'translate(-50%, -50%)',
                                  },
                                  '@media (min-width: 992px)': {
                                    transform: 'translate(-10%, -50%)',
                                  },
                                }}>
              <MDBox p={2} mx={3} display="grid" justifyContent="center">
                
                 {/* <div className="form-group col-md-4"> */}
                    <label htmlFor="inputReason" required>Please provide the reason</label>
                    <input type="text" 
                        className={`form-control ${reasonError?`is-invalid`:``}`}
                        id="inputAMCName"
                        value={reason}  
                        placeholder="" 
                        onChange={e=>{setReason(e.target.value)}} 
                      />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                       Please provide the reason
                    </div>
                {/* </div> */}
                <MDTypography variant="h6" color="white">
                          <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={pending}
                                    > 
                                Done
                            </MDButton>
                            <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={cancelPopUp}
                                    > 
                                  Cancel
                            </MDButton>
                            </MDTypography>
              </MDBox>
            </Card>
            </div>}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ExecutiveCallList;
