/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import executiveTableData from "layouts/executive/data/executiveTableData";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import CustomerForm from "./CustomerForm";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


function EditCustomer() {
//   const { columns, rows } = executiveTableData();
     const columns = []
     const rows = []
  const navigate = useNavigate()

    //Snackbar
    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
  
    useEffect(() => {
      if (location.state && location.state.successMessage) {
          setAlertMessage(location.state.successMessage);
          setOpenAlert(true);
      }
  }, [location.state]);
  
  const handleClose = () => {
      setOpenAlert(false);
  };
  //End Snackbar
  const listCustomer =()=>{
    navigate('/customers')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Customer
                  <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 58vw"}} 
                          onClick={listCustomer}> 
                        Back
                    </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <CustomerForm/>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Snackbar 
              open={openAlert} 
              autoHideDuration={6000} 
              style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
              onClose={handleClose}>
                <Alert severity="success" onClose={handleClose}>
                    {alertMessage}
                </Alert>
          </Snackbar>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default EditCustomer;
