import HttpService from "./htttp.service";

class CustomerService {
   authEndpoint = process.env.API_URL;

  list = async (parentId,searchValue,filterValue) => {
    const listOfCustomer = `/api/customer/list-customer-admin?parentId=${parentId}&page=1&limit=10&searchQuery=''&filter=''`;
    return await HttpService.get(listOfCustomer, searchValue);
  };

  alllist = async (parentId,searchValue,filterValue) => {
    const listOfCustomer = `/api/customer/all-customer?page=1&limit=10&searchQuery=''&filter=''`;
    return await HttpService.get(listOfCustomer, searchValue);
  };

  delete = async (payload) => {
    const loginEndpoint = `/api/customer/delete-customer?customerId=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };

  create = async (payload) => {
    const registerEndpoint = '/api/customer/create-customer';
    const data =JSON.stringify(payload)
    return await HttpService.post(registerEndpoint, data);
  };

  getByID = async (id) => {
    const getByIdEndpoint = `/api/customer/customer/${id}`;
    return await HttpService.get(getByIdEndpoint);
  };

  update = async (id,value) => {
    const registerEndpoint = `/api/customer/update-customer?customerId=${id}`;
    const data =JSON.stringify(value)
    return await HttpService.put(registerEndpoint,data);
  };
  /// /api/customer/search-customer?parentid=6513c529ef0a31371f20ada6&query=7666666666

  search = async (id,query) => {
    const searchEndpoint = `/api/customer/search-customer?parentid=${id}&query=${query}`;
    return await HttpService.get(searchEndpoint);
  };
  
//   logout = async () => {
//     const logoutEndpoint = 'api/auth/logout';
//     return await HttpService.post(logoutEndpoint);
//   };

//   forgotPassword = async (payload) => {
//     const forgotPassword = 'password-forgot';
//     return await HttpService.post(forgotPassword, payload);
//   }

//   resetPassword = async (credentials) => {
//     const resetPassword = 'password-reset';
//     return await HttpService.post(resetPassword, credentials);
//   }

//   getProfile = async() => {
//     const getProfile = 'me';
//     return await HttpService.get(getProfile);
//   }

//   updateProfile = async (newInfo) => {
//     const updateProfile = "me";
//     return await HttpService.patch(updateProfile, newInfo);
//   }
}

export default new CustomerService();
