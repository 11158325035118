/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton"
import DataTable from "examples/Tables/DataTable";

// Data
import priorityListData from "layouts/priority/data/priorityListData";
import TodoService from "services/todo-service";
import MDInput from "components/MDInput";
import departmentService from "services/department-service";
import jobSheetService from "services/jobSheet-service";
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom"




function DocNo() {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const navigate = useNavigate()
  const [values, setValues] = useState({
    amcDocNo: 1,
    jobSheetDocNo: 1,
    callDocNumber: 1
  })

  useEffect(async() => {
    try{
      const response = id && await jobSheetService.docNolist(id)
      
      setValues({
        amcDocNo: response.data.amcDocNumber,
        jobSheetDocNo: response.data.jobSheetDocNumber,
        callDocNumber: response.data.callDocNumber,
      })
    }catch(err){
      console.log(err)
    }
  }, [id])

  const handleSubmit= async(values)=>{
    try{
      const response = await jobSheetService.createDocNo(id,values)
      navigate('/settings')
    }catch(err){
      console.log(err)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Document Number
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <div className='form-container'>
                <form className='form-box' onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputamcName" required>AMC Doc No</label>
                            <input type="text" className="form-control" value={values.amcDocNo} id="inputamcName" placeholder="AMC Doc No"
                              onChange={e=>{setValues({...values,amcDocNo:e.target.value})}} 
                             />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputState">Jobsheet No</label>
                            <input type="tel" className="form-control" value={values.jobSheetDocNo} id="validationServer05" placeholder="Jobsheet No" 
                              onChange={e=>{setValues({...values,jobSheetDocNo:e.target.value})}} 
                             />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputState">Call Doc No</label>
                            <input type="tel" className="form-control" value={values.callDocNumber} id="validationServer05" placeholder="call Doc No" 
                              onChange={e=>{setValues({...values,callDocNumber:e.target.value})}} 
                             />
                        </div>
                    </div>
                    <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                          color="info"
                           onClick={()=>handleSubmit(values)}
                          > 
                          Update
                    </MDButton>
                </form>
                </div>
               
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default DocNo;
