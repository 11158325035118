import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import { useNavigate,useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import jobSheetService from 'services/jobSheet-service';
import executiveService from 'services/executive-service';
import MDChip from "components/MDChip";
import termsAndConditionService from "services/termsAndCondition-service";
import html2pdf from 'html2pdf.js';
import authService from "services/auth-service";
import DefaultProfile from "assets/images/34AD2.jpeg";

function convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

const JobSheetForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const parentId = userDetail.decodedToken?.userId;
    const navigate = useNavigate()
    const {id} = useParams()
    const [options,setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [startingDate, setStartingDate] = useState(formatDate(new Date()));
    const [endDate,setEndDate] = useState(getDefaultEndDate())
    const [creatDate,setCreatDate] = useState(formatDate(new Date()))
    const [search,setSearch] = useState('')
    const [executive,setExecutive] = useState([])
    const [termsAndConditionsList, setTermsAndConditionsList ] = useState([])
    const [print,setPrint] = useState(false)
    const [values,setValues] = useState({
        jobSheetNo:'',
        createdDate:creatDate,  
        companyName:'',
        contact:'',
        address:'',
        area:'',
        state:'',
        country:'',
        pincode:'',
        modelName:'',
        emiSerialPort:'',
        physicalCondition:'',
        problem:'',
        accessories:'',
        notes:'',
        estimatedPrice:'',
        executive:'',
        status:'running',
        termsAndCondition:'',
        parentId:parentId
       })


    useEffect(async() => {
        try{
            const jobsheet =  await jobSheetService.getByID(id)
            setValues({
                    jobSheetNo:jobsheet.data.jobSheetNo,
                    createdDate:jobsheet.data.createdDate,  
                    companyName:jobsheet.data.companyName,
                    contact:jobsheet.data.contact,
                    googleMapUrl:jobsheet.data.googleMapUrl,
                    address:jobsheet.data.address,
                    area:jobsheet.data.area,
                    state:jobsheet.data.state,
                    city:jobsheet.data.city,
                    country:jobsheet.data.country,
                    pincode:jobsheet.data.pincode,
                    modelName:jobsheet.data.modelName,
                    emiSerialPort:jobsheet.data.emiSerialPort,
                    physicalCondition:jobsheet.data.physicalCondition,
                    problem:jobsheet.data.problem,
                    accessories:jobsheet.data.accessories,
                    notes:jobsheet.data.notes,
                    estimatedPrice:jobsheet.data.estimatedPrice,
                    executive:jobsheet.data.executive,
                    status:jobsheet.data.status,
                    termsAndCondition:jobsheet.data.termsAndCondition,
                    parentId:jobsheet.data.parentId
                })
        }catch(err){
            console.log(err)
        }
    }, [])

    const [selectedOptions, setSelectedOptions] = useState([]);   
    const handleSelectedOptionsChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
        setValues({...values,executives:newSelectedOptions})
      };
    
    const [formErrors, setFormErrors] = useState({
        jobSheetNo:'',
        companyName:'',
        contact:'',
        // address:'',
        // area:'',
        // state:'',
        // country:'',
        // pincode:'',
        executive:'',
        });

    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

    useEffect(async() => {
        try{
            const executive =  await executiveService.list(parentId,'','')
            setExecutive(executive.data)
        }catch(err){
            console.log(err)
        }           
        }, [])    
    const formatedExecutiveList = executive.map((item) => ({
        value: item._id,
        label: item.name,
        }));

     
    useEffect(async() => {
        try{
            const list = await customerService.search(search)
            setOptions(list.data)
            
        }catch(err){
            console.log(err)
        }
        
    }, [search])   
    useEffect(async() => {
        try{
            const termsAndCondition = id ? await termsAndConditionService.list(id):{data:{termsAndConditionService:[]}}
            termsAndCondition.data && setTermsAndConditionsList(termsAndCondition.data.termsAndConditionsList)
        }catch(err){
            console.log(err)
        }           
    }, [id])

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.jobSheetNo.length == 0){
                newErrors.jobSheetNo='Invalid Job Sheet Number'
                valid = false
            }else{
                newErrors.jobSheetNo = ''
            }
            
            // if(values.companyName.length == 0){
            //     newErrors.companyName='Invalid Company Name'
            //     valid = false
            // }else{
            //     newErrors.companyName = ''
            // }

            if(values.contact.length < 10){
                newErrors.contact='Invalid Contact'
                valid = false
            }else{
                newErrors.contact = ''
            }

            // if(values.executive.length == 0){
            //     newErrors.executive='Invalid Executive'
            //     valid = false
            // }else{
            //     newErrors.executive = ''
            // }

            setFormErrors(newErrors);
            return valid;
        }   

        const printamc = async(id)=>{
            const response = await jobSheetService.getByID(id)
            const data = response.data
            const termsAndConditions = await termsAndConditionService.list(data.parentId)
            const termsAndConditionsList = termsAndConditions.data?.termsAndConditionsList
            const selectedTermsAndCondition = data.termsAndCondition.length > 0 ? getSelectedTermsAndCondition(termsAndConditionsList,data.termsAndCondition[0]) : '<ol>Default</ol>';
            const parent = await authService.getProfile(data.parentId)
            const parentDetails = parent.data
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // January is 0, so adding 1
            const year = currentDate.getFullYear();
            const imageUrl = (parentDetails.profileImage != '' && parentDetails.profileImage != undefined) ? `${process.env.REACT_APP_API_URL}${parentDetails.profileImage}` : `${DefaultProfile}`
           
            async function getImageBase64() {
              try {
                const base64Img = await convertImageToBase64(imageUrl);
                return base64Img;
              } catch (error) {
                console.error('Error occurred while converting image to Base64:', error);
                return null;
              }
            }
            const base64 =  await getImageBase64();
            const todayDate = `${day}-${month}-${year}`;
            const element =`
                 <div id="pdf-content">
                 <div class="centeredDivCustomerCopy">
                   <h5>CUSTOMER INWARD RECEIPT (CUSTOMER COPY)</h5>
                   <table class="table-container">
                     <tr>
                     <th class="th-print"><img class="logo-print" src='${base64}'/>
                     </th>
                       <th class="th-print">${parentDetails.companyName}</th>
                       <th class="th-print">
                         <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
                         <div class="address-container">
                           <p class="p-print">${parentDetails.email}</p>
                           <p class="p-print">website</p>
                           <p class="p-print">${parentDetails.contact}</p>
                         </div>
                       </th>
                     </tr>
                   </table>
                     <div class="customer-container">
                       <div class="customer-left">
                           <p class="p-print">Customer Name: ${data.companyName}</p>
                           <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                           <p class="p-print">Customer Contact: ${data.contact}</p>
                       </div>
                       <div class="customer-right">
                           <p class="p-print">Job Sheet No: ${data.jobSheetNo}</p>
                           <p class="p-print">Job Sheet Date: ${todayDate}</p>
                           <p class="p-print">Alternate Number: ${data.contact}</p>
                         </div>
                     </div>
                     <table class="table-container">
                         <thead>
                           <tr>
                             <th class="th-print" colspan="6">We Have Received Below Material For Repairing</th>
                           </tr>
                           <tr>
                             <th class="th-print">Model Name/ Number</th>
                             <th class="th-print">EMI/Serial/Part No</th>
                             <th class="th-print">Physical Condition</th>
                             <th class="th-print">Accessories</th>
                             <th class="th-print">Problem</th>
                             <th class="th-print">Estimated Price</th>
                           </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td class="td-print">${data.modelName}</td>
                           <td class="td-print">${data.emiSerialPort}</td>
                           <td class="td-print">${data.physicalCondition}</td>
                           <td class="td-print">${data.accessories}</td>
                           <td class="td-print">${data.problem}</td>
                           <td class="td-print">${data.estimatedPrice}</td>
                         </tr>
                         <tr>
                           <td class="td-print" colspan="6" style="text-align:left">Amount in words:</td>
                         </tr>
                         <tr>
                           <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
                         </tr>
                         <tr style={{height:'150px'}}>
                           <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
                         </tr>
                         <tr>
                           <td class="td-print" colSpan="6">
                           <div id="signatureBlock">
                             <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                             <div class="rightText">
                               For Company's Name<br/>
                               <div class="line-print"></div><br/>
                               Authorised signatory
                             </div>
                           </div>                
                           </td>
                         </tr>
                       </tbody>
                     </table>
                 </div>
                 <div class="centeredDivCustomerCopy">
                   <h5>CUSTOMER INWARD RECEIPT (OFFICE COPY)</h5>
                   <table class="table-container">
                   <tr>
                   <th class="th-print"><img class="logo-print" src='${base64}'/>
                     <th class="th-print">${parentDetails.companyName}</th>
                     <th class="th-print">
                       <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
                       <div class="address-container">
                         <p class="p-print">${parentDetails.email}</p>
                         <p class="p-print">website</p>
                         <p class="p-print">${parentDetails.contact}</p>
                       </div>
                     </th>
                   </tr>
                 </table>
                   <div class="customer-container">
                     <div class="customer-left">
                         <p class="p-print">Customer Name: ${data.companyName}</p>
                         <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                         <p class="p-print">Customer Contact: ${data.contact}</p>
                     </div>
                     <div class="customer-right">
                         <p class="p-print">Job Sheet No: ${data.jobSheetNo}</p>
                         <p class="p-print">Job Sheet Date: ${todayDate}</p>
                         <p class="p-print">Alternate Number: ${data.contact}</p>
                       </div>
                   </div>
                   <table class="table-container">
                       <thead>
                         <tr>
                           <th class="th-print" colspan="6">We Have Received Below Material For Repairing</th>
                         </tr>
                         <tr>
                           <th class="th-print">Model Name/ Number</th>
                           <th class="th-print">EMI/Serial/Part No</th>
                           <th class="th-print">Physical Condition</th>
                           <th class="th-print">Accessories</th>
                           <th class="th-print">Problem</th>
                           <th class="th-print">Estimated Price</th>
                         </tr>
                     </thead>
                     <tbody>
                       <tr>
                         <td class="td-print">${data.modelName}</td>
                         <td class="td-print">${data.emiSerialPort}</td>
                         <td class="td-print">${data.physicalCondition}</td>
                         <td class="td-print">${data.accessories}</td>
                         <td class="td-print">${data.problem}</td>
                         <td class="td-print">${data.estimatedPrice}</td>
                       </tr>
                       <tr>
                         <td class="td-print" colspan="6" style="text-align:left">Amount in words:</td>
                       </tr>
                       <tr>
                         <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
                       </tr>
                       <tr style={{height:'150px'}}>
                         <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
                       </tr>
                       <tr>
                         <td class="td-print" colSpan="6">
                         <div id="signatureBlock">
                           <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                           <div class="rightText">
                             For Company's Name<br/>
                             <div class="line-print"></div><br/>
                             Authorised signatory
                           </div>
                         </div>                
                         </td>
                       </tr>
                     </tbody>
                   </table>
               </div>
                 `    
            html2pdf()
            .from(element)
            .save('jobsheet.pdf');
          }

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await jobSheetService.update(id,values)
                print && printamc(id)
                navigate('/job-sheet', { state: { successMessage: "JobSheet updated successfully" } })
            }catch(err){
                setAlertMessage("Failed to create JobSheet");
                setOpenAlert(true);
                console.log(err)
            }

          } else {
            console.log('Form validation failed');
          }
    }
    const createCustomer=()=>{
        navigate('/create-customer')
    }
      
    const handleInputChange = (selected) => {
        setSelectedOption(selected);     
       
        selected.length > 0 && setValues((values) => ({...values,
                companyName:selected[0].companyName,
                address:selected[0].address,
                area:selected[0].area,
                country:selected[0].country,
                city:selected[0].city,
                state:selected[0].state,
                pincode:selected[0].pincode,
                googleMapUrl:selected[0].googleMapUrl,
                contact:selected[0].contact,
                email:selected[0].email,
                alternateNumber:selected[0].alternateNumber,
                contactPerson:selected[0].contactPerson,
                customerType:selected[0].customerType,
                gstNumber:selected[0].gstNumber,
                termsAndCondition:selected[0].termsAndCondition,
                parentId:selected[0].parentId,
            }))
    };

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
    
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="input" required>Customer Name<span className="text-danger">*</span></label>
                    <Typeahead
                        id="autocomplete-textfield"
                        labelKey="companyName"
                        options={options}
                        placeholder="Search Company Name"
                        selected={selectedOption.length > 0 ? selectedOption :  [values]}
                        onChange={handleInputChange}
                        className = {`${formErrors.companyName?`is-invalid`:``}`}
                        onInputChange={(text, e) => {setSearch(text)}}                      
                    />
                     <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Customer Name.
                    </div>
                </div>
                <MDButton 
                        size="small" 
                        variant="contained" 
                        style={{"width": "1vw","height": "1vw","margin":" 5vh 0 0 0"}} 
                        color="info"
                        onClick={createCustomer}
                        > 
                        +
                </MDButton>

                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Job Sheet No<span className="text-danger">*</span></label>
                    <input type="number" className={`form-control ${formErrors.jobSheetNo?`is-invalid`:``}`} id="inputAMCDocNo" value={values.jobSheetNo} placeholder="Job Sheet No" onChange={e=>{setValues({...values,jobSheetNo:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Job Sheet Number.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number<span className="text-danger">*</span></label>
                    <input type="tel" className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Job Sheet Number.
                    </div>
                </div>
            </div>
            <hr className='line'/>
            <div className="form-row">  
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Model Name/ Number</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="Model Name/Number"  value={values.modelName} onChange={e=>{setValues({...values,modelName:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">EMI/Serial/Part No</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="Model Name/Number"  value={values.emiSerialPort} onChange={e=>{setValues({...values,emiSerialPort:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Physical Condition</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="Physical Condition" value={values.physicalCondition} onChange={e=>{setValues({...values,physicalCondition:e.target.value})}}/>
                </div>
            </div>
             <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Problem</label>
                    <textarea className="form-control" id="validationServer05" value={values.problem} onChange={e=>{setValues({...values,problem:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Acessories</label>
                    <textarea className="form-control" id="validationServer05" value={values.accessories} onChange={e=>{setValues({...values,accessories:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Notes</label>
                    <textarea className="form-control" id="validationServer05" value={values.notes} onChange={e=>{setValues({...values,notes:e.target.value})}}/>
                </div>
             </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Estimated Price</label>
                    <input type="text"  className="form-control" value={values.estimatedPrice}  id="inputAddress" placeholder="Estimated Price" onChange={e=>{setValues({...values,estimatedPrice:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Executive</label>
                        <MDChip
                            selectedOptions={selectedOptions}
                            onSelectedOptionsChange={handleSelectedOptionsChange}
                            options={formatedExecutiveList}
                        />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Status</label>
                    <select id="inputCountry" className="form-control" value={values.status} onChange={e=>{setValues({...values,status:e.target.value})}}>
                        <option selected value='running'>Running</option>
                        <option selected value='completed'>Completed</option>
                        <option selected value='pending'>Pending</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Terms and Conditions</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/terms-and-condition')
                        } else {
                            setValues({...values,termsAndCondition:e.target.value})
                        }
                    }}
                    value={values.termsAndCondition}
                    >
                          <option value="default">Default</option>
                            {termsAndConditionsList && termsAndConditionsList.map((item,index) => (
                                             <option key={index} value={item.title}>{item.title}</option>
                                            ))}
                            <option value="create">Create</option>
                    </select>
                </div>
            </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <select id="inputCountry" className="form-control" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}>
                        <option selected>Choose Country</option>
                        <option>...</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control"  id="inputCity" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <select id="inputState" className="form-control" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}>
                        <option selected>Choose State</option>
                        <option>...</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control"  id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid Url.
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Print</label>
                    <div class="input-group mb-3">
                        <div className="form-check" style={{'margin-left': '8px'}}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="printCheckbox"
                                onChange={()=>setPrint(!print)}
                            />
                        </div>
                    </div>
                </div>
             </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                update
          </MDButton>
         
    </div>
  )
}

export default JobSheetForm

