import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import MDInput from "components/MDInput";
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import MDButton from 'components/MDButton';
import { useNavigate } from "react-router-dom"
import customerTypeService from 'services/customerType-service';
import customerService from 'services/customer-service';


  
const CustomerForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const parentId = userDetail.decodedToken?.userId;
    //const companyName = userDetail.decodedToken?.companyName;
    const [customerType, setCustomerType] = useState([])
    const navigate = useNavigate()
    const [values,setValues] = useState({
      companyName:'',
      email:'',
      contactPerson:'',
      contact:'',
      alternateNumber:'',
      customerType:'customer',
      gstNumber:'',
      address:'',
      area:'',
      city:'',
      pincode:'',
      state:'',
      country:'',
      parentId: parentId,
      googleMapUrl:'',
      notes:''
     })

    const [formErrors, setFormErrors] = useState({
        email: '',
        contact: '',
        name: '',
        contactPerson:'',
        address:'',
        pincode:'',
    }); 

    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar
    useEffect(() => {
       // !values.companyName && setValues({...values,companyName:companyName})
        !values.parentId && setValues({...values,parentId:parentId})
    }, [userDetail])

    const handleValidation = () => {
        let valid = true;
        const newErrors = { ...formErrors }; 

        // if(values.name.length == 0){
        //     newErrors.name='Invalid Name'
        //     valid = false
        // }else{
        //     newErrors.name = ''
        // }

        // if(values.email.length == 0){
        //     newErrors.email='Invalid Email'
        //     valid = false
        // }else{
        //     newErrors.email = ''
        // }
        if(values.companyName.length == 0){
            newErrors.companyName='Invalid Contact'
            valid = false
        }else{
            newErrors.contact = ''
        }

        if(values.contact.length == 0){
            newErrors.contact='Invalid Contact'
            valid = false
        }else{
            newErrors.contact = ''
        }

        // if(values.contactPerson.length == 0){
        //     newErrors.contactPerson='Invalid Contact Person'
        //     valid = false
        // }else{
        //     newErrors.contactPerson = ''
        // }

        // if(values.address.length == 0){
        //     newErrors.address='Invalid Address'
        //     valid = false
        // }else{
        //     newErrors.address = ''
        // }

        // if(values.pincode.length == 0){
        //     newErrors.pincode='Invalid Pincode'
        //     valid = false
        // }else{
        //     newErrors.pincode = ''
        // }

        // if(values.customerType.length == 0){
        //     newErrors.customerType='Invalid Customer Type'
        //     valid = false
        // }else{
        //     newErrors.customerType = ''
        // }


        setFormErrors(newErrors);
        return valid;
    }   

     useEffect(async() => {
      try{
          const customer =  await customerTypeService.list(parentId)
          setCustomerType(customer.data.customerTypeList)
      }catch(err){
          console.log(err)
      }           
    }, [parentId])
            
    const handleSubmit=async()=>{
        if(handleValidation()) {
            try{
                const response = await customerService.create(values)

                if(response.status == 200){
                    navigate('/customers', { state: { successMessage: "Customer created successfully" } })
                }else{
                    setAlertMessage(response.message);
                    setOpenAlert(true);
                }

                // Reset the form or navigate to another page as needed
               
            }catch(err){
                // Display an error alert
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
        } else {
            console.log('Form validation failed');
        }
    }

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
  
  return (
    <>
    <div className='form-container'>
        <form className='form-box'>
            <div className="form-row">
            <div className="form-group col-md-4">
                    <label htmlFor="inputState">Customer Type</label>
                    <select className="form-control" aria-label="Default select example"  
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/customer-type')
                        } else {
                          setValues({ ...values, customerType: e.target.value });
                        }
                      }}>
                            <option selected value="customer">Customer</option>
                            <option value="supplier">Supplier</option>
                                {customerType.map((item,index) => (
                                    <option key={index} value={item}>{item}</option>
                                    ))}
                            <option value="create">Create</option>
                    </select>
                </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Company/Customer Name<span className="text-danger">*</span></label>
                    <input type="text" className={`form-control ${formErrors.companyName?`is-invalid`:``}`} id="inputAMCName" value={values.companyName}  placeholder="Name" onChange={e=>{setValues({...values,companyName:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide Company Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number<span className="text-danger">*</span></label>
                    <input type="tel"className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05"  placeholder="Contact" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Contact Person</label>
                    <input type="text"className={`form-control ${formErrors.contactPerson?`is-invalid`:``}`} id="inputAMCDocNo" placeholder="Contact Person" value={values.contactPerson} onChange={e=>{setValues({...values,contactPerson:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact Person.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Alternate Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="Alternate Number" value={values.alternateNumber} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Email</label>
                    <input type="text" className={`form-control ${formErrors.email?`is-invalid`:``}`} id="inputAMCName" placeholder="Email"  value={values.email}  onChange={e=>{setValues({...values,email:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Email.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>GST Number</label>
                    <input type="text" className={`form-control ${formErrors.gstNumber?`is-invalid`:``}`} id="inputAMCName" value={values.gstNumber}  placeholder="Name" onChange={e=>{setValues({...values,gstNumber:e.target.value})}} />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please GST Number.
                    </div>
                </div>
                {/* here want division*/}
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className={`form-control ${formErrors.address?`is-invalid`:``}`}  id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea"  placeholder="Area" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="City" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text"  className={`form-control ${formErrors.state?`is-invalid`:``}`}  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className={`form-control ${formErrors.pincode?`is-invalid`:``}`} placeholder='Zip Code' id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text"  className={`form-control ${formErrors.country?`is-invalid`:``}`}  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid City.
                        </div>
                    </div>
                </div>
                  {/* here want division*/}
                <div className="form-group col-md-6">
                    <label htmlFor="inputZip">Notes</label>
                    <textarea className="form-control" id="validationServer05" value={values.notes} onChange={e=>{setValues({...values,notes:e.target.value})}}/>
                </div>
            </div>
        </form>   
          {/* Snackbar for alerts */}
          <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
        <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={handleSubmit}
                > 
                Create
        </MDButton>
    </div>
        {/* <div className='form-container'>    
            <div className='sub-container'>
                <div className='left'>
                    <div className='field-container'>
                        {validateEmail?
                        <MDInput type="email" label="Email" error style={{ "width": "92%"}} onChange={e=>{setValues({...values,email:e.target.value})}} required/>:
                        <MDInput type="email" label="Email" style={{ "width": "92%"}} onChange={e=>{setValues({...values,email:e.target.value})}} required/>
                        }
                    {validateEmail && <p style={{ position:"absolute",color: "red",fontSize:"1.5vh" }}>{validateEmail}</p>}
                    </div>
                     <div className='field-container'>
                        {validateContact?
                        <MDInput type="tel" label="Contact" error style={{ "width": "92%"}} onChange={e=>{setValues({...values,contact:e.target.value})}} required/>:
                        <MDInput type="tel" label="Contact" style={{ "width": "92%"}} onChange={e=>{setValues({...values,contact:e.target.value})}} required/>
                        }
                        
                        {validateContact && <p style={{ position:"absolute",color: "red",fontSize:"1.5vh" }}>{validateContact}</p>}
                    </div>
                    <div className='field-container'>
                        <FormControl style={{ "width": "92%","border": `${validateCustomerType?'0.3vh':'0'} solid ${validateCustomerType?'red':'#cbd0d5'}`,"borderRadius":"8px"}}>
                            <InputLabel style={{"backgroundColor":"white", "padding":"0 0.5vw"}}>Customer Type</InputLabel>
                            <Select
                                value={values.customerType}
                                style={dropdownStyle} 
                                onChange={e=>{setValues({...values,customerType:e.target.value})}}
                            >
                              {customerType.length > 0 && customerType.map((item,index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                        ))}
                               
                            </Select>
                          </FormControl>  
                            {validateCustomerType && <p style={{ position:"absolute",color: "red",fontSize:"1.5vh" }}>{validateCustomerType}</p>}
                        
                    </div>
                     <div className='field-container'>
                        <MDInput type="text" label="Address" style={{ "width": "92%"}} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    </div>
                     <div className='field-container'>
                        <MDInput type="text" label="City" style={{ "width": "92%"}} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    </div>
                     <div className='field-container'>
                        <FormControl style={{ "width": "92%"}}>
                            <InputLabel style={{"backgroundColor":"white", "padding":"0 0.5vw"}}>State</InputLabel>
                            <Select
                                value={values.state}
                                style={dropdownStyle} 
                                onChange={e=>{setValues({...values,state:e.target.value})}}
                            >
                                <MenuItem value="male">Maharashtra</MenuItem>
                                <MenuItem value="female">Gujarat</MenuItem>
                               
                            </Select>
                        </FormControl>
                    </div>   
                     <div className='field-container'>
                        <MDInput type="text" label="Notes" style={{ "width": "92%"}}  onChange={e=>{setValues({...values,notes:e.target.value})}}/>
                    </div>
                </div>
                <div className='right'>
                   <div className='field-container'>
                        <MDInput type="text" label="Contact Person" style={{ "width": "92%"}} onChange={e=>{setValues({...values,contactPerson:e.target.value})}}/>
                    </div>
                    <div className='field-container'>
                        <MDInput type="tel" label="Alternate Number" style={{ "width": "92%"}} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                    </div>
                   <div className='field-container'>
                        <MDInput type="text" label="GST Number" style={{ "width": "92%"}} onChange={e=>{setValues({...values,gstNumber:e.target.value})}}/>
                    </div>
                     <div className='field-container'>
                        <MDInput type="text" label="Area" style={{ "width": "92%"}} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                    </div>
                    <div className='field-container'>
                        <MDInput type="text" label="Pincode" style={{ "width": "92%"}} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    </div>
                     <div className='field-container'>
                        <FormControl style={{ "width": "92%"}}>
                            <InputLabel style={{"backgroundColor":"white", "padding":"0 0.5vw"}}>Country</InputLabel>
                            <Select
                                value={values.country}
                                style={dropdownStyle} 
                                onChange={e=>{setValues({...values,country:e.target.value})}}
                            >
                                <MenuItem value="male">India</MenuItem>
                            </Select>
                        </FormControl>
                    </div> 
                   <div className='field-container'>
                        <MDInput type="text" label="Google Url" style={{ "width": "92%"}}  onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                    </div>
                </div>
            </div>
        </div> 
        <div>
            <MDButton 
                    size="small" 
                    variant="contained" 
                    style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                    color="info"
                    onClick={()=>onSubmit(values)}
                    > 
                Create
            </MDButton>
        </div> */}
    </>
  )
  
}

export default CustomerForm