import React from 'react';
import Select from 'react-select';
import SelectedOption from './SelectedOption';

const MDChip = ({ selectedOptions, onSelectedOptionsChange, options }) => {

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '1rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '0.8rem',
      marginBottom: '0.4rem',
      fontWeight: '800'
    }),
  };

  return (
    <Select
      isMulti
      options={options}
      styles={customStyles}
      value={selectedOptions}
      onChange={onSelectedOptionsChange}
      placeholder="Add recipients..."
      components={{ MultiValue: SelectedOption }}
    />
  );
};

export default MDChip;
