/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Data
import callTableData from "layouts/call/data/callTableData";
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import MultipleCustomerForm from "./MultipleCustomerForm";

function MultipleCustomer() {
  const { columns, rows, searchPara,filterStatus } = callTableData();
  const [filter,setFilter] = useState('all')
  const navigate = useNavigate()

  const createCall =()=>{ 
    navigate('/create-call')
  }

  const searchList = async(searchValue)=>{
    searchPara(searchValue,filter)
  }
  const filterByStatus = async(status)=>{
    filterStatus(status)
  }
  // const downloadSampleXls = ()=>{
  //   const sampleXLSData = [
  //     [
  //       'companyName',
  //       'email',
  //       'contact',
  //       'alternateNumber',
  //       'contactPerson',
  //       'customerType',
  //       'gstNumber',
  //       'googleMapUrl',
  //       'address',
  //       'area',
  //       'state',
  //       'country',
  //       'pincode',
  //       'notes',
  //     ],
  //     [
  //       'ABC Company',
  //       'abc@example.com',
  //       '1234567890',
  //       '9876543210',
  //       'John Doe',
  //       'Regular',
  //       'GST12345',
  //       'https://maps.google.com',
  //       '123 Main St',
  //       'Downtown',
  //       'California',
  //       'USA',
  //       '12345',
  //       'Additional notes here',
  //     ],
  //     [
  //       'XYZ Corporation',
  //       'xyz@example.com',
  //       '5555555555',
  //       '9999999999',
  //       'Jane Smith',
  //       'Premium',
  //       'GST67890',
  //       'https://maps.google.com/xyz',
  //       '456 Elm St',
  //       'Uptown',
  //       'Texas',
  //       'USA',
  //       '54321',
  //       'More notes for XYZ',
  //     ],
  //   ];
  //   const blob = new Blob([sampleXLSData], { type: 'application/vnd.ms-excel' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'sample.xlsx';
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }
  const downloadSampleXls=()=>{
    // const xlsFileUrl = `sample.csv`;

    // // Create a hidden link and trigger the download
    // const link = document.createElement('a');
    // link.href = xlsFileUrl;
    // link.download = 'sample.xls'; // You can specify the file name here
    // link.style.display = 'none';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    const csvData = `companyName,email,contact,alternateNumber,contactPerson,customerType,gstNumber,googleMapUrl,address,area,state,country,pincode,notes
    ABC Company,abc@example.com,1234567890,9876543210,John Doe,Regular,GST12345,https://maps.google.com,123 Main St,Downtown,California,USA,12345,Additionalnotes here`;

    // Convert the CSV data to a Blob
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a data URI for the Blob
    const dataUri = URL.createObjectURL(blob);

    // Create a hidden link and trigger the download
    const link = document.createElement('a');
    link.href = dataUri;
    link.download = 'sample.csv'; // Specify the file name here
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Multiple Customer
                  <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 50vw"}} 
                          onClick={downloadSampleXls}
                          > 
                        Download XLS Sample
                    </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MultipleCustomerForm/>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default MultipleCustomer;
