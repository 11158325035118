import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import jobSheetService from 'services/jobSheet-service';
import executiveService from 'services/executive-service';
import MDChip from "components/MDChip";
import termsAndConditionService from "services/termsAndCondition-service";

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

const JobSheetForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const id = userDetail.decodedToken?.userId;
    const navigate = useNavigate()
    const [options,setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [startingDate, setStartingDate] = useState(formatDate(new Date()));
    const [endDate,setEndDate] = useState(getDefaultEndDate())
    const [creatDate,setCreatDate] = useState(formatDate(new Date()))
    const [search,setSearch] = useState('')
    const [executive,setExecutive] = useState([])
    const [docNumber,setDocNumber] = useState(0)
    const [termsAndConditionsList, setTermsAndConditionsList ] = useState([])
    const [values,setValues] = useState({
        jobSheetNo:docNumber,
        createdDate:creatDate,  
        companyName:'',
        contact:'',
        googleMapUrl:'',
        address:'',
        area:'',
        state:'',
        country:'',
        pincode:'',
        modelName:'',
        emiSerialPort:'',
        physicalCondition:'',
        problem:'',
        accessories:'',
        notes:'',
        estimatedPrice:'',
        executive:'',
        status:'pending',
        termsAndCondition:'',
        parentId:id
       })
    const [selectedOptions, setSelectedOptions] = useState([]);   
    const handleSelectedOptionsChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
        setValues({...values,executive:newSelectedOptions})
      };

    const [formErrors, setFormErrors] = useState({
        jobSheetNo:'',
        companyName:'',
        contact:'',
        // address:'',
        // area:'',
        // state:'',
        // country:'',
        // pincode:'',
        executive:'',
        });
    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

    useEffect(async() => {
        try{
            const response = id != undefined && await jobSheetService.docNolist(id)
            //response.data && setDocNumber(response.data.jobSheetDocNumber)
            response.data && setValues({
                ...values,
                jobSheetNo: response.data.jobSheetDocNumber
            })
        }catch(err){
            console.log(err)
        }
    },[id])
    useEffect(async() => {
        try{
            const executive = id != undefined && await executiveService.list(id,'','')
            executive.data && setExecutive(executive.data)
        }catch(err){
            console.log(err)
        }           
    }, [id])    
    useEffect(async() => {
        try{
            const termsAndCondition = id ? await termsAndConditionService.list(id):{data:{termsAndConditionService:[]}}
            termsAndCondition.data && setTermsAndConditionsList(termsAndCondition.data.termsAndConditionsList)
        }catch(err){
            console.log(err)
        }           
    }, [id])

    const formatedExecutiveList = executive.map((item) => ({
        value: item._id,
        label: item.name,
        }));
    useEffect(async() => {
        try{
            const list = id != undefined && await customerService.search(id,search,'')
            setOptions(list.data)
            
        }catch(err){
            console.log(err)
        }
        
    }, [search,id])   

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.jobSheetNo.length == 0){
                newErrors.jobSheetNo='Invalid Job Sheet Number'
                valid = false
            }else{
                newErrors.jobSheetNo = ''
            }
            
            // if(values.companyName.length == 0){
            //     newErrors.companyName='Invalid Company Name'
            //     valid = false
            // }else{
            //     newErrors.companyName = ''
            // }

            if(values.contact.length < 10){
                newErrors.contact='Invalid Contact'
                valid = false
            }else{
                newErrors.contact = ''
            }

            // if(values.executive.length == 0){
            //     newErrors.executive='Invalid Executive'
            //     valid = false
            // }else{
            //     newErrors.executive = ''
            // }

            setFormErrors(newErrors);
            return valid;
        }   

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await jobSheetService.create(values)
                navigate('/job-sheet', { state: { successMessage: "JobSheet created successfully" } })
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }

          } else {
            console.log('Form validation failed');
          }
    }
    const createCustomer=()=>{
        navigate('/create-customer')
    }
      
    const handleInputChange = (selected) => {
        setSelectedOption(selected);     
        selected.length > 0 && setValues((values) => ({...values,
                companyName:selected[0].companyName,
                address:selected[0].address,
                area:selected[0].area,
                country:selected[0].country,
                city:selected[0].city,
                state:selected[0].state,
                pincode:selected[0].pincode,
                googleMapUrl:selected[0].googleMapUrl,
                contact:selected[0].contact,
                email:selected[0].email,
                alternateNumber:selected[0].alternateNumber,
                contactPerson:selected[0].contactPerson,
                customerType:selected[0].customerType,
                gstNumber:selected[0].gstNumber,
                termsAndCondition:selected[0].termsAndCondition,
                parentId:id,
            }))
    };

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
    
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="input" required>Customer Name<span className="text-danger"></span></label>
                    <Typeahead
                        id="autocomplete-textfield"
                        labelKey="companyName"
                        options={options}
                        placeholder="Start typing..."
                        selected={selectedOption}
                        onChange={handleInputChange}
                        className = {`${formErrors.companyName?`is-invalid`:``}`}
                        onInputChange={(text, e) => {setSearch(text)}}                      
                    />
                     <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Customer Name.
                    </div>
                </div>
                <MDButton 
                        size="small" 
                        variant="contained" 
                        style={{"width": "1vw","height": "1vw","margin":" 5vh 0 0 0"}} 
                        color="info"
                        onClick={createCustomer}
                        > 
                        +
                </MDButton>

                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Job Sheet No<span className="text-danger">*</span></label>
                    <input type="number" className={`form-control ${formErrors.jobSheetNo?`is-invalid`:``}`} value={values.jobSheetNo} id="inputAMCDocNo" placeholder="Job Sheet No" onChange={e=>{setValues({...values,jobSheetNo:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Job Sheet Number.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number<span className="text-danger">*</span></label>
                    <input type="tel" className={`form-control ${formErrors.contact?`is-invalid`:``}`} placeholder="Contact"  id="validationServer05" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Job Sheet Number.
                    </div>
                </div>
            </div>
            <hr className='line'/>
            <div className="form-row">  
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Model Name/ Number</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="Model Name/Number" onChange={e=>{setValues({...values,modelName:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">EMI/Serial/Part No</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="EMI/Serial/Part No" onChange={e=>{setValues({...values,emiSerialPort:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Physical Condition</label>
                    <input type="text" className="form-control" id="inputAMCDocNo" placeholder="Physical Condition" onChange={e=>{setValues({...values,physicalCondition:e.target.value})}}/>
                </div>
            </div>
             <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Problem</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,problem:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Acessories</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,accessories:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Notes</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,notes:e.target.value})}}/>
                </div>
             </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Estimated Price</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Estimated Price" onChange={e=>{setValues({...values,estimatedPrice:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Executive</label>
                    <MDChip
                        selectedOptions={selectedOptions}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                        options={formatedExecutiveList}
                    />
                    {/* <select id="inputCountry" className="form-control" onChange={e=>{setValues({...values,executive:e.target.value})}}>
                        <option selected>Choose Executive</option>
                        {executive.length > 0 && executive.map((item,index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                    </select> */}
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Status</label>
                    <select id="inputCountry" className="form-control" onChange={e=>{setValues({...values,status:e.target.value})}}>
                        <option selected value='running'>Running</option>
                        <option value='completed'>Completed</option>
                        <option value='pending'>Pending</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Terms and Conditions</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/terms-and-condition')
                        } else {
                            setValues({...values,termsAndCondition:e.target.value})
                        }
                    }}
                    value={values.termsAndCondition}
                    >
                          <option value="default">Default</option>
                            {termsAndConditionsList && termsAndConditionsList.map((item,index) => (
                                             <option key={index} value={item.title}>{item.title}</option>
                                            ))}
                            <option value="create">Create</option>
                    </select>
                </div>
            </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea" placeholder="Area"  value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="City"  value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control"  id="validationServer05" placeholder="Zip"  value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid Url.
                        </div>
                    </div>
                </div>
             </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Create
          </MDButton>
         
    </div>
  )
}

export default JobSheetForm

