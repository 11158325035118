

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import authService from "services/auth-service";
import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import executiveService from "services/executive-service";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import { GraphicEq } from "@mui/icons-material";



function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const adminId = userDetail.decodedToken?.parentId;
  const role = userDetail.decodedToken?.role
  const [data, setData] = useState([])
  const [graph, setGraph] = useState()
  const [pieData, setPieData] = useState()
  const [filter,setFilter ] = useState('weekly')

  const handleChildStateChange = (newState) => {
    setFilter(newState); // Update the parent component's state with the new state from the child
  };

  const filteredGraphData = useMemo(() => {
    // Filter the graph data based on the selectedFilter value
    switch (filter) {
      case "weekly":
        return {
                        labels: graph?.call7DaysCounts?.dates,
                        datasets: [
                          {
                            label: "Calls",
                            color: "info",
                            data: graph?.call7DaysCounts?.countValues,
                          },
                          {
                            label: "Job Sheets",
                            color: "dark",
                            data: graph?.jobsheet7DaysCount?.countValues,
                          },
                          {
                            label: "AMC",
                            color: "primary",
                            data: graph?.amcCall7DaysCounts?.countValues,
                          },
                        ],
                      }; 
      case "1 month":
        return {
                        labels: graph?.lastOneMonthCallData?.dates,
                        datasets: [
                          {
                            label: "Calls",
                            color: "info",
                            data: graph?.lastOneMonthCallData?.countValues,
                          },
                          {
                            label: "Job Sheets",
                            color: "dark",
                            data: graph?.lastOneMonthJobSheetData?.countValues,
                          },
                          {
                            label: "AMC",
                            color: "primary",
                            data: graph?.lastOneMonthAMCData?.countValues,
                          },
                        ],
                      }; 
      case "3 months":
        return {
                        labels: graph?.lastThreeMonthsCallData?.dates,
                        datasets: [
                          {
                            label: "Calls",
                            color: "info",
                            data: graph?.lastThreeMonthsCallData?.countValues,
                          },
                          {
                            label: "Job Sheets",
                            color: "dark",
                            data: graph?.lastThreeMonthsJobSheetData?.countValues,
                          },
                          {
                            label: "AMC",
                            color: "primary",
                            data: graph?.lastThreeMonthsAMCData?.countValues,
                          },
                        ],
                      }; 
      case "6 months":
        return {
                        labels: graph?.lastSixMonthsCallData?.dates,
                        datasets: [
                          {
                            label: "Calls",
                            color: "info",
                            data: graph?.lastSixMonthsCallData?.countValues,
                          },
                          {
                            label: "Job Sheets",
                            color: "dark",
                            data: graph?.lastSixMonthsJobSheetData?.countValues,
                          },
                          {
                            label: "AMC",
                            color: "primary",
                            data: graph?.lastSixMonthsAMCData?.countValues,
                          },
                        ],
                      }; 
      default:
        return {
                        labels: graph?.call7DaysCounts?.dates,
                        datasets: [
                          {
                            label: "Calls",
                            color: "info",
                            data: graph?.call7DaysCounts?.countValues,
                          },
                          {
                            label: "Job Sheets",
                            color: "dark",
                            data: graph?.jobsheet7DaysCount?.countValues,
                          },
                          {
                            label: "AMC",
                            color: "primary",
                            data: graph?.amcCall7DaysCounts?.countValues,
                          },
                        ],
                      };
    }
  }, [graph, filter]);
     
  useEffect(async() => {
    try{
      const response = role == 'super admin' ? await authService.superAdminDashboard(id)
                                              :
                                              role == 'admin'? await authService.adminDashboard(id)
                                                              : 
                                                              await executiveService.dashboard(id,adminId)

      setData(response.data)
      setGraph(response.graph)
      setPieData(response.pieData)
    }catch(err){
      console.log(err)
    }
  }, [role,id,adminId])
  
  
   return (<>
   {id && <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
        {data.length > 0 && data.map((item, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color={item.color}
                  icon={item.icon}
                  title={item.title}
                  count={item.count}
                  percentage={{ color: "success", amount: `${item.totalCount}`, label: "Total"  }}
                />
              </MDBox>
            </Grid>
          ))}
            <Grid item xs={24} md={6} lg={6}>
              <MDBox mb={1.5}>
                {console.log(graph)}
                   {graph && <GradientLineChart
                      icon={{ color: "info", component: "leaderboard" }}
                      title="Gradient Line Chart"
                      description="Visits from devices"
                      onStateChange={handleChildStateChange}
                      chart={filteredGraphData}
                      // chart={{
                      //   labels: graph.call7DaysCounts?.dates,
                      //   datasets: [
                      //     {
                      //       label: "Calls",
                      //       color: "info",
                      //       data: graph.call7DaysCounts.countValues,
                      //     },
                      //     {
                      //       label: "Job Sheets",
                      //       color: "dark",
                      //       data: graph.jobsheet7DaysCount.countValues,
                      //     },
                      //     {
                      //       label: "AMC",
                      //       color: "primary",
                      //       data: graph.amcCall7DaysCounts.countValues,
                      //     },
                      //   ],
                      // }}
                    />}
              </MDBox>
            </Grid>
            <Grid item xs={24} md={6} lg={6}>
              <MDBox mb={1.5}>
             {pieData &&  <DefaultDoughnutChart
                    icon={{ color: "info", component: "leaderboard" }}
                    title="Default Doughnut Chart"
                    description="Affiliates program"
                    chart={{
                      labels: ["Call","Job Sheet","AMC"],
                      datasets: {
                        label: "Projects",
                        backgroundColors: ["info", "dark", "primary"],
                        data: [pieData[0], pieData[1], pieData[2]],
                      },
                    }}
                  />}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
    </DashboardLayout>}
    </>
  );
}

export default Dashboard;
