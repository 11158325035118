/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState,useEffect } from "react";
import ExecutiveService from "services/executive-service";
import { useNavigate } from "react-router-dom"
import superAdminService from "services/superAdmin-service";
import authService from "services/auth-service";
import DefaultProfile from "assets/images/34AD2.jpeg";



export default function data() {
    const Detail = ({ image, name, email,address }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography><br/>
            <MDTypography variant="caption">{address}</MDTypography>
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('')
    const navigate = useNavigate()

    const editTask = (id)=>{
            navigate(`/edit-user/${id}`)
    }

    const deleteUser =async(id)=>{
      try{
        const response = await authService.delete(id);
        setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }

    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
      setRefresh(!refresh)
    }

    function searchInData(searchTerm,data) {
      const filteredData = data.filter(item => {
        const { area, companyName, email, contact } = item;
    
        const areaString = area ? area.toLowerCase() : '';
        const companyNameString = companyName ? companyName.toLowerCase() : '';
        const emailString = email ? email.toLowerCase() : '';
    
        return (
          areaString.includes(searchTerm.toLowerCase()) ||
          companyNameString.includes(searchTerm.toLowerCase()) ||
          emailString.includes(searchTerm.toLowerCase()) ||
          (contact && contact.toString().includes(searchTerm))
        );
      });
    
      return filteredData;
    }

      useEffect(() => {
        async function fetchData() {
            try {
                const response = await superAdminService.list(searchValue,filterValue);
                  
               // const data = response.data; 
                const data = searchInData(searchValue, response.data)

                let sortedData = [];
                
                  switch (filterValue) {
                    case 'contact':
                      sortedData = data.slice().sort((a, b) => a.contact - b.contact);
                      break;
                    case 'department':
                      sortedData = data.slice().sort((a, b) => a.department.localeCompare(b.department));
                      break;
                    case 'designation':
                      sortedData = data.slice().sort((a, b) => a.designation.localeCompare(b.designation));
                      break;
                    case 'name':
                      sortedData = data.slice().sort((a, b) => a.companyName.localeCompare(b.companyName));
                      break;
                    case 'email':
                      sortedData = data.slice().sort((a, b) => a.email.localeCompare(b.email));
                      break;
                    case 'area':
                      sortedData = data.slice().sort((a, b) => a.area.localeCompare(b.area));
                      break;
                    case 'expiresat':
                      sortedData = data.slice().sort((a, b) => new Date(a.subscription.expiresAt) - new Date(b.subscription.expiresAt));
                      break;
                    case 'active':
                      sortedData = data.filter((item) => new Date(item.subscription.expiresAt) >= new Date());
                      break;
                    case 'inactive':
                      sortedData = data.filter((item) => new Date(item.subscription.expiresAt) < new Date());
                      break;
                    default:
                      sortedData = data;
                      break;
                  }
                  
                const newColumns = [
                    { Header: "Detail", accessor: "detail", width: "45%", align: "left" },
                    { Header: "status", accessor: "status", align: "left" },
                    // { Header: "status", accessor: "status", align: "center" },
                    { Header: "contact", accessor: "contact", align: "center" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];
                  const newRows = sortedData.map((item) => ({
                    
                    detail: (
                      
                      <Detail
                       // image={`${process.env.REACT_APP_API_URL}${item.profileImage}`}// You may need to update this part to dynamically select an image based on item data
                       image={item.profileImage && item.profileImage !== '' ? `${process.env.REACT_APP_API_URL}${item.profileImage}` : `${DefaultProfile}`} 
                       name={item.companyName}
                        email={item.email}
                        address={`${item.address}, ${item.area}, ${item.state}, ${item.pincode}` }
                      />
                    ),
                    status: (
                      <MDBox ml={-1}>
                        <MDBadge 
                          badgeContent={new Date(item.subscription.expiresAt) < new Date()?"Expire":"Active"} 
                          color={new Date(item.subscription.expiresAt) < new Date()?"error":"success"} 
                          variant="gradient" size="sm" 
                          // onClick={()=>{editTask(item)}}
                          style={{"cursor":"pointer"}}
                          />
                      </MDBox>
                    ),
                    contact: (
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                         <a href={`tel:${item.contact}`}>   
                            {item.contact}  
                        </a>
                      </MDTypography>
                    ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  href={`/edit-executive/${item._id}`}
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{editTask(item._id)}}
                                >
                                    edit
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{deleteUser(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    delete
                                </span>
                              </>
                    ),
                  }));
          
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {
                console.log(res)
                // if (res.hasOwnProperty("message")) {
                //   setCredentialsError(res.message);
                // } else {
                //   setCredentialsError(res.errors[0].detail);
                // }
              }
        }
        fetchData()
      }, [searchValue,refresh,filterValue])
     
     
  

  return {
    columns,
    rows,
    searchPara
  };
}
