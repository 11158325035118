
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import CustomerService from "services/customer-service";
import { useNavigate } from "react-router-dom"
import amcService from "services/amc-service";
import html2pdf from 'html2pdf.js';
import termsAndConditionService from "services/termsAndCondition-service";
import '../../../print.css'
import authService from "services/auth-service";
import DefaultProfile from "assets/images/34AD2.jpeg";

function convertImageToBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

function extractDate(dateTimeString) {
  const dateObj = new Date(dateTimeString); // Convert string to Date object
  const formattedDate = dateObj.toISOString().split('T')[0]; // Extract date part
  return formattedDate;
}


export default function data() { 
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;

    const Detail = ({ image, name, email,address }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {/* <MDAvatar src={image} name={name} size="sm" /> */}
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography><br/>
            <MDTypography variant="caption">{address}</MDTypography>
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)  
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('')
    const navigate = useNavigate()

    const editTask = (id)=>{
            navigate(`/edit-amc/${id}`)
    }

    const deleteTask =async(id)=>{
      try{
        const response = await amcService.delete(id);
        setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }

    const getSelectedTermsAndCondition = (termsAndConditionsList,title) => {
      const selectedTerms = termsAndConditionsList.find(
        (condition) => condition.title === title
      );
    
      return selectedTerms ? selectedTerms.content : null;
    };

    const printamc = async(id)=>{

      const response = await amcService.getByID(id)
      const data = response.data
      const termsAndConditions = await termsAndConditionService.list(data.parentId)
      const termsAndConditionsList = termsAndConditions.data?.termsAndConditionsList
      const selectedTermsAndCondition = data.termsAndCondition.length > 0 ? getSelectedTermsAndCondition(termsAndConditionsList,data.termsAndCondition[0]) : '<ol>Default</ol>';
      const parent = await authService.getProfile(data.parentId)
      const parentDetails = parent.data
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // January is 0, so adding 1
      const year = currentDate.getFullYear();
      const imageUrl = (parentDetails.profileImage != '' && parentDetails.profileImage != undefined) ? `${process.env.REACT_APP_API_URL}${parentDetails.profileImage}` : `${DefaultProfile}`
      async function getImageBase64() {
        try {
          const base64Img = await convertImageToBase64(imageUrl);
          return base64Img;
        } catch (error) {
          console.error('Error occurred while converting image to Base64:', error);
          return null;
        }
      }
      const base64 =  await getImageBase64();

      const todayDate = `${day}-${month}-${year}`;

      const generatePDFContent = (title,isNewPage) => {
  //       const element = `
  //     <div id="pdf-content" class="invoice-box">
  //     <table cellPadding="0" cellSpacing="0">
  //       <tbody>
  //         <tr class="top_rw">
  //           <td colSpan="2">
  //             <br/>
  //             <br/>
  //             <h2 style={{ marginBottom: 0 }}> ${title} </h2>
  //             <span style={{}}>Date: ${todayDate} </span>
  //           </td>
  //         </tr>
  //         <tr class="top">
  //           <td colSpan="3">
  //             <table>
  //               <tbody>
  //                 <tr>
  //                   <td>
  //                   <b> Sold By: ${parentDetails.companyName || 'N/A'} </b> <br />
  //                   ${parentDetails.address || 'N/A'},${parentDetails.area || 'N/A'} <br />
  //                   ${parentDetails.city || 'N/A'},${parentDetails.state || 'N/A'} <br />
  //                   ${parentDetails.country || 'N/A'},${parentDetails.pincode || 'N/A'}<br />
  //                   ${parentDetails.contact || 'N/A'} <br />
  //                   GSTIN: ${parentDetails.gstNumber || 'N/A'} <br />
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </td>
  //         </tr>
  //         <tr class="information">
  //           <td colSpan="3">
  //             <table>
  //               <tbody>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <b> Customer Details: </b> <br />
  //                     Company Name: ${data.companyName} <br />
  //                     Email: ${data.email}<br />
  //                     Contact: ${data.contact}<br />
  //                     Address: ${data.address}, ${data.area},${data.city},${data.state},${data.pincode},${data.country}<br />
  //                     Customer Type: ${data.customerType}<br />
  //                     GST: ${data.gstNumber}
  //                   </td>
  //                   <td>
  //                     <b> AMC Details: </b> <br />
  //                     AMC Doc No: ${data.amcDocNo}<br />
  //                     AMC Name:${data.amcName}<br />
  //                     AMC Category:${data.amcCategory} <br />
  //                     No of Services: ${data.numberOfService} <br />
  //                     Starting Date: ${data.startingDate.split('T')[0]} <br />
  //                     End Date: ${data.endDate.split('T')[0]} <br />
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </td>
  //         </tr>
  //         <tr>
  //           <td colSpan="3">
  //             <table cellSpacing="0px" cellPadding="2px">
  //               <tbody>
  //                 <tr>
  //                   <td width="50%">
  //                     <b> Terms And Condition: </b> <br />
  //                     ${selectedTermsAndCondition}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td width="50%"></td>
  //                   <td>
  //                     <b> Authorized Signature </b> <br />
  //                     <br />
  //                     ................................... <br />
  //                     <br />
  //                     <br />
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   </div>
  // </>
  //     `;
    
      const element =`
      <div id="pdf-content">
      <div class="centeredDivCustomerCopy">
        <h5>CUSTOMER INWARD RECEIPT (${title})</h5>
        <table class="table-container">
          <tr>
          <th class="th-print"><img class="logo-print" src='${base64}'/>
          </th>
            <th class="th-print">${parentDetails.companyName}</th>
            <th class="th-print">
              <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
              <div class="address-container">
                <p class="p-print">${parentDetails.email}</p>
                <p class="p-print">website</p>
                <p class="p-print">${parentDetails.contact}</p>
              </div>
            </th>
          </tr>
        </table>
          <div class="customer-container">
            <div class="customer-left">
                <p class="p-print">Customer Name: ${data.companyName}</p>
                <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                <p class="p-print">Customer Contact: ${data.contact}</p>
            </div>
            <div class="customer-right">
                <p class="p-print">Email: ${data.email}</p>
                <p class="p-print">Customer Type: ${data.customerType}</p>
                <p class="p-print">GST: ${data.gstNumber}</p>
              </div>
          </div>
          <table class="table-container">
              <thead>
              <tr>
                 <th class="th-print" colspan="6">AMC Details</th>
              </tr>
              <tr>
                <th class="th-print">AMC Doc No</th>
                <th class="th-print">AMC Name</th>
                <th class="th-print">AMC Category</th>
                <th class="th-print">No of service</th>
                <th class="th-print">Start Date</th>
                <th class="th-print">End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-print">${data.amcDocNo}</td>
                <td class="td-print">${data.amcName}</td>
                <td class="td-print">${data.amcCategory}</td>
                <td class="td-print">${data.numberOfService}</td>
                <td class="td-print">${extractDate(data.startingDate)}</td>
                <td class="td-print">${extractDate(data.endDate)}</td>
              </tr>
              <tr>
                <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
              </tr>
              <tr style={{height:'150px'}}>
                <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
              </tr>
              <tr>
                <td class="td-print" colSpan="6">
                <div id="signatureBlock">
                  <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                  <div class="rightText">
                    For Company's Name<br/>
                    <div class="line-print"></div><br/>
                    Authorised signatory
                  </div>
                </div>                
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
      `    
      const pageBreak = isNewPage ? 'page-break-before: always;' : '';

      return `
        <div style="${pageBreak}">
          ${element}
        </div>
      `;
      };
      const officeUseContent = generatePDFContent('For Office Use', false);
      const customerUseContent = generatePDFContent('For Customer Use', true);
    
      const finalElement = `
        ${officeUseContent}
        ${customerUseContent}
      `;
    
      html2pdf()
        .from(finalElement)
        .save('amc.pdf');


    }
    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
    }

      useEffect(() => {
        async function fetchData() {
            try {
                const response = await amcService.list(id,searchValue,filterValue);
                const data = response.data; 
                const newColumns = [
                    { Header: "amc Details", accessor: "amcDetails", width: "45%", align: "left" },
                    { Header: "current call", accessor: "currentCall", align: "center" },
                    { Header: "status", accessor: "status", align: "center" },
                    { Header: "GST", accessor: "gst", align: "left" },
                    { Header: "contact", accessor: "contact", align: "center" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];

                  const newRows = data.map((item) => ({
                    amcDetails: (
                      <Detail
                        image={team2} 
                        name={item.companyName}
                        email={item.amcName}
                        address={`${item.address}, ${item.area}, ${item.state}, ${item.pincode}` }
                        
                      />
                    ),
                    currentCall: (
                      <MDTypography
                          component="a"
                          href="#"
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                          id="contentToConvert"
                        >
                          {item.numberOfService-item.callRemaining}/{item.numberOfService}
                       </MDTypography>
                    ),
                    status: (
                      <MDBox ml={-1}>
                        <MDBadge 
                          badgeContent={item.renewStatus == 'active'?"Active":"InActive"} 
                          color={item.renewStatus == 'active'?"success":"error"} 
                          variant="gradient" size="sm" 
                          onClick={()=>{editTask(item)}}
                          style={{"cursor":"pointer"}}
                          />
                      </MDBox>
                    ),
                    gst: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        {item.gstNumber}
                      </MDTypography>
                    ),
                    contact: (
                        <MDTypography
                          component="a"
                          href="#"
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                           <a href={`tel:${item.contact}`}>   
                            {item.contact}  
                           </a>
                        </MDTypography>
                      ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  href={`/edit-customer/${item._id}`}
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{editTask(item._id)}}
                                >
                                    edit
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{deleteTask(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    delete
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{printamc(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    print
                                </span>
                              </>
                    ),
                  }));
          
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {

                console.log(res)
                // if (res.hasOwnProperty("message")) {
                //   setCredentialsError(res.message);
                // } else {
                //   setCredentialsError(res.errors[0].detail);
                // }
              }
        }
        fetchData()
      }, [searchValue,refresh])
     
     
  

  return {
    columns,
    rows,
    searchPara
  };
}
