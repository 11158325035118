/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import React, {useEffect,useState, useContext } from "react";
import { AuthContext } from "context";

import AuthService from 'services/auth-service'
// Overview page components
import Header from "layouts/profile/components/Header";
import executiveService from "services/executive-service";
import ProfileViewCard from "./components/ProfileViewCard";
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function Overview() {

  const { userData } = useContext(AuthContext);
  const userDetail = userData()
  const [user,setUser] = useState(null)
  const [userInfo,setUserInfo] = useState(null)

    //Snackbar
    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
  
    useEffect(() => {
      if (location.state && location.state.successMessage) {
          setAlertMessage(location.state.successMessage);
          setOpenAlert(true);
      }
  }, [location.state]);
  
  const handleClose = () => {
      setOpenAlert(false);
  };
  //End Snackbar

  useEffect(async() => {
    userDetail.decodedToken && setUser(userDetail.decodedToken)
    if(user){
      if(user.role == 'executive'){
        try{
          const data =  await executiveService.getByID(user.userId)
          data.data && setUserInfo(data.data)
        }catch(err){
          console.log(err)
        }
      }else{
        try{
          const data = await AuthService.getProfile(user.userId)
          setUserInfo(data.data)
        }catch(err){
          console.log(err)
        }
      }
    }     
  }, [userDetail])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header userInfo={userInfo}>
     { userInfo && <ProfileViewCard userInfo={userInfo}/>}
      </Header>
      {/* <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} sx={{ display: "flex" }}>
              <ProfileInfoCard
                title= {userInfo ? userInfo.companyName: ''}
                description=""
                info={{
                  fullName: `${userInfo ? userInfo.contactPerson: ''}`,
                  mobile: `${userInfo ? userInfo.contact: ''}`,
                  email: `${userInfo ? userInfo.email: ''}`,
                  alternateNumber: `${userInfo ? userInfo.alternateNumber: ''}`,
                  role: `${userInfo ? userInfo.role: ''}`,
                  address: `${userInfo ? userInfo.address: ''}`, 
                  area: `${userInfo ? userInfo.area: ''}`, 
                  city: `${userInfo ? userInfo.city: ''}`, 
                  state: `${userInfo ? userInfo.state: ''}`, 
                  country: `${userInfo ? userInfo.country: ''}`, 
                  pincode: `${userInfo ? userInfo.pincode: ''}`, 
                  googleMapUrl: `${userInfo ? userInfo.googleMapUrl: ''}`,
                }}

                action={{ route: `/edit-user/${userInfo ? userInfo._id: ''}`, tooltip: "Edit Profile" }}
                shadow={false}
              />
          </Grid>
        </MDBox>
      </Header> */}
      {/* <Footer /> */}
      <Snackbar 
              open={openAlert} 
              autoHideDuration={6000} 
              style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
              onClose={handleClose}>
                <Alert severity="success" onClose={handleClose}>
                    {alertMessage}
                </Alert>
          </Snackbar>
    </DashboardLayout>
  );
}

export default Overview;
