import { useContext, useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import { InputLabel } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TermsAndConditionsCard from "examples/TermsAndConditions";


function Register() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [credentialsErros, setCredentialsErrors] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const [inputs, setInputs] = useState({
    companyName: "",
    email: "",
    password: "",
    confirmPassword: "",
    contact: "",
  });

  const [errors, setErrors] = useState({
    companyNameError: false,
    emailError: false,
    passwordError: false,
    confirmPasswordError: false,
    contactError: false,
    error: false,
    errorText: "",
    agreeError: false
  });
   
  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.companyName.trim().length === 0) {
      setErrors({ ...errors, companyNameError: true });
      return;
    }

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 8) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.confirmPassword.trim() !== inputs.password.trim()) {
      setErrors({ ...errors, confirmPasswordError: true });
      return;
    }

    if (inputs.contact.trim().length < 10) {
      setErrors({ ...errors, contactError: true });
      return;
    }

    if (!termsAgreed) {
      setErrors({ ...errors, agreeError: true });
      return;
    }
    

    // here will be the post action to add a user to the db
    const newUser = { companyName: inputs.companyName, email: inputs.email, password: inputs.password, contact: inputs.contact, role: 'admin' };
  

    // try {
    //   const response = await AuthService.register(newUser);
    //     if(response.code == 1){
    //       const newUser = inputs.email.trim().match(mailFormat)?
    //                               { email: inputs.email, password: inputs.password }
    //                               :
    //                               { contact: inputs.email, password: inputs.password }
    //           const myData = {
    //             ...newUser
    //           };
            
    //           try {
    //             const response = await AuthService.login(myData);
    //             authContext.login(response.access_token, response.refresh_token);
    //             navigate('/dashboard')
    //           } catch (res) {
    //             if (res.hasOwnProperty("message")) {
    //               setCredentialsError(res.message);
    //             } else {
    //               setCredentialsError(res.errors[0].detail);
    //             }
    //           } 
    //               }
    //             // authContext.login(response.access_token, response.refresh_token);
    //             navigate('/dashboard')
    //             setInputs({
    //               companyName: "",
    //               email: "",
    //               password: "",
    //               agree: false,
    //             });

    //             setErrors({
    //               companyNameError: false,
    //               emailError: false,
    //               passwordError: false,
    //               agreeError: false,
    //               error: false,
    //               errorText: "",
    //             });
    // } catch (err) {
    //   console.log(err)
    //   setErrors({ ...errors, error: true, errorText: err.message });
    //   console.error(err);
    // }

    try {
      const response = await AuthService.register(newUser);
        if(response.status == 201){
          const newUser = inputs.email.trim().match(mailFormat)?
                                  { email: inputs.email, password: inputs.password }
                                  :
                                  { contact: inputs.email, password: inputs.password }
              const myData = {
                ...newUser
              };
            
              try {
                const response = await AuthService.login(myData);
                authContext.login(response.access_token, response.refresh_token);
                navigate('/dashboard')
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  setCredentialsErrors(res.message);
                } else {
                  setCredentialsErrors(res.errors[0].detail);
                }
              } 
        }else{
          setCredentialsErrors(response.message);
        }
                setErrors({
                  companyNameError: false,
                  emailError: false,
                  passwordError: false,
                  agreeError: false,
                  error: false,
                  errorText: "",
                });
    } catch (err) {
      console.log(err)
      setErrors({ ...errors, error: true, errorText: err.message });
      console.error(err);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleButtonClick = () => {
    // Navigate to the specified URL
    window.location.href = 'https://myofficejobs.com';
  };

  return (<>
   <CoverLayout image={bgImage}>
   <MDBox mt={2} mb={1} className='home-nav'>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleButtonClick}>
                Back to home
              </MDButton>
        </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Company Name"
                variant="standard"
                fullWidth
                name="companyName"
                value={inputs.companyName}
                onChange={changeHandler}
                error={errors.companyNameError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.companyNameError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The name can not be empty
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The email must be valid
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? 'text' : 'password'}
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        onMouseDown={togglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The password must be of at least 8 characters
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                variant="standard"
                fullWidth
                name="confirmPassword"
                value={inputs.confirmPassword}
                onChange={changeHandler}
                error={errors.confirmPasswordError}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPasswordVisibility}
                        onMouseDown={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The password must be of at least 8 characters
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="tel"
                label="Contact Number"
                variant="standard"
                fullWidth
                name="contact"
                value={inputs.contact}
                onChange={changeHandler}
                error={errors.contactError}
              />
              {errors.contactError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The contact number must be of 10 digit
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                name="agree"
                id="agree"
                checked={termsAgreed}
                onChange={(e) => setTermsAgreed(e.target.checked)}
              />
              <div style={{"display":"flex"}}>
                <InputLabel
                  variant="standard"
                  fontWeight="regular"
                  color="text"
                  sx={{ lineHeight: "1.5", cursor: "pointer" }}
                  htmlFor="agree"
                >
                  &nbsp;&nbsp;I agree to the&nbsp;
                </InputLabel>
            
              <MDTypography
                // component={Link}
                // to="/auth/login"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                onClick={() => setShowTermsAndConditions(true)}
              >
                Terms & Conditions
              </MDTypography>
              </div>
            </MDBox>
            {errors.agreeError && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                You must agree to the Terms and Conditions
              </MDTypography>
            )}
            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}
             {credentialsErros && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  {credentialsErros}
                </MDTypography>
              )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
    {showTermsAndConditions && <TermsAndConditionsCard onClose={() => setShowTermsAndConditions(false)} />}
  </>
  );
}

export default Register;
