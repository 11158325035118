import { Navigate } from "react-router-dom";

// const ProtectedRoute = ({ isAuthenticated, redirectPath = "/auth/login", children }) => {

//   if (!isAuthenticated) {
//     return <Navigate to={redirectPath} replace />;
//   }

//   return children;
// };

const ProtectedRoute = ({ role, allowedRoles, redirectPath = "/login", children,isExpired }) => {
  if (!role) {
    // If the user is not logged in, you can redirect to the login page or handle it as needed.
    return <Navigate to={redirectPath} replace/>;
  }

  if (!allowedRoles.includes(role)) {
    // If the user's role is not allowed, you can redirect them to a "Permission Denied" page or handle it as needed.
    return <Navigate to={redirectPath} replace/>;
  }
  if(isExpired){
    return <Navigate to='/expired' replace/>;
  }

  // If the user's role is allowed, render the specified route.
  return children;
};

export default ProtectedRoute;
