import HttpService from "./htttp.service";

class CallService {
   authEndpoint = process.env.API_URL;
//For Admin
  list = async (id,searchValue,filterValue,filterCallType) => {
    const listEndpoint = `/api/call/list-filter?filter=${filterValue}&parentId=${id}&calltype=${filterCallType}`;
    return await HttpService.get(listEndpoint, searchValue);
  };
  // list = async (id,searchQuery) => {
  //   const listEndpoint = `/api/call/list?parentId=${id}`;
  //   return await HttpService.get(listEndpoint, searchQuery);
  // };
  create = async (payload) => {
    const createTask = '/api/call/create';
    const data =JSON.stringify(payload)
    return await HttpService.post(createTask, data);
  };
  delete = async (payload) => {
    const loginEndpoint = `/api/call/delete?id=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };
  update = async (id,payload) => {
    const updateEndpoint = `/api/call/update?callid=${id}`;
    var data = JSON.stringify(payload);
    return await HttpService.put(updateEndpoint,data);
  };
  getByID = async (id) => {
    const getByIdEndpoint = `/api/call/get-by-id?id=${id}`;
    return await HttpService.get(getByIdEndpoint);
  };


 //For Executive
  calllist = async (parentId,id,filter) => {
   // const listEndpoint = `/api/call/list?parentId=${parentId}&filter=${searchQuery}`;
   const listEndpoint = `/api/call/list?filter=${filter}&parentId=${parentId}&userId=${id}`;
  
    return await HttpService.get(listEndpoint);
  };
  updateStatus = async (id,status,reason) => {
    const updateEndpoint = `/api/call/update-status?callid=${id}&status=${status}&reason=${reason}`;
    return await HttpService.put(updateEndpoint);
  };
}

export default new CallService();
