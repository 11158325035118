/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import CustomerService from "services/customer-service";
import { useNavigate } from "react-router-dom"
import jobSheetService from "services/jobSheet-service";
import callService from "services/call-service";


function formatDateToYYYYMMDD(dateString) {
  if(dateString){
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }else{
    return `No call attend date`
  }
 
}

// amc Name
// Customer Number
// doc No
// address

export default function data() { 
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
//   name={item.amcName}
//   companyName={item.companyName}
//   callDocumentNumber={item.callDocumentNumber}
//   email={item.callDocumentNumber}
//   address={`${item.address}, ${item.area}, ${item.state}, ${item.pincode}` }
    const Detail = ({ image, name,companyName,callDocumentNumber, email,address }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {/* <MDAvatar src={image} name={name} size="sm" /> */}
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{companyName}</MDTypography><br/>
            <MDTypography variant="caption">{callDocumentNumber}</MDTypography><br/>
            <MDTypography variant="caption">{email}</MDTypography><br/>
            <MDTypography variant="caption">{address}</MDTypography>
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)  
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('')
    const [filterCallType, setFilterCallType ] = useState('call')
    const navigate = useNavigate()

    const editTask = (id)=>{
            navigate(`/edit-call/${id}`)
    }

    const deleteTask =async(id)=>{
      try{
        const response = await callService.delete(id);
        setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }

    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
    }

    const filterStatus = (filter)=>{
      setFilterValue(filter)
    }

    const filterCall = (filter)=>{
       setFilterCallType(filter)
    }

      useEffect(() => {
        async function fetchData() {
            try {
                const response = await callService.list(id,searchValue,filterValue,filterCallType);
                const data = response.data; 
                console.log(data)
                const newColumns = [
                    { Header: "detail", accessor: "detail", width: "45%", align: "left" },
                    { Header: "executive", accessor: "executive", align: "left" },
                    { Header: "call Date", accessor: "callDate", align: "center" },
                    { Header: "contact", accessor: "contact", align: "center" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];
          
                  const newRows = data.map((item) => ({
                    detail: (
                      <Detail
                        image={team2} // You may need to update this part to dynamically select an image based on item data
                        name={item.amcName}
                        companyName={item.companyName}
                        callDocumentNumber={item.callDocumentNumber}
                        email={item.email}
                        address={`${item.address}, ${item.area}, ${item.state}, ${item.pincode}` }
                      />
                    ),
                    executive: (
                    <>
                       {item.executives.map((executive,index) => (
                          <MDTypography
                            key={executive.value} // Don't forget to add a unique key when using map
                            component="a"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >
                            {executive.label} 
                            {index < item.executives.length - 1 && ', '}
                          </MDTypography>
                        ))}
                    </>
                    ),
                    callDate: (
                      <>
                         <MDTypography
                            component="a"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >
                            {formatDateToYYYYMMDD(item.callAttendDate)}  
                          </MDTypography>
                      </>
                      ),
                    contact: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                         <a href={`tel:${item.RMN}`}>   
                            {item.RMN}  
                          </a>
                      </MDTypography>
                    ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  href={`/edit-customer/${item._id}`}
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{editTask(item._id)}}
                                >
                                    edit
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{deleteTask(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    delete
                                </span>
                              </>
                    ),
                  }));
          
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {
                console.log(res)
                // if (res.hasOwnProperty("message")) {
                //   setCredentialsError(res.message);
                // } else {
                //   setCredentialsError(res.errors[0].detail);
                // }
              }
        }
        fetchData()
      }, [searchValue,refresh,filterValue,filterCallType])
     
     
  

  return {
    columns,
    rows,
    searchPara,
    filterStatus,
    filterCall
  };
}
