import React from 'react'
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate, useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import callService from 'services/call-service';
import authService from "services/auth-service";
import { AuthContext } from "context";
import { useState, useEffect, useMemo, useContext } from "react";

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
}  
function formatDateToISOString(dateString) {
    const dateParts = dateString.split("-");
    if (dateParts.length !== 3) {
      return null; // Invalid date format
    }
  
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based (0-11)
    const day = parseInt(dateParts[2], 10);
  
    const date = new Date(year, month, day);
    if (isNaN(date)) {
      return null; // Invalid date
    }
  
    return date.toISOString();
  }
const ProfileForm = () => {
    const navigate = useNavigate()
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const role = userDetail.decodedToken?.role;
    const {id} = useParams()
    const [values,setValues] = useState({
        companyName: '',
        email: '',
        contact: '',
        alternateNumber: '',
        contactPerson: '',
        profileImage: '',
        address: '',
        area: '',
        state: '',
        country: '',
        pincode: '',
        googleMapUrl:'',
        gstNumber:'',
        subscription: {
            expiresAt: ''
        }
       })
       
    const [formErrors, setFormErrors] = useState({
            companyName:'',
            email: '',
            contact: '',
            contactPerson: '',
        });

    
    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        
        setValues({
            ...values,
            profileImage: file,
        });
    };
  
    useEffect(async() => {
        try{
            const list = await authService.getProfile(id)
            setValues({
                companyName: list.data.companyName,
                email: list.data.email,
                contact: list.data.contact,
                alternateNumber: list.data.alternateNumber,
                contactPerson: list.data.contactPerson,
                profileImage: list.data.profileImage,
                address: list.data.address,
                area: list.data.area,
                city: list.data.city,
                state: list.data.state,
                country: list.data.country,
                gstNumber: list.data.gstNumber,
                pincode: list.data.pincode,
                googleMapUrl: list.data.googleMapUrl,
                subscription: {
                    expiresAt: list.data.subscription.expiresAt
                }
            })
            
        }catch(err){
            console.log(err)
        }
        
    }, [])   

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            if(values.email.length == 0){
                newErrors.email='Invalid Email'
                valid = false
            }else{
                newErrors.email = ''
            }

            if(values.contact.length == 0){
                newErrors.contact='Invalid Contact'
                valid = false
            }else{
                newErrors.contact = ''
            }

            if(values.contactPerson.length == 0){
                newErrors.contactPerson='Invalid Contact Person'
                valid = false
            }else{
                newErrors.contactPerson = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   
    const handleSubmit=async()=>{
          if(handleValidation()) {
                // const response = await authService.updateProfile(id,values)
                // navigate('/profile', { state: { successMessage: "Profile updated successfully" } })
                    
                const formData = new FormData();
    
                formData.append('companyName', values.companyName);
                formData.append('email', values.email);
                formData.append('contactPerson', values.contactPerson);
                formData.append('contact', values.contact);
                formData.append('alternateNumber', values.alternateNumber);
                formData.append('gstNumber', values.gstNumber);
                formData.append('address', values.address);
                formData.append('city', values.city);
                formData.append('area', values.area);
                formData.append('state', values.state);
                formData.append('country', values.country);
                formData.append('pincode', values.pincode);
                formData.append('subscription', JSON.stringify(values.subscription));
                formData.append('googleMapUrl', values.googleMapUrl);
                if (values.profileImage) {
                    formData.append('profileImage', values.profileImage);
                }
                    
                try{
                    const response = await authService.updateProfile(id,formData)
                    navigate('/profile', { state: { successMessage: "Profile updated successfully" } })
                }catch(err){
                    setAlertMessage("Failed to update");
                    setOpenAlert(true);
                    console.log(err)
                }
          } else {
            console.log('Form validation failed');
          }
    }

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Company Name</label>
                    <input type="text" className={`form-control ${formErrors.companyName?`is-invalid`:``}`} id="inputAMCName" value={values.companyName}  placeholder="Company Name" onChange={e=>{setValues({...values,companyName:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Company Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>Email</label>
                    <input type="text" className={`form-control ${formErrors.email?`is-invalid`:``}`} id="inputAMCName" placeholder="Email"  value={values.email}  onChange={e=>{setValues({...values,email:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Email.
                     </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">Contact Person</label>
                    <input type="text"className={`form-control ${formErrors.contactPerson?`is-invalid`:``}`} id="inputAMCDocNo" placeholder="Contact Person" value={values.contactPerson} onChange={e=>{setValues({...values,contactPerson:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact Person.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact</label>
                    <input type="tel"className={`form-control ${formErrors.contact?`is-invalid`:``}`} id="validationServer05" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                    Please provide a Contact.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Alternate Number</label>
                    <input type="tel" className="form-control" id="validationServer05" value={values.alternateNumber} onChange={e=>{setValues({...values,alternateNumber:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">GST Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="GST Number" value={values.gstNumber} onChange={e=>{setValues({...values,gstNumber:e.target.value})}}/>
                </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control"  id="inputCity" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className={`form-control ${formErrors.state?`is-invalid`:``}`}  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control"  id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className={`form-control ${formErrors.country?`is-invalid`:``}`}  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid City.
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Profile Image</label>
                    <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile01"
                        onChange={handleImageUpload}
                    />
                     {/* {values.profileImage && <p style={{"fontSize":"1.5vh"}}>{values.profileImage}</p>} */}
                </div>
                {role == 'super admin' && <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Subscription End Date</label>
                    <input type="date" className="form-control"  id="inputCity" value={formatDateToYYYYMMDD(values.subscription.expiresAt)} onChange={e=>{setValues({...values,subscription:{expiresAt:formatDateToISOString(e.target.value)}})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Subscription Date.
                    </div>
                </div>}
            </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Update
          </MDButton>
         
    </div>
  )
}

export default ProfileForm

