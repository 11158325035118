/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import TodoService from "services/todo-service";



export default function data(flag) {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;

    const Title = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {/* <MDAvatar src={image} name={name} size="sm" /> */}
          <MDBox ml={2} lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="button" fontWeight="medium" textAlign="left">
              {name}
            </MDTypography>
            {/* <MDTypography variant="caption">{email}</MDTypography> */}
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('')

    const deleteTask =async(id)=>{
      try{
        const response = await TodoService.delete(id);
        setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }
    const editTask =async(task)=>{
      try{
        const response = await TodoService.update(task);
        setRefresh(!refresh)
      }catch(err){
        console.log(err)
      }
    }

    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
    }

      useEffect(() => {
        async function fetchData() {
            try {
                const response = await TodoService.list(id,searchValue);
                const data = response.data; 
               
                const newColumns = [
                    { Header: "title", accessor: "title", width: "45%", align: "left" },
                    // { Header: "", accessor: "designation", align: "left" },
                    { Header: "status", accessor: "status", align: "center" },
                    // { Header: "contact", accessor: "contact", align: "center" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];
          
                  const newRows = data.map((item) => ({
                    title: (
                      <Title
                        // image={team2} // You may need to update this part to dynamically select an image based on item data
                        name={item.title}
                        // email={item.email}
                      />
                    ),
                    status: (
                      <MDBox ml={-1}>
                        <MDBadge 
                          badgeContent={item.isDone?"Done":"Not Done"} 
                          color={item.isDone?"success":"error"} 
                          variant="gradient" size="sm" 
                          onClick={()=>{editTask(item)}}
                          style={{"cursor":"pointer"}}
                          />
                      </MDBox>
                    ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{deleteTask(item._id)}}
                                >
                                    delete
                                </span>

                                <span 
                                  className="material-symbols-outlined"
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{editTask(item)}}
                                >
                                   {item.isDone?'check_box':' check_box_outline_blank'}
                                </span>
                              </>
                    ),
                  }));
          
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  setCredentialsError(res.message);
                } else {
                  setCredentialsError(res.errors[0].detail);
                }
              }
        }
        fetchData()
      }, [flag, refresh,searchValue])
     
     
  

  return {
    columns,
    rows,
    searchPara
  };
}
