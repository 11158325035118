import React from 'react';

const SelectedOption = ({ data, innerProps, removeProps }) => (
  <div
    className="selected-option" // Add the CSS class here
    {...innerProps}
  >
    {data.label}
    <span className="remove" {...removeProps}>×</span>
  </div>
);

export default SelectedOption;
