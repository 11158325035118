import HttpService from "./htttp.service";

class AMCService {
   authEndpoint = process.env.API_URL;

  list = async (id,searchQuery) => {
    const listEndpoint = `/api/amc-customer/list-customer-admin?parentId=${id}&page=1&limit=3`;
    return await HttpService.get(listEndpoint, searchQuery);
  };

  create = async (payload) => {
    const createTask = '/api/amc-customer/create-customer';
        console
    const data =JSON.stringify(payload)

    return await HttpService.post(createTask, data);
  };

  delete = async (payload) => {
    const loginEndpoint = `/api/amc-customer/delete-customer?customerId=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };
  update = async (id,payload) => {
    const updateEndpoint = `/api/amc-customer/update-customer?customerId=${id}`;

    var data = JSON.stringify(payload);

    return await HttpService.put(updateEndpoint,data);
  };

  getByID = async (id) => {
    const getByIdEndpoint = `/api/amc-customer/customer/${id}`;
    return await HttpService.get(getByIdEndpoint);
  };
}

export default new AMCService();
