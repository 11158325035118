/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React helper functions
import gradientChartLine from "assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "examples/Charts/LineCharts/GradientLineChart/configs";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function GradientLineChart({ icon, title, description, height, chart,onStateChange }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options } = chartData;
  const [selectedFilter, setSelectedFilter] = useState("weekly");

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value); 
    onStateChange(event.target.value)
  };

  const renderFilterDropdown = (
    <FormControl style={{'marginLeft':'10rem'}}>
      <InputLabel id="filter-dropdown-label">Filter</InputLabel>
      <Select
        labelId="filter-dropdown-label"
        id="filter-dropdown"
        value={selectedFilter}
        label="Filter"
        onChange={handleFilterChange}
      >
        <MenuItem value="weekly">Weekly</MenuItem>
        <MenuItem value="1 month">1 Month</MenuItem>
        <MenuItem value="3 months">3 Months</MenuItem>
        <MenuItem value="6 months">6 Months</MenuItem>
      </Select>
    </FormControl>
  );

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0,
          pointRadius: 0,
          borderWidth: 4,
          borderColor: colors[dataset.color]
            ? colors[dataset.color || "dark"].main
            : colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
          ),
        }))
      : [];

    setChartData(configs(chart.labels || [], chartDatasets));
  }, [chart]);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
           {chart.datasets &&
            chart.datasets.map((dataset, index) => (
              <MDBox display="flex" alignItems="center" mb={1} key={index}>
                <span
                  style={{
                    width: "10px", // Adjust the width of the color lines as needed
                    height: "10px", // Adjust the height of the color lines as needed
                    backgroundColor: colors[dataset.color || "dark"].main,
                    margin: "8px", // Add spacing between the color lines and labels
                  }}
                />
                <MDTypography variant="caption">
                  {dataset.label}
                </MDTypography>
              </MDBox>
            ))}
              {renderFilterDropdown}
         
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox ref={chartRef} sx={{ height }}>
            <Line data={data} options={options} />
          </MDBox>
        ),
        [chartData, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradientLineChart;
