/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Data
import executiveCallListTableData from "layouts/executiveCallList/data/executiveCallListTableData";
import { useNavigate } from "react-router-dom"
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import callService from "services/call-service";
import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import PopUp from "components/PopUp";
import jobSheetService from "services/jobSheet-service";
import Icon from "@mui/material/Icon";

function ExecutiveJobSheet() {
  // const { columns, rows, searchPara,filterStatus } = executiveCallListTableData();
  const navigate = useNavigate()
  const [refresh,setRefresh]= useState(false)
  const [data, setData] = useState([])
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const role = userDetail.decodedToken?.role;
  const parentId = userDetail.decodedToken?.parentId;
  const [filter,setFilter] = useState('pending')
  const [popUp, setPopUp] = useState(false)
  const [message, setMessage] = useState('')
  const [callId, setCallId] = useState('')
  const [reason, setReason] = useState('')
  const [reasonError, setReasonError] = useState(false)
  const [pendingPopUp, setPendingPopUp] = useState(false)
  const [notes, setNotes] = useState('')
  const cancelPopUp =()=>{
    setPendingPopUp(false)
    setPopUp(false)
    setMessage('')
  }

  const statusPending = async(id)=>{
    setCallId(id)
    setMessage('Please provide the reason.')
    setPendingPopUp(true)
  }

  const pending = async()=>{
    try{
      if(reason.length != 0){
        const data = await jobSheetService.updateStatus(callId,'reassigned',reason)
        setPendingPopUp(false)
        setMessage('')
        setRefresh(!refresh)
        setReasonError(false)
        setReason('')
      }else{
        setReasonError(true)
      }
      
    }catch(err){
      console.log(err)
    }
  }
  const completed = async()=>{
    try{
        const data = await jobSheetService.updateStatus(callId,'completed',notes)
        setPopUp(false)
        setMessage('')
        setRefresh(!refresh)
    }catch(err){
       console.log(err)
    }
  }

const statusCompleted =async(id)=>{
  setCallId(id)
  setMessage('Are you sure you had completed it?')
  setPopUp(true)
}

useEffect(async() => {
    const response = await jobSheetService.getlist(parentId,id,filter);
    console.log('response',response)
    setData(response.data)
}, [filter,refresh])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Executive Job List
                  {/* <MDInput 
                       label={<span style={{ color: 'white' }}>Search Here</span>} 
                      size="small"  
                      style={{"margin":"0 1vw 0 44vw"}} 
                      onChange={(e)=>searchList(e.target.value)}
                    />
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        style={{color:"white"}}
                      >
                        <i className="fa fa-filter"></i>
                      </a>

                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item"  onClick={() => setFilter("all")}>All</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contact")}>Contact</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contactPerson")}>Contact Person</a>
                        <a className="dropdown-item"  onClick={() => setFilter("name")}>Name</a>
                        <a className="dropdown-item"  onClick={() => setFilter("email")}>Email</a>
                      </div> */}
              
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={createCall}> 
                        Create
                  </MDButton> */}
                  
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={() =>filterByStatus('all')}> 
                        All Jobs
                  </MDButton> */}
                  {/* <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={() =>filterByStatus('completed')}> 
                        Completed Jobs
                  </MDButton> */}
                   {/* <MDTypography variant="h6" color="white">
                      <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 4vw"}} 
                              onClick={() =>setFilter('pending')}> 
                            Pending Jobs
                      </MDButton>
                      <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin":"0 0 0 2vw"}} 
                              onClick={() =>setFilter('today')}> 
                            Today's Job
                      </MDButton>
                    </MDTypography>     */}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  data.map((item, index) => (
                    <Card>
                    <MDBox p={2} mx={3} display="flex" justifyContent="center">
                          <MDBox
                            display="grid"
                            justifyContent="center"
                            alignItems="center"
                            bgColor='info'
                            color="blue"
                            width="16rem"
                            height="16rem"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                          >
                            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} style={{textAlign:"left"}}>
                                <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
                                  {item.companyName}
                                </MDTypography>
                                {item.address.length > 0 && <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" >
                                  <Icon fontSize="medium">
                                    location_on
                                  </Icon> 
                                   {`${item.address}, ${item.area},${item.city}`}<br/>
                                   {`${item.state}, ${item.pincode}` }
                                </MDTypography>
                                }
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                                  <a href={`tel:${item.contact}`}> <span className="material-symbols-outlined" style={{color:"white"}}>call</span> </a>
                                  {item.contact}
                                </MDTypography>
                                {item.notes.length > 0 && <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
                                  <Icon fontSize="medium" marginBottom='1vh'>
                                    note_alt
                                  </Icon> 
                                  {item.notes}
                                </MDTypography>}
                            </MDBox>
                                <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                                    <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              style={{margin: "1rem"}}
                                              onClick={()=>{statusPending(item._id)}}
                                              > 
                                            Pending
                                        </MDButton>
                                        <MDButton 
                                              size="small" 
                                              variant="contained" 
                                              onClick={()=>{statusCompleted(item._id)}}
                                              > 
                                            Completed
                                      </MDButton>
                                </MDTypography>
                                  
                          </MDBox>
                    </MDBox>
                </Card>
                  ))
                }
            
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
         {popUp &&
          <div className='overlay'> <Card 
                                        style={{
                                        position: 'fixed',
                                        zIndex: 10000,
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        '@media (min-width: 768px)': {
                                          transform: 'translate(-50%, -50%)',
                                        },
                                        '@media (min-width: 992px)': {
                                          transform: 'translate(-10%, -50%)',
                                        },
                                      }}
                                        >
                  <MDBox p={2} mx={3} display="grid" justifyContent="center">
                            {/* {message} */}
                            <label htmlFor="inputReason" required>Are you sure you had completed it?</label>
                              <input type="text" 
                                  className={`form-control`}
                                  id="inputAMCName"
                                  value={notes}  
                                  placeholder="Notes" 
                                  onChange={e=>{setNotes(e.target.value)}} 
                                />
                              <div id="validationServer03Feedback" className="invalid-feedback">
                                Please provide the reason
                              </div>
                          <MDTypography variant="h6" color="white">
                          <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={completed}
                                    > 
                                Yes
                            </MDButton>
                            <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={cancelPopUp}
                                    > 
                                  No
                            </MDButton>
                            </MDTypography>
                  </MDBox>
          </Card></div>}
          {pendingPopUp && 
             <div className='overlay'><Card 
                      style={{
                        position: 'fixed',
                        zIndex: 10000,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        '@media (min-width: 768px)': {
                          transform: 'translate(-50%, -50%)',
                        },
                        '@media (min-width: 992px)': {
                          transform: 'translate(-10%, -50%)',
                        },
                      }}
                      >
              <MDBox p={2} mx={3} display="grid" justifyContent="center">
                
                 {/* <div className="form-group col-md-4"> */}
                    <label htmlFor="inputReason" required>Please provide the reason</label>
                    <input type="text" 
                        className={`form-control ${reasonError?`is-invalid`:``}`}
                        id="inputAMCName"
                        value={reason}  
                        placeholder="" 
                        onChange={e=>{setReason(e.target.value)}} 
                      />
                    <div id="validationServer03Feedback" className="invalid-feedback">
                       Please provide the reason
                    </div>
                {/* </div> */}
                <MDTypography variant="h6" color="white">
                          <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={pending}
                                    > 
                                Done
                            </MDButton>
                            <MDButton 
                                    size="small" 
                                    variant="contained" 
                                    style={{"margin":"0 0 0 2vw"}} 
                                    color='info'
                                    onClick={cancelPopUp}
                                    > 
                                  Cancel
                            </MDButton>
                            </MDTypography>
              </MDBox>
            </Card>
            </div> }
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ExecutiveJobSheet;








// /**
// =========================================================
// * Material Dashboard 2 React - v2.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// // Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

// // Data
// import executiveCallListTableData from "layouts/executiveCallList/data/executiveCallListTableData";
// import { useNavigate } from "react-router-dom"
// import { useState, useEffect } from "react"
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import callService from "services/call-service";
// import jobSheetService from "services/jobSheet-service";

// function ExecutiveJobSheet() {
//   const { columns, rows, searchPara,filterStatus } = executiveCallListTableData();
//   const [filter,setFilter] = useState('all')
//   const navigate = useNavigate()
//   const [refresh,setRefresh]= useState(false)
//   const [data, setData] = useState([])

//   const statusUnassign = async(id)=>{
//     try{
//         const payload = {
//           status: 'unassigned'
//         }
//         const data = await jobSheetService.updateStatus(id,payload)
//         setRefresh(!refresh)
//     }catch(err){
//        console.log(err)
//     }
// }

// const statusCompleted =async(id)=>{
//     try{
//           const payload = {
//             status: 'completed'
//           }
//         const data = await jobSheetService.updateStatus(id,payload);
//         setRefresh(!refresh)

//     }catch(err){
//         console.log(err)
//     }
// }

// useEffect(async() => {
//     const response = await jobSheetService.list('pending')
//     setData(response.data)
//     console.log(data)
// }, [refresh])


//   const createCall =()=>{ 
//     navigate('/create-call')
//   }

//   const searchList = async(searchValue)=>{
//     searchPara(searchValue,filter)
//   }
//   const filterByStatus = async(status)=>{
//     const response = await jobSheetService.calllist(status);
//     setData(response.data)
//   }

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={6} pb={3}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <Card>
//               <MDBox
//                 mx={2}
//                 mt={-3}
//                 py={3}
//                 px={2}
//                 variant="gradient"
//                 bgColor="info"
//                 borderRadius="lg"
//                 coloredShadow="info"
//               >
//                 <MDTypography variant="h6" color="white">
//                   Executive Jobsheet
//                   {/* <MDInput 
//                        label={<span style={{ color: 'white' }}>Search Here</span>} 
//                       size="small"  
//                       style={{"margin":"0 1vw 0 44vw"}} 
//                       onChange={(e)=>searchList(e.target.value)}
//                     />
//                       <a
//                         href="#"
//                         role="button"
//                         id="dropdownMenuLink"
//                         data-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{color:"white"}}
//                       >
//                         <i className="fa fa-filter"></i>
//                       </a>

//                       <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
//                         <a className="dropdown-item"  onClick={() => setFilter("all")}>All</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("contact")}>Contact</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("contactPerson")}>Contact Person</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("name")}>Name</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("email")}>Email</a>
//                       </div> */}
              
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={createCall}> 
//                         Create
//                   </MDButton> */}
                  
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={() =>filterByStatus('all')}> 
//                         All Jobs
//                   </MDButton> */}
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={() =>filterByStatus('completed')}> 
//                         Completed Jobs
//                   </MDButton> */}
//                   <MDTypography variant="h6" color="white">
//                     <MDButton 
//                             size="small" 
//                             variant="contained" 
//                             style={{"margin":"0 0 0 4vw"}} 
//                             onClick={() =>filterByStatus('pending')}> 
//                           Pending Jobs
//                     </MDButton>
//                     <MDButton 
//                             size="small" 
//                             variant="contained" 
//                             style={{"margin":"0 0 0 2vw"}} 
//                             onClick={() =>filterByStatus('today')}> 
//                           Today's Job
//                     </MDButton>
//                   </MDTypography>                    
//                 </MDTypography>
//               </MDBox>
//               <MDBox pt={3}>
//                 {
//                   data.map((item, index) => (
//                     <Card>
//                     <MDBox p={2} mx={3} display="flex" justifyContent="center">
//                           <MDBox
//                             display="grid"
//                             justifyContent="center"
//                             alignItems="center"
//                             bgColor='info'
//                             color="blue"
//                             width="16rem"
//                             height="16rem"
//                             shadow="md"
//                             borderRadius="lg"
//                             variant="gradient"
//                           >
//                             <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
//                                 <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                                   {item.companyName}
//                                 </MDTypography>
//                                 <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" >
//                                 {`${item.address}, ${item.area},${item.city}, ${item.state}, ${item.pincode}` }
//                                 </MDTypography><br/>
//                                 <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
//                                   {item.notes}
//                                 </MDTypography>
//                             </MDBox>
//                                 <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
//                                     <MDButton 
//                                               size="small" 
//                                               variant="contained" 
//                                               style={{margin: "1rem"}}
//                                               onClick={()=>{statusUnassign(item._id)}}
//                                               > 
//                                             Pending
//                                         </MDButton>
//                                         <MDButton 
//                                               size="small" 
//                                               variant="contained" 
//                                               onClick={()=>{statusCompleted(item._id)}}
//                                               > 
//                                             Completed
//                                       </MDButton>
//                                 </MDTypography>
                                  
//                           </MDBox>
//                     </MDBox>
//                 </Card>
//                   ))
//                 }
               
//               </MDBox>
//             </Card>
//           </Grid>
//         </Grid>
//       </MDBox>
//       {/* <Footer /> */}
//     </DashboardLayout>
//   );
// }

// export default ExecutiveJobSheet;
