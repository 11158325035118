import HttpService from "./htttp.service";

class ExecutiveService {
   authEndpoint = process.env.API_URL;
  
  //For Admin
  list = async (id,searchValue,filterValue) => {
    const listOfExecutive = `/api/executive/list-executive-admin?adminId=${id}&page=1&limit=10&searchQuery=${searchValue}&filter=${filterValue}`
   return await HttpService.get(listOfExecutive, searchValue);
  };
  alllist = async (id,searchValue,filterValue) => {
    const listOfExecutive = `/api/executive/all-executive?page=1&limit=10&searchQuery=${searchValue}&filter=${filterValue}`
   return await HttpService.get(listOfExecutive, searchValue);
  };
  delete = async (payload) => {
    const DeleteEndPoint = `/api/executive/delete-executive?userId=${payload}`;
    return await HttpService.delete(DeleteEndPoint);
  };
  search = async (filter,searchValue) => {
    const searchEndPoint = `/api/executive/search-executive?parentid=6513c529ef0a31371f20ada6&filter=${filter}&query=${searchValue}`;
    return await HttpService.get(searchEndPoint,searchValue);
  };
  register = async (payload) => {
    const registerEndpoint = '/api/executive/create-executive';
    const data =JSON.stringify(payload)
    return await HttpService.post(registerEndpoint, payload);
  };
  getByID = async (id) => {
    const registerEndpoint = `/api/executive/get-executive-by-id?id=${id}`;
    return await HttpService.get(registerEndpoint);
  };
  update = async (id,value) => {
    const registerEndpoint = `/api/executive/update-executive?userId=${id}`;
    const data =JSON.stringify(value)
    return await HttpService.put(registerEndpoint,value);
  };
  dashboard = async (id,adminId) => {
    const registerEndpoint = `/api/executive/executive-dashboard?userId=${id}&adminId=${adminId}`;
    return await HttpService.get(registerEndpoint);
  };


  
//For Executive

  
  
//   logout = async () => {
//     const logoutEndpoint = 'api/auth/logout';
//     return await HttpService.post(logoutEndpoint);
//   };

//   forgotPassword = async (payload) => {
//     const forgotPassword = 'password-forgot';
//     return await HttpService.post(forgotPassword, payload);
//   }

//   resetPassword = async (credentials) => {
//     const resetPassword = 'password-reset';
//     return await HttpService.post(resetPassword, credentials);
//   }

//   getProfile = async() => {
//     const getProfile = 'me';
//     return await HttpService.get(getProfile);
//   }

//   updateProfile = async (newInfo) => {
//     const updateProfile = "me";
//     return await HttpService.patch(updateProfile, newInfo);
//   }
}

export default new ExecutiveService();
