import { useContext, useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Typeahead } from "react-bootstrap-typeahead";
// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// Images
import adminImage from "assets/images/undraw_business_decisions_re_84ag.svg";
import executiveImage from "assets/images/undraw_profile_details_re_ch9r.svg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom"
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, TextField } from '@mui/material';

function Login() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  const [user, setUser] = useState({});
  const [credentialsErros, setCredentialsError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [isAdminLogin,setIsAdminLogin] = useState(false)
  const [logger,setLogger] = useState('Executive')
  const [bgcolor,setBgcolor] = useState('info')
  const [showPassword, setShowPassword] = useState(false);
  const [search,setSearch] = useState('')
  const [companyName, setCompanyName] = useState([])
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    companyName:""
  });
    
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
    companyName: false
  });
    console.log("inputs",inputs)
  useEffect(() => {

    const token = localStorage.getItem("token");

    if (token) {
      // You can also decode the token to check its validity here if needed
      // If the token is present, the user is authenticated, so navigate to the dashboard
      navigate('/dashboard');
    }
   
      const savedEmail = localStorage.getItem("rememberedEmail");
      const savedPassword = localStorage.getItem("rememberedPassword");
      const savedCompanyName = localStorage.getItem("rememberedCompanyName");
      
      if (savedEmail && savedPassword) {
        setInputs({
          ...inputs,
          email: savedEmail,
          password: savedPassword,
          companyName: savedCompanyName
        });
        setRememberMe(true);
      }
     
  }, []);

  useEffect(async() => {
 
    try{
      const response = await AuthService.allAdmin('')
      setCompanyName(response.data)
    }catch(err){
      console.log(err)
    }
  }, [search])
  
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSetRememberMe = () => {
    if (rememberMe) {
      // If "Remember me" is unchecked, remove saved credentials
      localStorage.removeItem("rememberedEmail");
      localStorage.removeItem("rememberedPassword");
      localStorage.removeItem("rememberedCompanyName");
    } else {
      // If "Remember me" is checked, save credentials in cookies
      localStorage.setItem("rememberedEmail", inputs.email);
      localStorage.setItem("rememberedPassword", inputs.password);
      localStorage.setItem("rememberedCompanyName", inputs.companyName);
    }
    setRememberMe(!rememberMe);
  };

  // const changeHandler = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (event, value) => {
    const selectedCompany = value || ''; // If the value is null/undefined, set it to an empty string
    setInputs((prevInputs) => ({
      ...prevInputs,
      companyName: selectedCompany,
    }));
  };

  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    if ((inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat))&&(inputs.email.trim().length !== 10)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    // if (isAdminLogin && inputs.companyName.trim().length < 6) {
    //   setErrors({ ...errors, companyNameError: true });
    //   return;
    // }

    const newUser = isAdminLogin?
                                inputs.email.trim().match(mailFormat)?
                                                                      { email: inputs.email, password: inputs.password, companyName: inputs.companyName }
                                                                      :
                                                                      { contact: inputs.email, password: inputs.password, companyName: inputs.companyName }
                                :
                                inputs.email.trim().match(mailFormat)?
                                                                      { email: inputs.email, password: inputs.password }
                                                                      :
                                                                      { contact: inputs.email, password: inputs.password }
    const myData = {
      ...newUser
    };
   
    try {
      const response = isAdminLogin ? await AuthService.executivelogin(myData):await AuthService.login(myData);
        
      if(response.status == 200){
        authContext.login(response.access_token, response.refresh_token);
        navigate('/dashboard')
      }else {
        setCredentialsError(response.message);
      }
     
    } catch (res) {
    
      if (res.hasOwnProperty("message")) {
        setCredentialsError(res.message);
      } else {
        setCredentialsError(res.errors[0].detail);
      }
    }

    return () => {
      setInputs({
        email: "",
        password: "",
        companyName: ""
      });

      setErrors({
        emailError: false,
        passwordError: false,
        companyName: false
      });
    };
  };

  const changeSignIn = () => {
    setIsAdminLogin(!isAdminLogin)
    isAdminLogin ? setLogger('Executive') : setLogger('Company')
    isAdminLogin ?  setBgcolor('info'): setBgcolor('secondary') 
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleButtonClick = () => {
    // Navigate to the specified URL
    window.location.href = 'https://myofficejobs.com';
  };

  return (<>
    <BasicLayoutLanding image={isAdminLogin?executiveImage:adminImage}>
        <MDBox mt={2} mb={1} className='home-nav'>
              <MDButton variant="gradient" color={bgcolor} fullWidth onClick={handleButtonClick}>
                Back to home
              </MDButton>
        </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={bgcolor}
          borderRadius="lg"
          coloredShadow={bgcolor}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {logger == 'Executive'? '':'Executive'} Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Email/Number"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            {isAdminLogin && (
              <MDBox mb={2}>
                <Autocomplete
                  options={companyName}
                  onChange={handleAutocompleteChange} 
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Name"
                      name="companyName"
                      value={inputs.companyName}
                      //onChange={changeHandler} 
                      style={{ height: '6vh' }}
                    />
                  )}
                />
              </MDBox>
            )}
            {/* {isAdminLogin && (
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="companyNameLabel">Company Name</InputLabel>
                    <Select
                      labelId="companyNameLabel"
                      id="companyName"
                      //value={inputs.companyName}
                      label="Company Name"
                      name="companyName"
                      onChange={changeHandler}
                      style={{height: '6vh', textAlign: 'left'}}
                    >
                      {companyName.map((company, index) => (
                        <MenuItem key={index} value={company}>
                          {company}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              )} */}
          {/* {isAdminLogin && <MDBox mb={2}>
            <MDInput
                type="text"
                label="Company Name"
                fullWidth
                value={inputs.companyName}
                name="companyName"
                onChange={changeHandler}
                error={errors.companyNameError}
              />
            </MDBox>} */}
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? 'text' : 'password'}
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        onMouseDown={togglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color={bgcolor} fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}
             <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color={bgcolor} fullWidth onClick={changeSignIn}>
               {logger} sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot your password? Reset it{" "}
                <MDTypography
                  component={Link}
                  to={`/forgot-password?isExecutive=${isAdminLogin}`}
                  variant="button"
                  color={bgcolor}
                  fontWeight="medium"
                  textGradient
                >
                  here
                </MDTypography>
              </MDTypography>
            </MDBox>
           {!isAdminLogin && <MDBox mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                {!isAdminLogin && <MDTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color={bgcolor}
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>}
              </MDTypography>
            </MDBox>}
          </MDBox>
        </MDBox>
      </Card>
   </BasicLayoutLanding>
   </>
  );
}

export default Login;
