/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function DefaultInfoCard({ companyName, Address, RMN, callDetailsNotes}) {
  return (
    <Card>
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor='info'
          color="blue"
          width="16rem"
          height="16rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
              <MDTypography variant="h2" fontWeight="regular" textTransform="capitalize" color="light">
                {companyName}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
                {Address}
              </MDTypography>
              <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                {RMN}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
                {callDetailsNotes}
              </MDTypography>
           </MDBox>
              <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
                  <MDButton 
                            size="small" 
                            variant="contained" 
                            > 
                          Pending
                      </MDButton>
                      <MDButton 
                            size="small" 
                            variant="contained" 
                            > 
                          Completed
                    </MDButton>
              </MDTypography>
                
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;
