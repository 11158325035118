/**
=========================================================
* Material Dashboard 2 React - v2.1.0
======================================================= ==

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Data 
import callTableData from "layouts/call/data/callTableData";
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const tabStyles = {
  service: {
    backgroundColor: '#2788f5',
    color: 'white',
    border: '2px solid #2788f5',
    margin: '0vw 5.32vw 2vh 10.66vw',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  amc: {
    backgroundColor: '#2788f5',
    color: 'white',
    border: '2px solid #2788f5',
    margin: '0 5.32vw 2vh 10.66vw',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  all: {
    backgroundColor: '#2788f5',
    color: 'white',
    border: '2px solid #2788f5',
    margin: '0 1.44vw 0 7.3vw',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  completed: {
    backgroundColor: '#24b351',
    color: 'white',
    border: '2px solid #24b351',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  pending: {
    backgroundColor: '#313136',
    color: 'white',
    border: '2px solid #313136',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  reassigned: {
    backgroundColor: '#f60062',
    color: 'white',
    border: '2px solid #f60062',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  unassigned: {
    backgroundColor: '#ff7d00',
    color: 'white',
    border: '2px solid #ff7d00',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
};
const tabInactiveStyles = {
  service: {
    color: '#2788f5',
    backgroundColor: 'white',
    border: '2px solid #2788f5',
    margin: '0 5.32vw 2vh 10.66vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  amc: {
    color: '#2788f5',
    backgroundColor: 'white',
    border: '2px solid #2788f5',
    margin: '0 5.32vw 2vh 10.66vw',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  all: {
    color: '#2788f5',
    backgroundColor: 'white',
    border: '2px solid #2788f5',
    margin: '0 1.44vw 0 7.3vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  completed: {
    color: '#24b351',
    backgroundColor: 'white',
    border: '2px solid #24b351',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  pending: {
    color: '#313136',
    backgroundColor: 'white',
    border: '2px solid #313136',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  reassigned: {
    color: '#f60062',
    backgroundColor: 'white',
    border: '2px solid #f60062',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
  unassigned: {
    backgroundColor: 'white',
    color: '#ff7d00',
    border: '2px solid #ff7d00',
    margin: '0 1.44vw',
     textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5vh'
  },
};

function Call() {
  const { columns, rows, searchPara,filterStatus,filterCall } = callTableData();
  const [filter,setFilter] = useState('all')
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState('service call');
  const [activeSubTab, setActiveSubTab] = useState('all');

  //Snackbar
  const location = useLocation();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (location.state && location.state.successMessage) {
        setAlertMessage(location.state.successMessage);
        setOpenAlert(true);
    }
}, [location.state]);

const handleClose = () => {
    setOpenAlert(false);
};
//End Snackbar

  const handleMainTabClick = (tab) => {
    filterCall(tab)
    setActiveMainTab(tab);
    // Reset the sub-tab when switching main tabs
    setActiveSubTab('all');
  };

  const handleSubTabClick = (tab) => {
    filterStatus(tab)
    setActiveSubTab(tab);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = ({ value, index, children }) => {
    if(value == 0){
      filterStatus('all')
    }else if(value == 1){
      filterStatus('completed')
    }else if(value == 2){
      filterStatus('pending')
    }else if(value == 3){
      filterStatus('unassigned')
    }else if(value == 4){
      filterStatus('reassigned')
    }
    
    return value === index && children;
   
  };



  const createCall =()=>{ 
    navigate('/create-call')
  }

  const searchList = async(searchValue)=>{
    searchPara(searchValue,filter)
  }
  const filterByStatus = async(status)=>{
    filterStatus(status)
  }
  const filterByCall = async(call)=>{
    filterCall(call)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Call
                  <MDInput 
                       label={<span style={{ color: 'white' }}>Search Here</span>} 
                      size="small"  
                      style={{"margin":"0 1vw 0 40vw"}} 
                      onChange={(e)=>searchList(e.target.value)}
                    />
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        style={{color:"white"}}
                      >
                        <i className="fa fa-filter"></i>
                      </a>

                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item"  onClick={() => setFilter("all")}>All</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contact")}>Contact</a>
                        <a className="dropdown-item"  onClick={() => setFilter("contactPerson")}>Contact Person</a>
                        <a className="dropdown-item"  onClick={() => setFilter("name")}>Name</a>
                        <a className="dropdown-item"  onClick={() => setFilter("email")}>Email</a>
                      </div>
              
                  <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={createCall}> 
                       + Create Call
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}
               sx={{
                '@media (max-width: 768px)': {
                  margin: '0',
                  textAlign: 'center',
                  fontSize: '0.6rem', 
                },
              }}
              >
                <div>
                  <ul className="nav nav-pills custom-tabs">
                    <li className="">
                      <a
                        className="nav-link"
                        style={activeMainTab === 'service call' ? tabStyles.service : tabInactiveStyles.service}
                        onClick={() => handleMainTabClick('service call')}
                      >
                        Normal Calls
                      </a>
                    </li>
                    <li className="">
                      <a
                        className="nav-link"
                        style={activeMainTab === 'amc call' ? tabStyles.amc : tabInactiveStyles.amc}
                        onClick={() => handleMainTabClick('amc call')}
                      >
                        AMC Calls
                      </a>
                    </li>
                  </ul>

                  {activeMainTab === 'service call' && <ul className="nav nav-pills custom-sub-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={activeSubTab === 'all' ? tabStyles.all : tabInactiveStyles.all}
                        onClick={() => handleSubTabClick('all')}
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={activeSubTab === 'completed' ? tabStyles.completed : tabInactiveStyles.completed} 
                        onClick={() => handleSubTabClick('completed')}
                      >
                        Completed
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={activeSubTab === 'pending' ? tabStyles.pending : tabInactiveStyles.pending} 
                        onClick={() => handleSubTabClick('pending')}
                      >
                        Pending
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={activeSubTab === 'reassigned' ? tabStyles.reassigned : tabInactiveStyles.reassigned}
                        onClick={() => handleSubTabClick('reassigned')}
                      >
                        Reassigned
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={activeSubTab === 'unassigned' ? tabStyles.unassigned : tabInactiveStyles.unassigned}
                        onClick={() => handleSubTabClick('unassigned')}
                      >
                        Unassigned
                      </a>
                    </li>
                  </ul>}

                  <div className="tab-content">
                    {activeMainTab === 'service call' && (
                      <div>
                        <DataTable
                                table={{ columns, rows }}
                                isSorted={false}
                                entriesPerPage={true}
                                showTotalEntries={true}
                                noEndBorder
                              />
                      </div>
                    )}
                    {activeMainTab === 'amc call' && (
                      <div>
                        <DataTable
                                table={{ columns, rows }}
                                isSorted={false}
                                entriesPerPage={true}
                                showTotalEntries={true}
                                noEndBorder
                              />
                      </div>
                    )}
                  </div>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Snackbar 
              open={openAlert} 
              autoHideDuration={6000} 
              style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
              onClose={handleClose}>
                <Alert severity="success" onClose={handleClose}>
                    {alertMessage}
                </Alert>
        </Snackbar>
      </MDBox>
    </DashboardLayout>
  );
}

export default Call;

