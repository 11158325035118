import React, { useState, useEffect } from 'react';
import MDButton from 'components/MDButton';

const PrevDoc = ({ url }) => {
  const [view, setView] = useState(false);
  const live = typeof url === 'string' && url.startsWith('uploads');
  
  const handleChange = () => {
    setView(!view);
  };

  const handleCancel = () => {
    setView(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {live && ( 
        view ? (
          <>
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                   // src={live?`${process.env.REACT_APP_API_URL}${url}`:`${url}`}
                    src={`${process.env.REACT_APP_API_URL}${url}`}
                    className="d-block w-100"
                    style={{ height: '100%', width: '100%' }}
                    alt="Please Upload"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCancel}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'grey',
                border: 'none',
                fontSize: '1.5rem',
                color: 'black',
                cursor: 'pointer',
                borderRadius: '50%',
                padding: '7px',
                lineHeight: 0.5,
              }}
            >
              &#x2715;
            </button>
          </>
        ) : (
          <MDButton
            size="small"
            variant="contained"
            style={{ margin: '5vh 0 0 2vw' }}
            onClick={handleChange}
            color="info"
          >
            View
          </MDButton>
        )
      )}
    </div>
  );
};

export default PrevDoc;




// import React, { useState,useEffect } from 'react';
// import MDButton from 'components/MDButton';

// const PrevDoc = ({url}) => {
//   const [view, setView] = useState(false);
 
//   const handleChange = () => {
//     setView(!view);
//   };

//   const handleCancel = () => {
//     setView(false);
//   };

//   return (
//     <div style={{ position: 'relative' }}>
//       {view && (
//         <>
//           <div
//             id="carouselExampleSlidesOnly"
//             className="carousel slide"
//             data-bs-ride="carousel"
//           >
//             <div className="carousel-inner">
//               <div className="carousel-item active">
//                 <img
//                   src={`${process.env.REACT_APP_API_URL}${url}`}
//                   className="d-block w-100"
//                   style={{ height: '100%', width: '100%' }}
//                   alt="Please Upload"
//                 />
//               </div>
//             </div>
//           </div>
//           <button
//             onClick={handleCancel}
//             style={{
//               position: 'absolute',
//               top: '10px', // Adjust the top margin as needed
//               right: '10px', // Adjust the right margin as needed
//               backgroundColor: 'grey', // White circular background
//               border: 'none',
//               fontSize: '1.5rem',
//               color: 'black', // Black cross color
//               cursor: 'pointer',
//               borderRadius: '50%', // Makes the background circular
//               padding: '7px',
//               lineHeight: 0.5,
//             }}
//           >
//             &#x2715;  {/* This is the circled cross symbol (✖) */}
//           </button>
//         </>
//       )}
//       {!view && (
//         <MDButton
//           size="small"
//           variant="contained"
//           style={{ margin: '5vh 0 0 2vw' }}
//           onClick={handleChange}
//           color="info"
//         >
//           View
//         </MDButton>
//       )}
//     </div>
//   );
// };

// export default PrevDoc;
