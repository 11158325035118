import HttpService from "./htttp.service";

class DepartmentService {
   authEndpoint = process.env.API_URL;

  list = async (id) => {
    const listEndpoint = `/api/department-type/list?parentId=${id}`;
    return await HttpService.get(listEndpoint);
  };

  create = async (id,payload) => {
    const createTask = `/api/department-type/create-and-update?parentId=${id}`;
    
    const data =JSON.stringify({
      "departmentTypeList": payload
     })
   
    return await HttpService.post(createTask, data);
  };

  delete = async (payload) => {
    const loginEndpoint = `/api/task/delete?id=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };
  update = async (payload) => {
    const loginEndpoint = `/api/task/update?id=${payload._id}`;

    var data = JSON.stringify({
      "title": payload.title,
      "isDone": !payload.isDone
    });

    return await HttpService.put(loginEndpoint,data);
  };
}

export default new DepartmentService();
