/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import authService from "services/auth-service";
import { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "context";
import executiveService from "services/executive-service";


function ExecutiveDashboard() {
  const { sales, tasks } = reportsLineChartData;
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const [data, setData] = useState()

  useEffect(async() => {
    try{
      const response = await executiveService.dashboard(id)
      setData(response.data)
    }catch(err){
      console.log(err)
    }
  }, [])

   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {data && <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Executive Count"
                count={data.executiveCount}
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "than lask week",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Call Count"
                count={data.callCount}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Completed Call"
                count={data.completedCallCount}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Pending Call"
                count={data.pendingCallCount}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>}
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <MDBox py={3}>
         {data && <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Unassigned Call"
                  count={data.unassignedCallCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Jobsheet Count"
                  count={data.jobsheetCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+3%",
                  //   label: "than last month",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Completed Jobsheet"
                  count={data.completedJobsheetCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+1%",
                  //   label: "than yesterday",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Pending Jobsheet"
                  count={data.pendingJobsheetCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: "Just updated",
                  // }}
                />
              </MDBox>
            </Grid>
          </Grid>}
      </MDBox>
      <MDBox py={3}>
        {data && <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Unassigned Jobsheet"
                  count={data.unassignedJobsheetCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than lask week",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="AMC Count"
                  count={data.amcCount}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+3%",
                  //   label: "than last month",
                  // }}
                />
              </MDBox>
            </Grid>
          </Grid>}
      </MDBox>
    </DashboardLayout>
  );
}

export default ExecutiveDashboard;


// /**
// =========================================================
// * Material Dashboard 2 React - v2.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// // Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

// // Data
// import executiveCallListTableData from "layouts/executiveCallList/data/executiveCallListTableData";
// import { useNavigate } from "react-router-dom"
// import { useState, useEffect } from "react"
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import callService from "services/call-service";
// import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

// function ExecutiveDashboard() {
//   const { columns, rows, searchPara,filterStatus } = executiveCallListTableData();
//   const [filter,setFilter] = useState('all')
//   const navigate = useNavigate()
//   const [refresh,setRefresh]= useState(false)
//   const [data, setData] = useState([])

//   const statusUnassign = async(id)=>{
//     try{
//         const payload = {
//           status: 'unassigned'
//         }
//         const data = await callService.updateStatus(id,payload)
//         setRefresh(!refresh)
//     }catch(err){
//        console.log(err)
//     }
// }

// const statusCompleted =async(id)=>{
//     try{
//           const payload = {
//             status: 'completed'
//           }
//         const data = await callService.updateStatus(id,payload);
//         setRefresh(!refresh)

//     }catch(err){
//         console.log(err)
//     }
// }




//   const createCall =()=>{ 
//     navigate('/create-call')
//   }

//   const searchList = async(searchValue)=>{
//     searchPara(searchValue,filter)
//   }
//   const filterByStatus = async(status)=>{
//     const response = await callService.calllist(status);
//     setData(response.data)
//   }

//   return (
//     <DashboardLayout>
//       {/* <DashboardNavbar /> */}
//       <MDBox pt={6} pb={3}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <Card>
//               <MDBox
//                 mx={2}
//                 mt={-3}
//                 py={3}
//                 px={2}
//                 variant="gradient"
//                 bgColor="info"
//                 borderRadius="lg"
//                 coloredShadow="info"
//               >
//                 <MDTypography variant="h6" color="white">
//                   Executive Dashboard
//                   {/* <MDInput 
//                        label={<span style={{ color: 'white' }}>Search Here</span>} 
//                       size="small"  
//                       style={{"margin":"0 1vw 0 44vw"}} 
//                       onChange={(e)=>searchList(e.target.value)}
//                     />
//                       <a
//                         href="#"
//                         role="button"
//                         id="dropdownMenuLink"
//                         data-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{color:"white"}}
//                       >
//                         <i className="fa fa-filter"></i>
//                       </a>

//                       <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
//                         <a className="dropdown-item"  onClick={() => setFilter("all")}>All</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("contact")}>Contact</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("contactPerson")}>Contact Person</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("name")}>Name</a>
//                         <a className="dropdown-item"  onClick={() => setFilter("email")}>Email</a>
//                       </div> */}
              
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={createCall}> 
//                         Create
//                   </MDButton> */}
                  
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={() =>filterByStatus('all')}> 
//                         All Jobs
//                   </MDButton> */}
//                   {/* <MDButton 
//                           size="small" 
//                           variant="contained" 
//                           style={{"margin":"0 0 0 2vw"}} 
//                           onClick={() =>filterByStatus('completed')}> 
//                         Completed Jobs
//                   </MDButton> */}
//                    {/* <MDTypography variant="h6" color="white">
//                       <MDButton 
//                               size="small" 
//                               variant="contained" 
//                               style={{"margin":"0 0 0 4vw"}} 
//                               onClick={() =>filterByStatus('pending')}> 
//                             Pending Jobs
//                       </MDButton>
//                       <MDButton 
//                               size="small" 
//                               variant="contained" 
//                               style={{"margin":"0 0 0 2vw"}} 
//                               onClick={() =>filterByStatus('today')}> 
//                             Today's Job
//                       </MDButton>
//                     </MDTypography>     */}
//                 </MDTypography>
//               </MDBox>
//               <MDBox pt={3}>
//               <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
             
             
//               </MDTypography>  
//               <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                   <DefaultDoughnutChart
//                     title="Today Performance"
//                     chart={{
//                       labels: ["Completed", "Pending"],
//                       datasets: {
//                         label: "Projects",
//                         backgroundColors: ["info", "error"],
//                         data: [15, 20],
//                       },
//                     }}
//                   />
//               </MDTypography>    
//               <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                   <DefaultDoughnutChart
//                     title="Weekly Performance"
//                     chart={{
//                       labels: ["Completed", "Pending"],
//                       datasets: {
//                         label: "Projects",
//                         backgroundColors: ["info", "error"],
//                         data: [35, 20],
//                       },
//                     }}
//                   />
//               </MDTypography>  
//               <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                   <DefaultDoughnutChart
//                     title="Monthly Performance"
//                     chart={{
//                       labels: ["Completed", "Pending"],
//                       datasets: {
//                         label: "Projects",
//                         backgroundColors: ["info", "error"],
//                         data: [15, 20],
//                       },
//                     }}
//                   />
//               </MDTypography> 
//               <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                   <DefaultDoughnutChart
//                     title="Overall Performance"
//                     chart={{
//                       labels: ["Completed", "Pending"],
//                       datasets: {
//                         label: "Projects",
//                         backgroundColors: ["info", "error"],
//                         data: [15, 50],
//                       },
//                     }}
//                   />
//               </MDTypography> 
//                 {/* {
//                   data.map((item, index) => (
//                     <Card>
//                     <MDBox p={2} mx={3} display="flex" justifyContent="center">
//                           <MDBox
//                             display="grid"
//                             justifyContent="center"
//                             alignItems="center"
//                             bgColor='info'
//                             color="blue"
//                             width="16rem"
//                             height="16rem"
//                             shadow="md"
//                             borderRadius="lg"
//                             variant="gradient"
//                           >
//                             <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
//                                 <MDTypography variant="h2" fontWeight="regular" textTransform="uppercase" color="light" align='left'>
//                                   {item.companyName}
//                                 </MDTypography>
//                                 <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light" >
//                                 {`${item.address}, ${item.area},${item.city}, ${item.state}, ${item.pincode}` }
//                                 </MDTypography>
//                                 <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
//                                   {item.RMN}
//                                   <a href={`tel:${item.RMN}`}> <span className="material-symbols-outlined" style={{color:"white"}}>call</span> </a>
//                                 </MDTypography>
//                                 <MDTypography variant="caption" fontWeight="regular" textTransform="capitalize" color="light">
//                                   {item.callDetailsNotes}
//                                 </MDTypography>
//                             </MDBox>
//                                 <MDTypography variant="h5" fontWeight="regular" textTransform="capitalize" color="light">
//                                     <MDButton 
//                                               size="small" 
//                                               variant="contained" 
//                                               style={{margin: "1rem"}}
//                                               onClick={()=>{statusUnassign(item._id)}}
//                                               > 
//                                             Pending
//                                         </MDButton>
//                                         <MDButton 
//                                               size="small" 
//                                               variant="contained" 
//                                               onClick={()=>{statusCompleted(item._id)}}
//                                               > 
//                                             Completed
//                                       </MDButton>
//                                 </MDTypography>
                                  
//                           </MDBox>
//                     </MDBox>
//                 </Card>
//                   ))
//                 } */}
               
//               </MDBox>
//             </Card>
//           </Grid>
//         </Grid>
//       </MDBox>
//       {/* <Footer /> */}
//     </DashboardLayout>
//   );
// }

// export default ExecutiveDashboard;
