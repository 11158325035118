import React,{ useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate, useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import axios from 'axios';
import multipleCustomerService from "services/multipleCustomer-service";


const MultipleCustomerForm = () => {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const parentId = userDetail.decodedToken?.userId;

  const [formErrors, setFormErrors] = useState({
    multipleCustomerError:''
   }); 
  const [file, setFile] = useState(null);
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  const handleValidation = () => {
    let valid = true;
    const newErrors = { ...formErrors }; 

    if(!file){
        newErrors.multipleCustomerError='Invalid Upload'
        valid = false
    }else{
        newErrors.contact = ''
    }
    setFormErrors(newErrors);
    return valid;
}   
  const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
      
    const handleUpload = async() => {
        if (handleValidation()) {
            const data = new FormData();
            data.append('file', file);

            try{
              const list = await multipleCustomerService.upload(parentId,data)
              navigate('/settings')
            }catch(err){
              console.log(err)
            }
        }
    };

    const handleDownload = async()=>{
         axios({
            method: 'get',
            url: `http://localhost:8080/api/customer/download?parentId=${parentId}`,
            responseType: 'blob', // Set the response type to 'blob' for binary data
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your access token
            },
          })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'customer_list.xlsx'); // Specify the filename
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(url);
              navigate('/settings')
            })
            .catch((error) => {
              console.error(error);
            });
    }

    return (
      <div className='form-container'>
           <form className='form-box'>
              <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Multiple Customer List</label>
                        <input type="file" className={`form-control ${formErrors.multipleCustomerError?`is-invalid`:``}`} onChange={handleFileChange} id="inputGroupFile01"/>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                          Please Upload File.
                        </div>
                </div>
                <div className="form-group col-md-4">
                        <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin": "5vh 0 2vh 2vw","width": "8vw","height": "5vh"}} 
                              color="info"
                              onClick={handleUpload}
                              my={4}
                              > 
                              Import
                        </MDButton>
                </div>
                {/* <div className="form-group col-md-4">
                        <MDButton 
                              size="small" 
                              variant="contained" 
                              style={{"margin": "4vh 0 2vh 10vw","width": "8vw","height": "5.5vh"}} 
                              color="info"
                              onClick={handleDownload}
                              my={4}
                              > 
                              Export
                        </MDButton>
                </div> */}
              </div>
            </form>
        </div>
    )
}

export default MultipleCustomerForm

