/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton"
import DataTable from "examples/Tables/DataTable";

// Data
import termsAndConditionListData from "layouts/termsAndCondition/data/termsAndConditionListData";
import TodoService from "services/todo-service";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom"
import departmentService from "services/department-service";
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import termsAndConditionService from "services/termsAndCondition-service";



function CreateTermsAndCondition() {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const [flag,setFlag] = useState(false)
  const { columns, rows, searchPara, addPriority } = termsAndConditionListData(flag);
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [termsAndCondition, setTermsAndCondition] = useState([])

  const [formErrors, setFormErrors] = useState({
     title:'',
    });

  const handleValidation = () => {
    let valid = true;
    const newErrors = { ...formErrors }; 

    if(title.length == 0){
        newErrors.title='Invalid Title'
        valid = false
    }else{
        newErrors.title = ''
    }

    setFormErrors(newErrors);
    return valid;
  }   

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleProcedureContentChange = (content) => {
    setTitle(title)
    setContent(content)
  };

  const onSubmit = async()=>{
    if(handleValidation()) {
        try{
           const data = await termsAndConditionService.create(id,[...termsAndCondition,{ title: title,content: content}])
            navigate('/terms-and-condition')
        }catch(err){
          console.log(err)
        }
        
    } else {
      console.log('Form validation failed');
    }
  }

  useEffect(async() => {
    try{
      const list = await termsAndConditionService.list(id)
      list.data && setTermsAndCondition(list.data.termsAndConditionsList)
    
    }catch(err){
      console.log(err)
    }
  }, [])
  const searchList = async(searchValue)=>{
    searchPara(searchValue)
  }
  

  const termsAndConditionList= async()=>{
    navigate('/terms-and-condition')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Terms And Condition
                  <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 32vw"}} 
                          onClick={termsAndConditionList}> 
                        List
                    </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Title</label>
                    <input type="text"  className={`form-control ${formErrors.title?`is-invalid`:``}`}   id="inputAddress" placeholder="Enter Title" onChange={e=>{setTitle(e.target.value)}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Title.
                    </div>
                </div>
              <div style={{ display: "grid", justifyContent: "center",margin: '2vh 0 10vh 0'}}>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleProcedureContentChange}
                    style={{ height: "220px", width:"75.5vw" }}
                  >
                  </ReactQuill>
                </div>

                <MDButton 
                    size="small" 
                    variant="contained" 
                    style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                    color="info"
                    onClick={onSubmit}
                    > 
                    Create
                </MDButton>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default CreateTermsAndCondition;
