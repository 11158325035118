/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import CustomerService from "services/customer-service";
import { useNavigate } from "react-router-dom"
import jobSheetService from "services/jobSheet-service";
import termsAndConditionService from "services/termsAndCondition-service";
import authService from "services/auth-service";
import html2pdf from 'html2pdf.js';
import DefaultProfile from "assets/images/34AD2.jpeg";

function convertImageToBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

export default function data() { 
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
    const Detail = ({ image, name, email,address }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {/* <MDAvatar src={image} name={name} size="sm" /> */}
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography><br/>
            <MDTypography variant="caption">{address}</MDTypography>
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)  
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('all')
    const navigate = useNavigate()

    const editTask = (id)=>{
            navigate(`/edit-jobsheet/${id}`)
    }

    const deleteTask =async(id)=>{
      try{
        const response = await jobSheetService.delete(id);
        setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }

    const getSelectedTermsAndCondition = (termsAndConditionsList,title) => {
      const selectedTerms = termsAndConditionsList.find(
        (condition) => condition.title === title
      );
    
      return selectedTerms ? selectedTerms.content : null;
    };

    const printamc = async(id)=>{
      const response = await jobSheetService.getByID(id)
      const data = response.data
      const termsAndConditions = await termsAndConditionService.list(data.parentId)
      const termsAndConditionsList = termsAndConditions.data?.termsAndConditionsList
      const selectedTermsAndCondition = data.termsAndCondition.length > 0 ? getSelectedTermsAndCondition(termsAndConditionsList,data.termsAndCondition[0]) : '<ol>Default</ol>';
      const parent = await authService.getProfile(data.parentId)
      const parentDetails = parent.data
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // January is 0, so adding 1
      const year = currentDate.getFullYear();
      const imageUrl = (parentDetails.profileImage != '' && parentDetails.profileImage != undefined) ? `${process.env.REACT_APP_API_URL}${parentDetails.profileImage}` : `${DefaultProfile}`
     
      async function getImageBase64() {
        try {
          const base64Img = await convertImageToBase64(imageUrl);
          return base64Img;
        } catch (error) {
          console.error('Error occurred while converting image to Base64:', error);
          return null;
        }
      }
      const base64 =  await getImageBase64();
      const todayDate = `${day}-${month}-${year}`;
      const element =`
           <div id="pdf-content">
           <div class="centeredDivCustomerCopy">
             <h5>CUSTOMER INWARD RECEIPT (CUSTOMER COPY)</h5>
             <table class="table-container">
               <tr>
               <th class="th-print"><img class="logo-print" src='${base64}'/>
               </th>
                 <th class="th-print">${parentDetails.companyName}</th>
                 <th class="th-print">
                   <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
                   <div class="address-container">
                     <p class="p-print">${parentDetails.email}</p>
                     <p class="p-print">website</p>
                     <p class="p-print">${parentDetails.contact}</p>
                   </div>
                 </th>
               </tr>
             </table>
               <div class="customer-container">
                 <div class="customer-left">
                     <p class="p-print">Customer Name: ${data.companyName}</p>
                     <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                     <p class="p-print">Customer Contact: ${data.contact}</p>
                 </div>
                 <div class="customer-right">
                     <p class="p-print">Job Sheet No: ${data.jobSheetNo}</p>
                     <p class="p-print">Job Sheet Date: ${todayDate}</p>
                     <p class="p-print">Alternate Number: ${data.contact}</p>
                   </div>
               </div>
               <table class="table-container">
                   <thead>
                     <tr>
                       <th class="th-print" colspan="6">We Have Received Below Material For Repairing</th>
                     </tr>
                     <tr>
                       <th class="th-print">Model Name/ Number</th>
                       <th class="th-print">EMI/Serial/Part No</th>
                       <th class="th-print">Physical Condition</th>
                       <th class="th-print">Accessories</th>
                       <th class="th-print">Problem</th>
                       <th class="th-print">Estimated Price</th>
                     </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td class="td-print">${data.modelName}</td>
                     <td class="td-print">${data.emiSerialPort}</td>
                     <td class="td-print">${data.physicalCondition}</td>
                     <td class="td-print">${data.accessories}</td>
                     <td class="td-print">${data.problem}</td>
                     <td class="td-print">${data.estimatedPrice}</td>
                   </tr>
                   <tr>
                     <td class="td-print" colspan="6" style="text-align:left">Amount in words:</td>
                   </tr>
                   <tr>
                     <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
                   </tr>
                   <tr style={{height:'150px'}}>
                     <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
                   </tr>
                   <tr>
                     <td class="td-print" colSpan="6">
                     <div id="signatureBlock">
                       <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                       <div class="rightText">
                         For Company's Name<br/>
                         <div class="line-print"></div><br/>
                         Authorised signatory
                       </div>
                     </div>                
                     </td>
                   </tr>
                 </tbody>
               </table>
           </div>
           <div class="centeredDivCustomerCopy">
             <h5>CUSTOMER INWARD RECEIPT (OFFICE COPY)</h5>
             <table class="table-container">
             <tr>
             <th class="th-print"><img class="logo-print" src='${base64}'/>
               <th class="th-print">${parentDetails.companyName}</th>
               <th class="th-print">
                 <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
                 <div class="address-container">
                   <p class="p-print">${parentDetails.email}</p>
                   <p class="p-print">website</p>
                   <p class="p-print">${parentDetails.contact}</p>
                 </div>
               </th>
             </tr>
           </table>
             <div class="customer-container">
               <div class="customer-left">
                   <p class="p-print">Customer Name: ${data.companyName}</p>
                   <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                   <p class="p-print">Customer Contact: ${data.contact}</p>
               </div>
               <div class="customer-right">
                   <p class="p-print">Job Sheet No: ${data.jobSheetNo}</p>
                   <p class="p-print">Job Sheet Date: ${todayDate}</p>
                   <p class="p-print">Alternate Number: ${data.contact}</p>
                 </div>
             </div>
             <table class="table-container">
                 <thead>
                   <tr>
                     <th class="th-print" colspan="6">We Have Received Below Material For Repairing</th>
                   </tr>
                   <tr>
                     <th class="th-print">Model Name/ Number</th>
                     <th class="th-print">EMI/Serial/Part No</th>
                     <th class="th-print">Physical Condition</th>
                     <th class="th-print">Accessories</th>
                     <th class="th-print">Problem</th>
                     <th class="th-print">Estimated Price</th>
                   </tr>
               </thead>
               <tbody>
                 <tr>
                   <td class="td-print">${data.modelName}</td>
                   <td class="td-print">${data.emiSerialPort}</td>
                   <td class="td-print">${data.physicalCondition}</td>
                   <td class="td-print">${data.accessories}</td>
                   <td class="td-print">${data.problem}</td>
                   <td class="td-print">${data.estimatedPrice}</td>
                 </tr>
                 <tr>
                   <td class="td-print" colspan="6" style="text-align:left">Amount in words:</td>
                 </tr>
                 <tr>
                   <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
                 </tr>
                 <tr style={{height:'150px'}}>
                   <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
                 </tr>
                 <tr>
                   <td class="td-print" colSpan="6">
                   <div id="signatureBlock">
                     <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                     <div class="rightText">
                       For Company's Name<br/>
                       <div class="line-print"></div><br/>
                       Authorised signatory
                     </div>
                   </div>                
                   </td>
                 </tr>
               </tbody>
             </table>
         </div>
           `    
      html2pdf()
      .from(element)
      .save('jobsheet.pdf');
    }

    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
    }

    const filterStatus = (filter)=>{
      setFilterValue(filter)
    }
      useEffect(() => {
        async function fetchData() {
            try {
                const response = await jobSheetService.list(id,filterValue);
               
                const data = response.data; 
                console.log(data)
                const newColumns = [
                    { Header: "detail", accessor: "detail", width: "45%", align: "left" },
                    { Header: "problem", accessor: "problem", align: "left" },
                    { Header: "executive", accessor: "executive", align: "left" },
                    { Header: "contact", accessor: "contact", align: "center" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];
          
                  const newRows = data.map((item) => ({
                    detail: (
                      <Detail
                        image={team2} // You may need to update this part to dynamically select an image based on item data
                        name={item.companyName}
                        email={item.jobSheetNo}
                        address={`${item.address}, ${item.area}, ${item.state}, ${item.pincode}` }
                        
                      />
                    ),
                    executive: (
                      <>
                         {item.executive.map((executive,index) => (
                            <MDTypography
                              key={executive.value} // Don't forget to add a unique key when using map
                              component="a"
                              variant="caption"
                              color="text"
                              fontWeight="medium"
                            >
                              {executive.label} 
                              {index < item.executive.length - 1 && ', '}
                            </MDTypography>
                          ))}
                      </>
                      ),
                    contact: (
                      <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                         <a href={`tel:${item.contact}`}>   
                            {item.contact}  
                           </a>
                      </MDTypography>
                    ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  href={`/edit-customer/${item._id}`}
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{editTask(item._id)}}
                                >
                                    edit
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{deleteTask(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    delete
                                </span>
                                <span 
                                  className="material-symbols-outlined"
                                  onClick={()=>{printamc(item._id)}}
                                  style={{"cursor":"pointer"}}
                                >
                                    print
                                </span>
                              </>
                    ),
                  }));
          
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  setCredentialsError(res.message);
                } else {
                  setCredentialsError(res.errors[0].detail);
                }
              }
        }
        fetchData()
      }, [searchValue,refresh,filterValue])
     
     
  

  return {
    columns,
    rows,
    searchPara,
    filterStatus
  };
}
