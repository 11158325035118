import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const styles = {
  label: {
    color: '#596580',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  textField: {
    width: '100%',
  },
};

const ProfileViewCard = ({ userInfo }) => {
  // Create a filtered version of the userInfo object
  const filteredUserInfo = { ...userInfo };
  delete filteredUserInfo.subscription;
  delete filteredUserInfo._id;
  delete filteredUserInfo.companyName;
  delete filteredUserInfo.password;
  delete filteredUserInfo.role;
  delete filteredUserInfo.__v;
  delete filteredUserInfo.resetPasswordExpires;
  delete filteredUserInfo.resetPasswordToken;
  

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {Object.entries(filteredUserInfo).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <div style={styles.label}>{key}:</div>
              <TextField
                value={value}
                variant="outlined"
                InputProps={{ readOnly: true }}
                style={styles.textField}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileViewCard;

