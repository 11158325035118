import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate, useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead";
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import amcService from 'services/amc-service';
import termsAndConditionService from "services/termsAndCondition-service";
import html2pdf from 'html2pdf.js';
import authService from "services/auth-service";
import DefaultProfile from "assets/images/34AD2.jpeg";

function convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }
  function extractDate(dateTimeString) {
    const dateObj = new Date(dateTimeString); // Convert string to Date object
    const formattedDate = dateObj.toISOString().split('T')[0]; // Extract date part
    return formattedDate;
  }

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

const AMCForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const parentId = userDetail.decodedToken?.userId;
    const {id} = useParams()
    const navigate = useNavigate()
    const [options,setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [startingDate, setStartingDate] = useState(formatDate(new Date()));
    const [endDate,setEndDate] = useState(getDefaultEndDate())
    const [creatDate,setCreatDate] = useState(formatDate(new Date()))
    const [search,setSearch] = useState('')
    const [amcTypeList, setAMCTypeList ] = useState([])
    const [termsAndConditionsList, setTermsAndConditionsList ] = useState([])
    const [print,setPrint] = useState(false)
    const [values,setValues] = useState({
        companyName:'',
        amcName:'',
        contact:'',

        amcDocNo:'', 
        amcCategory:'',
        renewStatus:'',

        createdDate: creatDate,  
        startingDate: startingDate,
        endDate: endDate,
        numberOfService:'',
        
        address:'',
        area:'',
        country:'',
        city:'',//
        state:'',
        pincode:'',
        googleMapUrl:'',
        
        amcNotes:'',
        serviceNotes:'',


        email:'',
        alternateNumber:'',
        contactPerson:'',
        customerType:'',
        gstNumber:'',
        termsAndCondition:'',
        parentId: parentId
       })

    useEffect(async() => {
        try{
            const customer =  await amcService.getByID(id)
            console.log('daatatatat',customer)
            setValues({
                companyName:customer.data.companyName,
                amcName:customer.data.amcName,
                contact:customer.data.contact,

                amcDocNo:customer.data.amcDocNo, 
                amcCategory:customer.data.amcCategory,
                renewStatus:customer.data.renewStatus,

                createdDate: customer.data.creatDate,  
                startingDate: customer.data.startingDate,
                endDate: customer.data.endDate,
                numberOfService:customer.data.numberOfService,
                
                address:customer.data.address,
                area:customer.data.area,
                country:customer.data.country,
                city:customer.data.city,
                state:customer.data.state,
                pincode:customer.data.pincode,
                googleMapUrl:customer.data.googleMapUrl,
                
                amcNotes:customer.data.amcNotes,
                serviceNotes:customer.data.serviceNotes,


                email:customer.data.email,
                alternateNumber:customer.data.alternateNumber,
                contactPerson:customer.data.contactPerson,
                customerType:customer.data.customerType,
                gstNumber:customer.data.gstNumber,
                termsAndCondition:customer.data.termsAndCondition,
                parentId:'6513c529ef0a31371f20ada6'
             })
        }catch(err){
            console.log(err)
        }
    }, [])
    
    const [formErrors, setFormErrors] = useState({
            amcDocNo: '',
            amcName: '',
            companyName:'',
            numberOfService:''
        });


    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

        useEffect(async() => {
            try{
                const amcType = parentId ? await amcTypeService.list(parentId):{data:{amcTypeList:[]}}
                setAMCTypeList(amcType.data.amcTypeList)
            }catch(err){
                console.log(err)
            }           
        }, [parentId])

        useEffect(async() => {
            try{
                const termsAndCondition = parentId ? await termsAndConditionService.list(parentId):{data:{termsAndConditionService:[]}}
                setTermsAndConditionsList(termsAndCondition.data.termsAndConditionsList)
            }catch(err){
                console.log(err)
            }           
        }, [parentId])
    
     
        useEffect(async() => {
            try{
                const list = id ? await customerService.search(parentId,search):{data:[]}
                setOptions(list.data)
                
            }catch(err){
                console.log(err)
            }
            
        }, [search])   

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.amcDocNo.length == 0){
                newErrors.amcDocNo='Invalid AMC Doc Number'
                valid = false
            }else{
                newErrors.amcDocNo = ''
            }

            if(values.amcName.length == 0){
                newErrors.amcName='Invalid AMC Name'
                valid = false
            }else{
                newErrors.amcName = ''
            }

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            if(values.numberOfService.length == 0){
                newErrors.numberOfService='Invalid Number Of Service'
                valid = false
            }else{
                newErrors.numberOfService = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   
    
        const printamc = async(id)=>{

            const response = await amcService.getByID(id)
            const data = response.data
            const termsAndConditions = await termsAndConditionService.list(data.parentId)
            const termsAndConditionsList = termsAndConditions.data?.termsAndConditionsList
            const selectedTermsAndCondition = data.termsAndCondition.length > 0 ? getSelectedTermsAndCondition(termsAndConditionsList,data.termsAndCondition[0]) : '<ol>Default</ol>';
            const parent = await authService.getProfile(data.parentId)
            const parentDetails = parent.data
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // January is 0, so adding 1
            const year = currentDate.getFullYear();
            const imageUrl = (parentDetails.profileImage != '' && parentDetails.profileImage != undefined) ? `${process.env.REACT_APP_API_URL}${parentDetails.profileImage}` : `${DefaultProfile}`
            async function getImageBase64() {
              try {
                const base64Img = await convertImageToBase64(imageUrl);
                return base64Img;
              } catch (error) {
                console.error('Error occurred while converting image to Base64:', error);
                return null;
              }
            }
            const base64 =  await getImageBase64();
      
            const todayDate = `${day}-${month}-${year}`;
      
            const generatePDFContent = (title,isNewPage) => {
        //       const element = `
        //     <div id="pdf-content" class="invoice-box">
        //     <table cellPadding="0" cellSpacing="0">
        //       <tbody>
        //         <tr class="top_rw">
        //           <td colSpan="2">
        //             <br/>
        //             <br/>
        //             <h2 style={{ marginBottom: 0 }}> ${title} </h2>
        //             <span style={{}}>Date: ${todayDate} </span>
        //           </td>
        //         </tr>
        //         <tr class="top">
        //           <td colSpan="3">
        //             <table>
        //               <tbody>
        //                 <tr>
        //                   <td>
        //                   <b> Sold By: ${parentDetails.companyName || 'N/A'} </b> <br />
        //                   ${parentDetails.address || 'N/A'},${parentDetails.area || 'N/A'} <br />
        //                   ${parentDetails.city || 'N/A'},${parentDetails.state || 'N/A'} <br />
        //                   ${parentDetails.country || 'N/A'},${parentDetails.pincode || 'N/A'}<br />
        //                   ${parentDetails.contact || 'N/A'} <br />
        //                   GSTIN: ${parentDetails.gstNumber || 'N/A'} <br />
        //                   </td>
        //                 </tr>
        //               </tbody>
        //             </table>
        //           </td>
        //         </tr>
        //         <tr class="information">
        //           <td colSpan="3">
        //             <table>
        //               <tbody>
        //                 <tr>
        //                   <td colSpan="2">
        //                     <b> Customer Details: </b> <br />
        //                     Company Name: ${data.companyName} <br />
        //                     Email: ${data.email}<br />
        //                     Contact: ${data.contact}<br />
        //                     Address: ${data.address}, ${data.area},${data.city},${data.state},${data.pincode},${data.country}<br />
        //                     Customer Type: ${data.customerType}<br />
        //                     GST: ${data.gstNumber}
        //                   </td>
        //                   <td>
        //                     <b> AMC Details: </b> <br />
        //                     AMC Doc No: ${data.amcDocNo}<br />
        //                     AMC Name:${data.amcName}<br />
        //                     AMC Category:${data.amcCategory} <br />
        //                     No of Services: ${data.numberOfService} <br />
        //                     Starting Date: ${data.startingDate.split('T')[0]} <br />
        //                     End Date: ${data.endDate.split('T')[0]} <br />
        //                   </td>
        //                 </tr>
        //               </tbody>
        //             </table>
        //           </td>
        //         </tr>
        //         <tr>
        //           <td colSpan="3">
        //             <table cellSpacing="0px" cellPadding="2px">
        //               <tbody>
        //                 <tr>
        //                   <td width="50%">
        //                     <b> Terms And Condition: </b> <br />
        //                     ${selectedTermsAndCondition}
        //                   </td>
        //                 </tr>
        //                 <tr>
        //                   <td width="50%"></td>
        //                   <td>
        //                     <b> Authorized Signature </b> <br />
        //                     <br />
        //                     ................................... <br />
        //                     <br />
        //                     <br />
        //                   </td>
        //                 </tr>
        //               </tbody>
        //             </table>
        //           </td>
        //         </tr>
        //       </tbody>
        //     </table>
        //   </div>
        // </>
        //     `;
          
            const element =`
            <div id="pdf-content">
            <div class="centeredDivCustomerCopy">
              <h5>CUSTOMER INWARD RECEIPT (${title})</h5>
              <table class="table-container">
                <tr>
                <th class="th-print"><img class="logo-print" src='${base64}'/>
                </th>
                  <th class="th-print">${parentDetails.companyName}</th>
                  <th class="th-print">
                    <p class="p-print">${parentDetails.address}, ${parentDetails.area}, ${parentDetails.state}, ${parentDetails.pincode}</p>
                    <div class="address-container">
                      <p class="p-print">${parentDetails.email}</p>
                      <p class="p-print">website</p>
                      <p class="p-print">${parentDetails.contact}</p>
                    </div>
                  </th>
                </tr>
              </table>
                <div class="customer-container">
                  <div class="customer-left">
                      <p class="p-print">Customer Name: ${data.companyName}</p>
                      <p class="p-print">Customer Address: ${data.address}, ${data.area}, ${data.state}, ${data.pincode}</p>
                      <p class="p-print">Customer Contact: ${data.contact}</p>
                  </div>
                  <div class="customer-right">
                      <p class="p-print">Email: ${data.email}</p>
                      <p class="p-print">Customer Type: ${data.customerType}</p>
                      <p class="p-print">GST: ${data.gstNumber}</p>
                    </div>
                </div>
                <table class="table-container">
                    <thead>
                    <tr>
                       <th class="th-print" colspan="6">AMC Details</th>
                    </tr>
                    <tr>
                      <th class="th-print">AMC Doc No</th>
                      <th class="th-print">AMC Name</th>
                      <th class="th-print">AMC Category</th>
                      <th class="th-print">No of service</th>
                      <th class="th-print">Start Date</th>
                      <th class="th-print">End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="td-print">${data.amcDocNo}</td>
                      <td class="td-print">${data.amcName}</td>
                      <td class="td-print">${data.amcCategory}</td>
                      <td class="td-print">${data.numberOfService}</td>
                      <td class="td-print">${extractDate(data.startingDate)}</td>
                      <td class="td-print">${extractDate(data.endDate)}</td>
                    </tr>
                    <tr>
                      <th class="th-print" colspan="6" style="text-align:left">Terms & Conditions</th>
                    </tr>
                    <tr style={{height:'150px'}}>
                      <td class="td-print" colspan="6" style="text-align:left">${selectedTermsAndCondition}</td>
                    </tr>
                    <tr>
                      <td class="td-print" colSpan="6">
                      <div id="signatureBlock">
                        <div class="leftText"><div class="line-print"></div><br/>Customer Sinecure</div>
                        <div class="rightText">
                          For Company's Name<br/>
                          <div class="line-print"></div><br/>
                          Authorised signatory
                        </div>
                      </div>                
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
            `    
            const pageBreak = isNewPage ? 'page-break-before: always;' : '';
      
            return `
              <div style="${pageBreak}">
                ${element}
              </div>
            `;
            };
            const officeUseContent = generatePDFContent('For Office Use', false);
            const customerUseContent = generatePDFContent('For Customer Use', true);
          
            const finalElement = `
              ${officeUseContent}
              ${customerUseContent}
            `;
          
            html2pdf()
              .from(finalElement)
              .save('amc.pdf');
      
      
          }

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await amcService.update(id,values)
                print && printamc(id)
                navigate('/amc', { state: { successMessage: "AMC updated successfully" } })
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
            
          } else {
            console.log('Form validation failed');
          }
    }
    const createCustomer=()=>{
        navigate('/create-customer')
    }
      
    const handleInputChange = (selected) => {
        setSelectedOption(selected);     
                
        selected.length > 0 && setValues((values) => ({...values,
                companyName:selected[0].companyName,
                address:selected[0].address,
                area:selected[0].area,
                country:selected[0].country,
                city:selected[0].city,
                state:selected[0].state,
                pincode:selected[0].pincode,
                googleMapUrl:selected[0].googleMapUrl,
                contact:selected[0].contact,
                email:selected[0].email,
                alternateNumber:selected[0].alternateNumber,
                contactPerson:selected[0].contactPerson,
                customerType:selected[0].customerType,
                gstNumber:selected[0].gstNumber,
                termsAndCondition:selected[0].termsAndCondition,
                parentId:selected[0].parentId,
            }))
    };

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
        
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="input" required>Customer Name<span className="text-danger">*</span></label>
                    <Typeahead
                        id="autocomplete-textfield"
                        labelKey="companyName"
                        options={options}
                        placeholder="Search Company Name"
                        selected={selectedOption.length > 0 ? selectedOption :  [values]}
                        onChange={handleInputChange}
                        className = {`${formErrors.amcName?`is-invalid`:``}`}
                        onInputChange={(text, e) => {setSearch(text)}}
                    />
                     <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Customer Name.
                    </div>
                </div>
                <MDButton 
                        size="small" 
                        variant="contained" 
                        style={{"width": "1vw","height": "1vw","margin":" 5vh 0 0 0"}} 
                        color="info"
                        onClick={createCustomer}
                        > 
                        +
                </MDButton>

                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>AMC Name<span className="text-danger">*</span></label>
                    <input type="text" className={`form-control ${formErrors.amcName?`is-invalid`:``}`} value={values.amcName} id="inputAMCName" placeholder="AMC Name" onChange={e=>{setValues({...values,amcName:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a AMC Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number</label>
                    <input type="tel" className="form-control" id="validationServer05" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} disabled/>
                </div>
            </div>
            <hr className='line'/>
            <div className="form-row">  
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">AMC Doc No<span className="text-danger">*</span></label>
                    <input type="number" className={`form-control ${formErrors.amcDocNo?`is-invalid`:``}`} value={values.amcDocNo} id="inputAMCDocNo" placeholder="Doc No" onChange={e=>{setValues({...values,amcDocNo:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a AMC Doc Number.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">AMC Category</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/amc-type')
                        } else {
                            setValues({...values,amcCategory:e.target.value})
                        }
                    }}
                    value={values.amcCategory}
                    >
                                   <option value="service amc">Service AMC</option>
                                    <option value="labour amc">Labour AMC</option>
                                    {amcTypeList.map((item,index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                    ))}
                                    <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Renew Status</label>
                    <select id="input" className="form-control" value={values.renewStatus} onChange={e=>{setValues({...values,renewStatus:e.target.value})}}>
                        <option>Active</option>
                        <option>In Active</option>
                    </select>
                </div>
            </div>
             <div className="form-row">
                 {/* <div className="form-group col-md-4">
                    <label htmlFor="inputCreatedDate" required>Created Date</label>
                    <input type="date" className="form-control" id="inputCreatedDate" placeholder="Created Date" onChange={e=>{setValues({...values,createdDate:e.target.value})}}/>
                </div> */}
                <div className="form-group col-md-4">
                    <label htmlFor="inputStartingDate">Starting Date</label>
                    <input type="date" className="form-control" value={values.startingDate} id="inputStartingDate" onChange={e=>{setValues({...values,startingDate:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputEndDate">End Date</label>
                    <input type="date" className="form-control" value={values.endDate} id="inputEndDate" onChange={e=>{setEndDate(e.target.value);setValues({...values,endDate:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Number of Services<span className="text-danger">*</span><span style={{"marginLeft": "6vw"}}>Max 52</span></label>
                    <input type="number" className={`form-control ${formErrors.numberOfService?`is-invalid`:``}`} value={values.numberOfService} id="validationServer05" onChange={e=>{setValues({...values,numberOfService:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Number of Services.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Terms and Conditions</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/terms-and-condition')
                        } else {
                            setValues({...values,termsAndCondition:e.target.value})
                        }
                    }}
                    value={values.termsAndCondition}
                    >
                          <option value="service amc">Default</option>
                            {termsAndConditionsList && termsAndConditionsList.map((item,index) => (
                                             <option key={index} value={item.title}>{item.title}</option>
                                            ))}
                            <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">GST Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="GST Number" value={values.gstNumber} onChange={e=>{setValues({...values,gstNumber:e.target.value})}}/>
                </div>
             </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" id="inputArea" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}} disabled/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control" id="inputCity" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control"  id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div class="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            class="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid Url.
                        </div>
                    </div>
                </div> 
             </div>
            
            <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">AMC Notes</label>
                    <textarea className="form-control" id="validationServer05" value={values.amcNotes} onChange={e=>{setValues({...values,amcNotes:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Services Notes</label>
                    <textarea type="text" className="form-control" id="validationServer05" value={values.serviceNotes} onChange={e=>{setValues({...values,serviceNotes:e.target.value})}}/>
                </div>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={()=>setPrint(!print)}/>
                    <label class="form-check-label" for="flexCheckDefault">
                        Print
                    </label>
            </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Update
          </MDButton>
         
    </div>
  )
}

export default AMCForm

