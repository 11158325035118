import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import MDButton from 'components/MDButton';
import customerService from 'services/customer-service';
import { useNavigate } from "react-router-dom"
import { FormControl, InputLabel, Select, MenuItem,  Alert, Snackbar } from "@mui/material";
import { Typeahead } from "react-bootstrap-typeahead";
import amcService from 'services/amc-service';
import amcTypeService from "services/amcType-service";
import jobSheetService from "services/jobSheet-service";
import termsAndConditionService from "services/termsAndCondition-service";

const getDefaultEndDate = () => {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
    return formatDate(oneYearFromNow);
  };
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

const AMCForm = () => {
    const authContext = useContext(AuthContext);
    const userDetail = authContext.userData()
    const id = userDetail.decodedToken?.userId;
    const navigate = useNavigate()
    const [options,setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([]);
    const [startingDate, setStartingDate] = useState(formatDate(new Date()));
    const [endDate,setEndDate] = useState(getDefaultEndDate())
    const [creatDate,setCreatDate] = useState(formatDate(new Date()))
    const [search,setSearch] = useState('')
    const [docNumber,setDocNumber] = useState(0)
    const [amcTypeList, setAMCTypeList ] = useState([])
    const [termsAndConditionsList, setTermsAndConditionsList ] = useState([])
    const [values,setValues] = useState({
        companyName:'',
        amcName:'',
        contact:'',

        amcDocNo:'', 
        amcCategory:'service amc',
        renewStatus:'active',

        createdDate: creatDate,  
        startingDate: startingDate,
        endDate: endDate,
        numberOfService:'',
        
        address:'',
        area:'',
        country:'',
        city:'',//
        state:'',
        pincode:'',
        googleMapUrl:'',
        
        amcNotes:'',
        serviceNotes:'',


        email:'',
        alternateNumber:'',
        contactPerson:'',
        customerType:'',
        gstNumber:'',
        termsAndCondition:'',
        parentId: id
       })
    useEffect(async() => {
      
            try{
                const response = id != undefined && await jobSheetService.docNolist(id)
                //setDocNumber(response.data.amcDocNumber)
                response.data && setValues({
                    ...values,
                    amcDocNo: response.data.amcDocNumber
                })
            }catch(err){
                console.log(err)
            }
    },[id])
    
    const [formErrors, setFormErrors] = useState({
            amcDocNo: '',
            amcName: '',
            companyName:'',
            numberOfService:''
        });

    //SnackBar
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    //End SnackBar

     
        useEffect(async() => {
            try{
                const amcType = id ? await amcTypeService.list(id):{data:{amcTypeList:[]}}
                amcType.data && setAMCTypeList(amcType.data.amcTypeList)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        useEffect(async() => {
            try{
                const termsAndCondition = id ? await termsAndConditionService.list(id):{data:{termsAndConditionService:[]}}
                termsAndCondition.data && setTermsAndConditionsList(termsAndCondition.data.termsAndConditionsList)
            }catch(err){
                console.log(err)
            }           
        }, [id])

        useEffect(async() => {
            try{
                const list = id ? await customerService.search(id,search):{data:[]}
                setOptions(list.data)
                
            }catch(err){
                console.log(err)
            }
            
        }, [search])     

    const handleValidation = () => {
            let valid = true;
            const newErrors = { ...formErrors }; 

            if(values.amcDocNo.length == 0){
                newErrors.amcDocNo='Invalid AMC Doc Number'
                valid = false
            }else{
                newErrors.amcDocNo = ''
            }

            if(values.amcName.length == 0){
                newErrors.amcName='Invalid AMC Name'
                valid = false
            }else{
                newErrors.amcName = ''
            }

            if(values.companyName.length == 0){
                newErrors.companyName='Invalid Company Name'
                valid = false
            }else{
                newErrors.companyName = ''
            }

            if(values.numberOfService.length == 0){
                newErrors.numberOfService='Invalid Number Of Service'
                valid = false
            }else{
                newErrors.numberOfService = ''
            }

            setFormErrors(newErrors);
            return valid;
        }   

    const handleSubmit=async()=>{
          if(handleValidation()) {
            try{
                const response = await amcService.create(values)
                navigate('/amc', { state: { successMessage: "AMC created successfully" } })
    
            }catch(err){
                setAlertMessage("Failed to create customer");
                setOpenAlert(true);
                console.log(err)
            }
          } else {
            
            console.log('Form validation failed');
          }
    }
    const createCustomer=()=>{
        navigate('/create-customer')
    }
      
    const handleInputChange = (selected) => {
        setSelectedOption(selected);     
       
        selected.length > 0 && setValues((values) => ({...values,
                companyName:selected[0].companyName,
                address:selected[0].address,
                area:selected[0].area,
                country:selected[0].country,
                city:selected[0].city,
                state:selected[0].state,
                pincode:selected[0].pincode,
                googleMapUrl:selected[0].googleMapUrl,
                contact:selected[0].contact,
                email:selected[0].email,
                alternateNumber:selected[0].alternateNumber,
                contactPerson:selected[0].contactPerson,
                customerType:selected[0].customerType,
                gstNumber:selected[0].gstNumber,
                termsAndCondition:selected[0].termsAndCondition,
                parentId:selected[0].parentId,
            }))
    };

    function openGoogleMapUrl(url) {
        if (url) {
          window.open(url, '_blank'); // Open the URL in a new tab
        } else {
          // Handle the case when the URL is empty or not provided
          alert('Please provide a valid Google Map URL.');
        }
      }
       
  return (
    <div className='form-container'>
        <form className='form-box' onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="input" required>Customer Name<span className="text-danger">*</span></label>
                    <Typeahead
                        id="autocomplete-textfield"
                        labelKey="companyName"
                        options={options}
                        placeholder="Start typing..."
                        selected={selectedOption}
                        onChange={handleInputChange}
                        className = {`${formErrors.amcName?`is-invalid`:``}`}
                        onInputChange={(text, e) => {setSearch(text)}}

                        // onInputChange={(text, e) => {
                        //     // Set refresh to true here when the text changes
                        //     setRefresh(true);
                        
                        //     // Call your handleInputChange function to handle other changes
                        //     handleInputChange(text);
                        //   }}
                      
                    />
                     <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Customer Name.
                    </div>
                </div>
                <MDButton 
                        size="small" 
                        variant="contained" 
                        style={{"width": "1vw","height": "1vw","margin":" 5vh 0 0 0"}} 
                        color="info"
                        onClick={createCustomer}
                        > 
                        +
                </MDButton>

                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCName" required>AMC Name<span className="text-danger">*</span></label>
                    <input type="text" className={`form-control ${formErrors.amcName?`is-invalid`:``}`} id="inputAMCName" placeholder="AMC Name" onChange={e=>{setValues({...values,amcName:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a AMC Name.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">Contact Number</label>
                    <input type="tel" className="form-control" id="validationServer05" placeholder="Contact" value={values.contact} onChange={e=>{setValues({...values,contact:e.target.value})}} disabled/>
                </div>
            </div>
            <hr className='line'/>
            <div className="form-row">  
                <div className="form-group col-md-4">
                    <label htmlFor="inputAMCDocNo">AMC Doc No<span className="text-danger">*</span></label>
                    <input type="number" className={`form-control ${formErrors.amcDocNo?`is-invalid`:``}`} value={values.amcDocNo} id="inputAMCDocNo" placeholder="Doc No" onChange={e=>{setValues({...values,amcDocNo:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a AMC Doc Number.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">AMC Category</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/amc-type')
                        } else {
                            setValues({...values,amcCategory:e.target.value})
                        }
                    }}
                    value={values.amcCategory}
                    >
                            <option value="service amc">Service AMC</option>
                            <option value="labour amc">Labour AMC</option>
                            {amcTypeList.map((item,index) => (
                                            <option key={index} value={item}>{item}</option>
                                            ))}
                            <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Renew Status</label>
                    <select id="input" className="form-control" value={values.renewStatus} onChange={e=>{setValues({...values,renewStatus:e.target.value})}}>
                        <option value="active">Active</option>
                        <option value="in active">In Active</option>
                    </select>
                </div>
            </div>
             <div className="form-row">
                 {/* <div className="form-group col-md-4">
                    <label htmlFor="inputCreatedDate" required>Created Date</label>
                    <input type="date" className="form-control" id="inputCreatedDate" placeholder="Created Date" onChange={e=>{setValues({...values,createdDate:e.target.value})}}/>
                </div> */}
                <div className="form-group col-md-4">
                    <label htmlFor="inputStartingDate">Starting Date</label>
                    <input type="date" className="form-control" defaultValue={startingDate} id="inputStartingDate" onChange={e=>{setValues({...values,startingDate:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputEndDate">End Date</label>
                    <input type="date" className="form-control" defaultValue={endDate} id="inputEndDate" onChange={e=>{setEndDate(e.target.value);setValues({...values,endDate:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Number of Services<span className="text-danger">*</span><span style={{"marginLeft": "6vw"}}>Max 52</span></label>
                    <input type="number" className={`form-control ${formErrors.numberOfService?`is-invalid`:``}`} placeholder="No of services" id="validationServer05" onChange={e=>{setValues({...values,numberOfService:e.target.value})}}/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a Number of Services.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="input">Terms and Conditions</label>
                    <select id="input" className="form-control" 
                    onChange={(e) => {
                        if (e.target.value === "create") {
                            navigate('/terms-and-condition')
                        } else {
                            setValues({...values,termsAndCondition:e.target.value})
                        }
                    }}
                    value={values.termsAndCondition}
                    >
                          <option value="default">Default</option>
                            {termsAndConditionsList && termsAndConditionsList.map((item,index) => (
                                             <option key={index} value={item.title}>{item.title}</option>
                                            ))}
                            <option value="create">Create</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">GST Number</label>
                    <input type="tel" className="form-control" id="validationServer05"  placeholder="GST Number" value={values.gstNumber} onChange={e=>{setValues({...values,gstNumber:e.target.value})}}/>
                </div>
             </div>
             <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text"  className="form-control"   id="inputAddress" placeholder="Address" value={values.address} onChange={e=>{setValues({...values,address:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Address.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputArea">Area</label>
                    <input type="text" className="form-control" placeholder="Area" id="inputArea" value={values.area} onChange={e=>{setValues({...values,area:e.target.value})}} disabled/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCountry">Country</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="Country" value={values.country} onChange={e=>{setValues({...values,country:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Country.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className="form-control" placeholder="City" id="inputCity" value={values.city} onChange={e=>{setValues({...values,city:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid City.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputState">State</label>
                    <input type="text" className="form-control"  id="inputCity" placeholder="State" value={values.state} onChange={e=>{setValues({...values,state:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid State.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Zip</label>
                    <input type="text" className="form-control" placeholder="Zip" id="validationServer05" value={values.pincode} onChange={e=>{setValues({...values,pincode:e.target.value})}} disabled/>
                    <div id="validationServer03Feedback" className="invalid-feedback">
                        Please provide a valid Zipcode.
                    </div>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputCity">Google Map</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder='Google Map Url' id="inputCity"  aria-describedby="button-addon2" value={values.googleMapUrl} onChange={e=>{setValues({...values,googleMapUrl:e.target.value})}}/>
                        <button 
                            className="btn btn-outline-secondary" 
                            type="button" id="button-addon2"
                            onClick={() => openGoogleMapUrl(values.googleMapUrl)}
                            >view</button>
                        <div id="validationServer03Feedback" className="invalid-feedback">
                            Please provide a valid Url.
                        </div>
                    </div>
                </div>
             </div>
            <hr className='line'/>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">AMC Notes</label>
                    <textarea className="form-control" id="validationServer05" onChange={e=>{setValues({...values,amcNotes:e.target.value})}}/>
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="inputZip">Services Notes</label>
                    <textarea type="text" className="form-control" id="validationServer05" onChange={e=>{setValues({...values,serviceNotes:e.target.value})}}/>
                </div>
            </div>
         </form>   
         <Snackbar
                    open={openAlert}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={() => setOpenAlert(false)}
                    style={{"position":"fixed","bottom":"90.5vh","left":"22vw"}}
                >
                    <Alert
                        severity={alertMessage.includes("successfully") ? "success" : "error"}
                        onClose={() => setOpenAlert(false)}
                    >
                        {alertMessage}
                    </Alert>
         </Snackbar>
          <MDButton 
                size="small" 
                variant="contained" 
                style={{"margin": "0 0 2vh 2vw","width": "8vw","height": "5.5vh"}} 
                color="info"
                onClick={()=>handleSubmit(values)}
                > 
                Create
          </MDButton>
         
    </div>
  )
}

export default AMCForm

