import HttpService from "./htttp.service";

class JobSheetService {
   authEndpoint = process.env.API_URL;
  //admin
  list = async (id,filterValue) => {
    const listOfCustomer = `/api/job-sheet/get-list?parentId=${id}&filter=${filterValue}`;
    return await HttpService.get(listOfCustomer);
  };

  delete = async (payload) => {
    const loginEndpoint = `/api/job-sheet/delete-job-sheet?jobsheetid=${payload}`;
    return await HttpService.delete(loginEndpoint);
  };

  create = async (payload) => {
    const registerEndpoint = '/api/job-sheet/create';
    const data =JSON.stringify(payload)
    return await HttpService.post(registerEndpoint, data);
  };

  getByID = async (id) => {
    const getByIdEndpoint = `/api/job-sheet/get-job-sheet?jobsheetid=${id}`;
    return await HttpService.get(getByIdEndpoint);
  };

  update = async (id,value) => {
    const registerEndpoint = `/api/job-sheet/update?jobsheetid=${id}`;
    const data =JSON.stringify(value)
    return await HttpService.put(registerEndpoint,data);
  };
  search = async (query) => {
    const searchEndpoint = `/api/customer/search-customer?parentid=6513c529ef0a31371f20ada6&query=${query}`;
    return await HttpService.get(searchEndpoint);
  };
  //executive

  getlist = async (id,userId,filter) => {
    const getByIdEndpoint = `/api/job-sheet/get-user-list?parentId=${id}&filter=${filter}&userId=${userId}`;
    return await HttpService.get(getByIdEndpoint);
  };
  updateStatus = async (id,status,reason) => {
    const registerEndpoint = `/api/job-sheet/update-status?jobsheetid=${id}&status=${status}&reason=${reason}`;
    return await HttpService.put(registerEndpoint);
  };

  docNolist = async (id,filterValue) => {
    const listOfDocNo = `/api/amc-customer/get-doc-no?parentId=${id}`;
    return await HttpService.get(listOfDocNo);
  };

  createDocNo = async (id,values) => {
    console.log(values,"values")
    const registerEndpoint = `/api/amc-customer/set-doc-no?parentId=${id}&amcDocNumber=${values.amcDocNo}&jobSheetDocNumber=${values.jobSheetDocNo}&callDocNumber=${values.callDocNumber}`;
    return await HttpService.post(registerEndpoint);
  };
  
  
//   logout = async () => {
//     const logoutEndpoint = 'api/auth/logout';
//     return await HttpService.post(logoutEndpoint);
//   };

//   forgotPassword = async (payload) => {
//     const forgotPassword = 'password-forgot';
//     return await HttpService.post(forgotPassword, payload);
//   }

//   resetPassword = async (credentials) => {
//     const resetPassword = 'password-reset';
//     return await HttpService.post(resetPassword, credentials);
//   }

//   getProfile = async() => {
//     const getProfile = 'me';
//     return await HttpService.get(getProfile);
//   }

//   updateProfile = async (newInfo) => {
//     const updateProfile = "me";
//     return await HttpService.patch(updateProfile, newInfo);
//   }
}

export default new JobSheetService();
