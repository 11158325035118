import HttpService from "./htttp.service";

class MultipleCustomerService {
   authEndpoint = process.env.API_URL;

  upload = async (parentId,payload) => {
    const uploadList = `/api/customer/upload?parentId=${parentId}`;

    return await HttpService.post(uploadList, payload);
  };

  download = async () => {
    const downloadList = `/api/customer/download?parentId=${parentId}`;

    return await HttpService.get(downloadList);
  };
}

export default new MultipleCustomerService();
