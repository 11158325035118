/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton"
import DataTable from "examples/Tables/DataTable";

// Data
import designationListData from "layouts/designation/data/designationListData";
import TodoService from "services/todo-service";
import MDInput from "components/MDInput";
import { useState,useEffect,useRef,useContext } from "react";
import departmentService from "services/department-service";
import { AuthContext } from "context";



function Designation() {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
  const [flag,setFlag] = useState(false)
  const { columns, rows, searchPara, addDesignation } = designationListData(flag);
  const [design, setDesign] = useState('')


  useEffect(() => {
  
  }, [flag])

  const searchList = async(searchValue)=>{
    searchPara(searchValue)
  }
  

  const createDesignation= async()=>{
        addDesignation(id,design)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Designation Table
                  <MDInput 
                      label={<span style={{ color: 'white' }}>Search Here</span>} 
                      size="small"  
                      style={{"margin":"0 1vw 0 4vw"}} 
                      onChange={(e)=>searchList(e.target.value)}
                    />
                  <MDInput
                    variant="outlined" 
                    label={<span style={{ color: 'white' }}>Enter Designation...</span>}
                    size="small" 
                    style={{"margin":"0 0 0 30vw"}} 
                    onChange={e=>{setDesign(e.target.value)}} 
                  />
                  <MDButton 
                          size="small" 
                          variant="contained" 
                          style={{"margin":"0 0 0 2vw"}} 
                          onClick={createDesignation}> 
                        Create
                    </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Designation;
