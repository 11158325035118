import Configurator from 'examples/Configurator'
import React from 'react'
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState, useEffect } from "react";
import NotificationItem from 'examples/Items/NotificationItem';
import { Link } from 'react-router-dom'; 


const Icons = () => {


  const data = [
    {
        icon:'call',
        title:'Call Type',
        route: '/call-type'
    },
    {
        icon:'support_agent',
        title:'Customer Type',
        route: '/customer-type'
    },
     {
        icon:'integration_instructions',
        title:'AMC Type',
        route: '/amc-type'
    },
    {
        icon:'view_agenda',
        title:'Department',
        route: '/department'
    },
    {
        icon:'branding_watermark',
        title:'Designation',
        route: '/designation'
    },
    {
        icon:'priority_high',
        title:'Priority',
        route: '/priority'
    },
    {
        icon:'hvac',
        title:'Terms & Conditions',
        route: '/terms-and-condition'
    },
    {
        icon:'publish',
        title:'Multiple Customer',
        route: '/multiple-customer'
    },
    {
      icon:'assignment',
      title:'Doc Number',
      route: '/doc-no'
  },
   
  ]
  return (
    <>
       <MDBox py={3} mx={1}>
        <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <MDBox mb={1.5}>
            <Link to={item.route} style={{ textDecoration: 'none' }}>
              <ComplexStatisticsCard
                icon={item.icon}
                title={item.title}
              />
               </Link>
            </MDBox>
          </Grid>
        ))}
        </Grid>
      </MDBox>
    </>
  )
}

export default Icons