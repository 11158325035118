/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import { useState,useEffect,useRef,useContext } from "react";
import { AuthContext } from "context";
import TodoService from "services/todo-service";
import departmentService from "services/department-service";
import CustomerTypeService from "services/customerType-service";
import callTypeService from "services/callType-service";
import amcTypeService from "services/amcType-service";



export default function data(flag) {
  const authContext = useContext(AuthContext);
  const userDetail = authContext.userData()
  const id = userDetail.decodedToken?.userId;
    const Title = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {/* <MDAvatar src={image} name={name} size="sm" /> */}
          <MDBox ml={2} lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="button" fontWeight="medium" textAlign="left">
              {name}
            </MDTypography>
            {/* <MDTypography variant="caption">{email}</MDTypography> */}
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

    const [columns, setColumns ] = useState([])
    const [rows, setRows ] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [searchValue, setSearchValue ] = useState('')
    const [filterValue, setFilterValue ] = useState('')
    const [departmentData, setDepartmentData] = useState([])

    const deleteTask =async(index,data)=>{
       
          if(index >= 0 && index < data.length) {
            data.splice(index,1)
          }
          
      try{
        const response = await amcTypeService.create(id,data);
         setRefresh(!refresh)
        
      }catch(err){
        console.log(err)
      }
    }
 
    const searchPara = (searchPara,filter)=>{
      setSearchValue(searchPara)
      setFilterValue(filter)
    }

    const addAMCType = async(amc)=>{
      if(!(amc.trim().length === 0)){
            try{
                const response = await amcTypeService.list(id,searchValue);
                const list = response.data?response.data.amcTypeList:[]
                    list.push(amc)

                    try{
                         await amcTypeService.create(id,list);
                         setRefresh(!refresh)
                    }catch(err){
                        console.log(err.message)
                    }
            }catch(err){
                console.log(err.message)
            }
      }
    }


      useEffect(() => {
        async function fetchData() {
            try {
                const response = await amcTypeService.list(id,searchValue);
                const data = response.data.amcTypeList;

                const newColumns = [
                    { Header: "title", accessor: "title", width: "45%", align: "left" },
                    { Header: "action", accessor: "action", align: "center" },
                  ];
          
                  const newRows = data.map((item,index) => ({
                    title: (
                      <Title
                        name={item}
                      />
                    ),
                    action: (<>
                                <span 
                                  className="material-symbols-outlined"
                                  style={{"cursor":"pointer"}}
                                  onClick={()=>{deleteTask(index,data)}}
                                >
                                    delete
                                </span>
                              </>
                    ),
                  }));
                 
                  
                  setColumns(newColumns);
                  setRows(newRows);
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  setCredentialsError(res.message);
                } else {
                  setCredentialsError(res.errors[0].detail);
                }
              }
        }
        fetchData()
      }, [flag, refresh,searchValue])
     
     
  

  return {
    columns,
    rows,
    searchPara,
    addAMCType
  };
}
